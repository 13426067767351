// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FilterMenuButton_button__YtV8s {\n    font-family: \"Quatro\";\n    font-style: normal;\n    font-weight: 350;\n    gap: 10px;\n    font-size: 10px;\n    line-height: 91%;\n    display: flex;\n    align-items: center;\n    letter-spacing: 0.015em;\n    color: #262626;\n    padding: 0 15px;\n    height: 29px;\n    min-width: 75px;\n    margin-bottom: 8px;\n    box-sizing: border-box;\n    cursor: pointer;\n    background: #FFFFFF;\n}\n\n.FilterMenuButton_textInput__3AX6W {\n    width: 180px;\n}", "",{"version":3,"sources":["webpack://./src/components/ui/molecules/filterMenuButton/FilterMenuButton.module.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,kBAAkB;IAClB,gBAAgB;IAChB,SAAS;IACT,eAAe;IACf,gBAAgB;IAChB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,cAAc;IACd,eAAe;IACf,YAAY;IACZ,eAAe;IACf,kBAAkB;IAClB,sBAAsB;IACtB,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".button {\n    font-family: \"Quatro\";\n    font-style: normal;\n    font-weight: 350;\n    gap: 10px;\n    font-size: 10px;\n    line-height: 91%;\n    display: flex;\n    align-items: center;\n    letter-spacing: 0.015em;\n    color: #262626;\n    padding: 0 15px;\n    height: 29px;\n    min-width: 75px;\n    margin-bottom: 8px;\n    box-sizing: border-box;\n    cursor: pointer;\n    background: #FFFFFF;\n}\n\n.textInput {\n    width: 180px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "FilterMenuButton_button__YtV8s",
	"textInput": "FilterMenuButton_textInput__3AX6W"
};
export default ___CSS_LOADER_EXPORT___;
