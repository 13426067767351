const useConnectCode = () => {
    const getProvisioningCode = () => {
        while (true) {
            var code = generateCode();
            var sum =
                code.charCodeAt(code.length - 1) +
                code.charCodeAt(code.length - 2) +
                code.charCodeAt(code.length - 3);
            if (sum % 7 === 0) {
                return code;
            }
        }
    }
    const generateCode = () => {
        var length = 8,
            charset = "abcdefghjkmnpqrstuvwxyzABCDEFGHJKMNPQRSTUVWXYZ23456789",
            retVal = "";
        for (var i = 0, n = charset.length; i < length; ++i) {
            retVal += charset.charAt(Math.floor(Math.random() * n));
        }
        return retVal;
    }
    return { getProvisioningCode };
}

export default useConnectCode