import ChartWidgetList from '../../atoms/chartWidgetList/ChartWidgetList';
import ChartWidgetTitle from '../../atoms/chartWidgetTitle/ChartWidgetTitle';
import ChartWidgetFooter from '../../atoms/chartWidgetFooter/ChartWidgetFooter';
import styles from './ChartWidgetListMedium.module.css'
import { WidgetContainer } from '@ctouch-europe-b-v/react-component-library';
import { useState, useEffect } from 'react';

const ChartWidgetListMedium = ({ dataList, className, colorVariant, title, iconLeft, iconRight, setOverLayActive, hasWarning, hasError, overLayActive, containsPlus, chartRef }) => {
  const [fullViewActive, setFullViewActive] = useState(false);
  useEffect(() => {
    if (!overLayActive) {
      setFullViewActive(false)
    }
  }, [overLayActive])
  console.log(chartRef)
  return (
    <div ref={chartRef} className={styles.wrapper}>
      <WidgetContainer size="medium" className={[className, (hasError || hasWarning) && styles.errorContainer, styles.container, styles[colorVariant], fullViewActive && styles.fullViewContainer,].filter(e => !!e).join(" ")}>
        <ChartWidgetTitle title={title} iconLeft={iconLeft} iconRight={iconRight} />
        {
          hasWarning
            ? <span className={styles.warning}>There is not enough data to display this information...</span>
            : hasError
              ? <span className={styles.error}>Something went wrong displaying this data...</span>
              : <ChartWidgetList background={colorVariant} containsPlus={containsPlus} hasTopThree={true} fullViewActive={fullViewActive} data={dataList} ascending={true} size={"medium"} className={styles.listItems} max={!fullViewActive ? 5 : undefined} sortIsNeeded={true} />
        }
        <ChartWidgetFooter>
          {!fullViewActive && (!hasWarning && !hasError)
            ? <span className={styles.footerButton} onClick={() => { setFullViewActive(true); setOverLayActive(true) }}>View All</span>
            : !fullViewActive && (hasWarning || hasError)
              ? <span></span>
              : <span className={styles.footerButton} onClick={() => { setFullViewActive(false); setOverLayActive(false) }}>Close</span>
          }
        </ChartWidgetFooter>
      </WidgetContainer>
    </div >
  )
}

export default ChartWidgetListMedium