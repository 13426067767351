// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ChartWidgetTitle_titleContainer__wZbjh {\n  display: flex;\n  padding-bottom: 16px;\n  justify-content: space-between;\n}\n.ChartWidgetTitle_iconLeft__vsEs-{\n  margin-right: 5px;\n}", "",{"version":3,"sources":["webpack://./src/components/ui/atoms/chartWidgetTitle/ChartWidgetTitle.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,oBAAoB;EACpB,8BAA8B;AAChC;AACA;EACE,iBAAiB;AACnB","sourcesContent":[".titleContainer {\n  display: flex;\n  padding-bottom: 16px;\n  justify-content: space-between;\n}\n.iconLeft{\n  margin-right: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"titleContainer": "ChartWidgetTitle_titleContainer__wZbjh",
	"iconLeft": "ChartWidgetTitle_iconLeft__vsEs-"
};
export default ___CSS_LOADER_EXPORT___;
