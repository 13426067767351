import { AxiosService } from './AxiosService';

const baseUrl = "sphere/companies";

export const CustomerService = {
    async getAll(id, role) {
        return await AxiosService.getRequest(`${baseUrl}/${id}/${role}/all`);
    },

    async getOne(id) {
        return await AxiosService.getRequest(`${baseUrl}/${id}`);
    },

    async update(id) {
        return await AxiosService.postRequest(`${baseUrl}/${id}/update`,);
    },

    async getAllDisplays(id) {
        return await AxiosService.getRequest(`${baseUrl}/${id}/displays/all`);
    },

    async checkIfCompanyExists(domain) {
        return await AxiosService.getRequest(`${baseUrl}/check/${domain}`);
    }
};