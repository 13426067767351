import { faMagnifyingGlass } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState, useRef } from 'react'
import styles from "./SearchBar.module.css"
import useOnClickOutside from "../../../../hooks/UseOnClickOutside"

const SearchBar = ({ placeholder, onChange }) => {
    const ref = useRef();
    const [isOpen, setIsOpen] = useState(false);
    const [hasText, setHasText] = useState(false);
    useOnClickOutside(ref, () => (!hasText) && setIsOpen(false));

    const clickHandler = () => {
        setIsOpen(true);
    } 

    return (
        <>
            {isOpen === false ?
                <FontAwesomeIcon icon={faMagnifyingGlass} onClick={clickHandler} className={styles.searchButton} />
                : <div className={styles.searchContainer} ref={ref}>
                    <FontAwesomeIcon icon={faMagnifyingGlass} onClick={clickHandler} className={styles.searchIcon} />
                    <input autoFocus onChange={(e) => { e.target.value === '' ? setHasText(false) : setHasText(true); onChange && onChange(e) }} placeholder={placeholder} className={styles.searchInput}></input>
                </div>
            }
        </>
    );
}

export default SearchBar