import styles from './ChartsSidebarHeader.module.css';

const ChartsSidebarHeader = ({ title, subTitle }) => {
    return (
        <div className={styles.container}>
            <span>{title}</span>
            <span className={styles.subTitle}>{subTitle}</span>
        </div>
    );
}

export default ChartsSidebarHeader;