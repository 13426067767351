import { PopUpHeader, PopUpText, PopUpButtons, ButtonColor, PopUp } from '@ctouch-europe-b-v/react-component-library';
import { useAuth0 } from "@auth0/auth0-react";
import { RoleService } from "../../../../services/RoleService";

function IllegalMail() {
    const { logout } = useAuth0();

    return (
        <PopUp>
            <PopUpHeader headerText={"Mail address not allowed"} />
            <PopUpText bodyText={"You cannot use general email domains to create an account. Please use your organization's email domain."} />
            <PopUpButtons hasBtn2 textBtn2={"BACK"} onClickBtn2={() => { RoleService.removeStorage(); logout({ logoutParams: { returnTo: window.location.origin } }) }} variantBtn2={ButtonColor.lifeAquatic} />
        </PopUp>
    )
}

export default IllegalMail