// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AdminMailSentPopUp_adminMail__YT3tv {\n    width: auto;\n    box-sizing: border-box;\n    margin-bottom: 16px;\n}\n\n.AdminMailSentPopUp_adminMail__YT3tv input {\n    width: 100%;\n}", "",{"version":3,"sources":["webpack://./src/components/ui/organisms/adminMailSentPopUp/AdminMailSentPopUp.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,WAAW;AACf","sourcesContent":[".adminMail {\n    width: auto;\n    box-sizing: border-box;\n    margin-bottom: 16px;\n}\n\n.adminMail input {\n    width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"adminMail": "AdminMailSentPopUp_adminMail__YT3tv"
};
export default ___CSS_LOADER_EXPORT___;
