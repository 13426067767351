// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BetaFeature_betaFeature__dPWtX {\n  padding: 8px 32px 8px;\n  gap: 0 10px;\n}\n\n.BetaFeature_betaFeatureText__DJBB8 {\n  font-family: \"Quatro\", \"Verdana\";\n  color: #262626;\n  font-size: 13px;\n  font-style: normal;\n  font-weight: 350;\n  line-height: 13px;\n  letter-spacing: 0.65px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n.BetaFeature_iconWrapper__THaz- {\n  display:flex;\n  justify-content: center;\n  align-items: center;\n}\n.BetaFeature_icon__YsfM6 {\n  font-size: 13px;\n  font-style: normal;\n  font-weight: 350;\n  line-height: 13px;\n  letter-spacing: 0.65px;\n}", "",{"version":3,"sources":["webpack://./src/components/ui/atoms/betaFeature/BetaFeature.module.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,WAAW;AACb;;AAEA;EACE,gCAAgC;EAChC,cAAc;EACd,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,sBAAsB;EACtB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;AACA;EACE,YAAY;EACZ,uBAAuB;EACvB,mBAAmB;AACrB;AACA;EACE,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,sBAAsB;AACxB","sourcesContent":[".betaFeature {\n  padding: 8px 32px 8px;\n  gap: 0 10px;\n}\n\n.betaFeatureText {\n  font-family: \"Quatro\", \"Verdana\";\n  color: #262626;\n  font-size: 13px;\n  font-style: normal;\n  font-weight: 350;\n  line-height: 13px;\n  letter-spacing: 0.65px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n.iconWrapper {\n  display:flex;\n  justify-content: center;\n  align-items: center;\n}\n.icon {\n  font-size: 13px;\n  font-style: normal;\n  font-weight: 350;\n  line-height: 13px;\n  letter-spacing: 0.65px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"betaFeature": "BetaFeature_betaFeature__dPWtX",
	"betaFeatureText": "BetaFeature_betaFeatureText__DJBB8",
	"iconWrapper": "BetaFeature_iconWrapper__THaz-",
	"icon": "BetaFeature_icon__YsfM6"
};
export default ___CSS_LOADER_EXPORT___;
