// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ChartsSidebar_container__HWg3z {\n    padding: 0;\n    margin: 0;\n    background: #F0F1F3;\n}\n.ChartsSidebar_sideBarContainer__S0H03 {\n    padding: 25px 30px;\n}\n.ChartsSidebar_spacer__k\\+xIi {\n    border-bottom:1px solid #cccccc;\n    width: 100%;\n    margin:0;\n}\n.ChartsSidebar_sideBarHeader__RJXQ4 {\n    font-family: Quatro;\n    font-size: 17px;\n    font-style: normal;\n    font-weight: 900;\n    line-height: 17px; \n    letter-spacing: 0.51px;\n    text-transform: uppercase;\n}\n.ChartsSidebar_inner__zWetn {\n    padding-left: 12px;\n}\n.ChartsSidebar_modelIcon__zSQnt {\n    padding-left: 4px;\n}\n\n.ChartsSidebar_modelIcon__zSQnt:hover{\n    cursor: pointer;\n}", "",{"version":3,"sources":["webpack://./src/components/ui/molecules/chartsSidebar/ChartsSidebar.module.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,SAAS;IACT,mBAAmB;AACvB;AACA;IACI,kBAAkB;AACtB;AACA;IACI,+BAA+B;IAC/B,WAAW;IACX,QAAQ;AACZ;AACA;IACI,mBAAmB;IACnB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB;IACjB,sBAAsB;IACtB,yBAAyB;AAC7B;AACA;IACI,kBAAkB;AACtB;AACA;IACI,iBAAiB;AACrB;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".container {\n    padding: 0;\n    margin: 0;\n    background: #F0F1F3;\n}\n.sideBarContainer {\n    padding: 25px 30px;\n}\n.spacer {\n    border-bottom:1px solid #cccccc;\n    width: 100%;\n    margin:0;\n}\n.sideBarHeader {\n    font-family: Quatro;\n    font-size: 17px;\n    font-style: normal;\n    font-weight: 900;\n    line-height: 17px; \n    letter-spacing: 0.51px;\n    text-transform: uppercase;\n}\n.inner {\n    padding-left: 12px;\n}\n.modelIcon {\n    padding-left: 4px;\n}\n\n.modelIcon:hover{\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ChartsSidebar_container__HWg3z",
	"sideBarContainer": "ChartsSidebar_sideBarContainer__S0H03",
	"spacer": "ChartsSidebar_spacer__k+xIi",
	"sideBarHeader": "ChartsSidebar_sideBarHeader__RJXQ4",
	"inner": "ChartsSidebar_inner__zWetn",
	"modelIcon": "ChartsSidebar_modelIcon__zSQnt"
};
export default ___CSS_LOADER_EXPORT___;
