// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DisplaySubtitle_subtitle__c0RYV {\n    font-family: 'Quatro';\n    font-style: normal;\n    font-weight: 350;\n    font-size: 8px;\n    line-height: 100%;\n    /* identical to box height, or 8px */\n\n    letter-spacing: 0.05em;\n\n    /* Gray 1 */\n\n    color: #666666;\n}\n\n.DisplaySubtitle_selected__zpa8U {\n    color: #E6E6E6;\n}", "",{"version":3,"sources":["webpack://./src/components/ui/atoms/displaySubtitle/DisplaySubtitle.module.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,kBAAkB;IAClB,gBAAgB;IAChB,cAAc;IACd,iBAAiB;IACjB,oCAAoC;;IAEpC,sBAAsB;;IAEtB,WAAW;;IAEX,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB","sourcesContent":[".subtitle {\n    font-family: 'Quatro';\n    font-style: normal;\n    font-weight: 350;\n    font-size: 8px;\n    line-height: 100%;\n    /* identical to box height, or 8px */\n\n    letter-spacing: 0.05em;\n\n    /* Gray 1 */\n\n    color: #666666;\n}\n\n.selected {\n    color: #E6E6E6;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"subtitle": "DisplaySubtitle_subtitle__c0RYV",
	"selected": "DisplaySubtitle_selected__zpa8U"
};
export default ___CSS_LOADER_EXPORT___;
