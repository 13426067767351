import styles from "./SettingsTitle.module.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from "@fortawesome/pro-solid-svg-icons";
import SearchBar from "../../atoms/searchBar/SearchBar";
import StatusBullet from "../../atoms/statusBullet/StatusBullet";
import { useNavigate } from "react-router-dom";
import Routes from "../../../../enums/Routes";

const SettingsTitle = ({ title, subtitle, status, className, onSearchChange, filters, companyId }) => {
    const navigate = useNavigate();
    return (
        <div className={[styles.row, className].filter(e => !!e).join(' ')}>
            <div className={styles.iconTitleContainer}>
            <FontAwesomeIcon icon={faArrowLeft} className={styles.prefix} onClick={() => {filters !== null ? navigate("/" + Routes.displayOverview, {state: {company_id:companyId, filters:filters}}) : navigate(-1)}} />
                <span className={styles.textContainer}>
                    <span className={styles.titleIndicatorContainer}>
                        <span className={styles.title}> {title}</span>
                    </span>
                    {subtitle && <span className={styles.subTitle}> {subtitle}</span>}
                </span>
            </div>
            <SearchBar onChange={onSearchChange} placeholder={"Type to search..."} />
        </div>
    );
}

export default SettingsTitle