import styles from "./SettingsSidebarHeader.module.css"

const SettingsSidebarHeader = ({ title }) => {
    return (
        <div className={styles.container}>
            <span className={styles.title}>{title}</span>
        </div>
    );
}

export default SettingsSidebarHeader;