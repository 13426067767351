
const useSort = () => {
    const sortList = (data, state, propAccessor) => {
        if (state === null || state === 2) {
            return data;
        } else {
            data = data.sort((a, b) => {
                let propA = propAccessor(a);
                let propB = propAccessor(b);
                if (typeof propA === 'string') propA = propA.toLowerCase();
                if (typeof propB === 'string') propB = propB.toLowerCase();
                if (propA === null && propB !== null) {
                    return 1;
                } else if (propA !== null && propB === null) {
                    return -1;
                } else {
                    if (propA > propB) return 1;
                    else if (propA < propB) return -1;
                    else return 0;
                }
            });
            if (state === 1) return data.reverse();
            return data;
        }

    }
    return { sortList };
}

export default useSort