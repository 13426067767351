import { AxiosService } from './AxiosService';

const baseUrl = "sphere/companies/user";

export const UserService = {
    async create(user) {
        return await AxiosService.postRequest(`${baseUrl}/create`, user);
    },
    async update(user) {
        return await AxiosService.postRequest(`${baseUrl}/update`, user);
    }
};