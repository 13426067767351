import styles from "./ContextMenuButton.module.css"
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fal } from "@fortawesome/pro-light-svg-icons";
library.add(fal);

function ContextMenuButton({buttonText, iconName, hasRedText, isDisabled, onClick}){
    return (
        <li className={[styles.contextMenuButton, hasRedText && styles.redText, isDisabled && styles.disabled].filter(e => !!e).join(' ')} onClick={!isDisabled && onClick}>{iconName != null && <FontAwesomeIcon icon={["fal", iconName]} className={styles.icon} />}{buttonText}</li>
    )
}

export default ContextMenuButton;