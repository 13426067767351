// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Tab_container__89WIO {\n  width: 100px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  align-content: center;\n  border-radius: 5px 5px 0 0;\n  height: 100%;\n  cursor: pointer;\n}\n\n.Tab_icon__dmK9O {\n  padding-right: 10px;\n}\n\n.Tab_title__zopPq {\n  padding-bottom: 3px;\n}\n\n.Tab_selected__WgmTO {\n  background-color: white;\n}", "",{"version":3,"sources":["webpack://./src/components/ui/atoms/tab/Tab.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,qBAAqB;EACrB,0BAA0B;EAC1B,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,uBAAuB;AACzB","sourcesContent":[".container {\n  width: 100px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  align-content: center;\n  border-radius: 5px 5px 0 0;\n  height: 100%;\n  cursor: pointer;\n}\n\n.icon {\n  padding-right: 10px;\n}\n\n.title {\n  padding-bottom: 3px;\n}\n\n.selected {\n  background-color: white;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Tab_container__89WIO",
	"icon": "Tab_icon__dmK9O",
	"title": "Tab_title__zopPq",
	"selected": "Tab_selected__WgmTO"
};
export default ___CSS_LOADER_EXPORT___;
