import styles from './CreateDisplayForm.module.css'
import { PopUpHeader, PopUpSpacer, Input, InputType, PopUpText, PopUpButtons, ButtonColor } from '@ctouch-europe-b-v/react-component-library';
import { useEffect, useState } from 'react';
import useConnectCode from '../../../../hooks/UseConnectCode';
import { DisplayService } from '../../../../services/DisplayService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink } from 'react-router-dom';
import Routes from "../../../../enums/Routes";

function CreateDisplayForm({ closeAddDisplay, locations, company_id, handleCreated }) {
    const [displayName, setDisplayName] = useState(null);
    const [location, setLocation] = useState(null);
    const [serialNumber, setSerialNumber] = useState(null);
    const [provisioningCode, setProvisioningCode] = useState();
    const [submitDisabled, setSubmitDisabled] = useState(true);
    const [serialExists, setSerialExists] = useState(false);
    const [duplicateSerialMes, setDuplicateSerialMes] = useState();
    const { getProvisioningCode } = useConnectCode();
    const regex = "(55|65|75|86)((SPR|spr)([0-9A-Fa-f]{2}){4}([0-9A-Fa-f]{2})|([0-9a-fA-F]{4}\\.[0-9a-fA-F]{4}\\.[0-9a-fA-F]{4})|(X|x)(02|03|04|05|06)(RA|ra|CN|cn|DT|dt|RT|rt|CC|cc)([0-9]{1})(0[1-9]|1[012])([A-Z]|[a-z]{1})([0-9]{4}))";
    useEffect(() => {
        setProvisioningCode(getProvisioningCode());
    }, []);

    useEffect(() => {
        if (displayName !== null && location !== null && serialNumber !== null && !serialExists) {
            setSubmitDisabled(false)
        } 
        else setSubmitDisabled(true)
    }, [displayName, location, serialNumber])

    const createDisplay = () => {
        if (serialNumber?.match(regex)) {
            DisplayService.create({ "name": displayName, "location_name": location, "serial": serialNumber, "connectCode": provisioningCode, "company_id": company_id }).then(() => {
                closeAddDisplay();
                handleCreated();
            });
        }
    }

    const checkSerialDuplicate = (serial) => {
        if (serial.length === 15) {
            DisplayService.checkDuplicateSerial(serial).then((response) => {
                if(response.serialExists === 1) {
                    setDuplicateSerialMes("Serial number already registered")
                    setSerialExists(true)
                    setSubmitDisabled(true)
                } else {
                    setSerialExists(false);
                    setSubmitDisabled(false);
                    setDuplicateSerialMes();
                }
            });
        }
    }

    return (
        <form>
            <PopUpHeader headerText={"Add a display"} />
            <PopUpText hasIcon bodyText={[<span className={styles.redText}>This method only works for <span className={styles.boldText}>Riva 1</span></span>, ". For other Sphere compatible displays, please create a global connect code ", <NavLink className={styles.linkText} to={'/' + Routes.accountSettings}>here</NavLink>, "."]} />
            <PopUpSpacer />
            <Input inputType={InputType.text} labelText={"Display name"} name={"displayName"} onChange={(e) => setDisplayName(e.target.value)} />
            <Input inputType={InputType.dataList} labelText={"Location"} options={locations.map((location) => location.location_name)} name={"location"} className={styles.dropDown} onChange={(e) => setLocation(e.target.value)} />
            <Input inputType={InputType.text} labelText={"Serial number"} pattern={regex} title={'Invalid serial number'} name={"serialNumber"} onChange={(e) => {setSerialNumber(e.target.value.toUpperCase()); checkSerialDuplicate(e.target.value)}} value={serialNumber} />
            { duplicateSerialMes && <PopUpText bodyText={duplicateSerialMes} hasIcon /> }
            <PopUpSpacer />
            <PopUpText bodyText={["First time? Follow these handy ", <a className={styles.linkText} href="https://support.ctouch.eu/hc/en-us/articles/4409052450066-User-Manual" target="_blank" rel="noreferrer">setup instructions</a>, "."]} />
            <span className={styles.connectCodeWrapper}><Input className={styles.connectCode} inputType={InputType.text} value={provisioningCode} labelText={"Connect code"} disabled /><FontAwesomeIcon className={styles.copyIcon} icon={["fal", "copy"]} onClick={() => navigator.clipboard.writeText(provisioningCode)} /></span>
            <PopUpSpacer />
            <PopUpButtons hasCancelBtn hasBtn1 variantCancel={ButtonColor.grey} btn1Disabled={submitDisabled} textBtn1={"ADD"} onClickBtn1={createDisplay} onClickCancel={closeAddDisplay} />
        </form>
    )
}

export default CreateDisplayForm;