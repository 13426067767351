import { RoleService } from "../../../../services/RoleService";
import ContextMenuButton from "../../atoms/contextMenuButton/ContextMenuButton";
import ContextSpacer from "../../atoms/contextSpacer/ContextSpacer";
import styles from "./HelpContextMenu.module.css"
import { useAuth0 } from "@auth0/auth0-react";

function HelpContextMenu({ innerRef, className, openReleaseNotes }) {
    const { logout } = useAuth0();
    return (
        <ul className={[styles.helpMenu, className].filter(e => !!e).join(' ')} ref={innerRef}>
            <ContextMenuButton onClick={() => { RoleService.removeStorage(); logout({ logoutParams: { returnTo: window.location.origin } }) }} buttonText={"Log out"} iconName={"arrow-right-to-bracket"} hasRedText />
            <ContextSpacer />
            <ContextMenuButton buttonText={"Help Guide"} iconName={"life-ring"} onClick={() => window.open("https://support.ctouch.eu/hc/en-us/articles/4409052450066-User-Manual", "_blank", "noreferrer")} />
            <ContextMenuButton buttonText={"Send us a message"} iconName={"envelope-open-text"} onClick={() => window.open("https://ctouch.eu/nl/contact", "_blank", "noreferrer")} />
            <ContextSpacer />
            <ContextMenuButton buttonText={"New features (v2.4.0)"} iconName={"gem"} onClick={openReleaseNotes} />
            <ContextSpacer />
            {/* <ContextMenuButton buttonText={new Date().getFullYear() + " CTOUCH Sphere"} iconName={"copyright"} isDisabled /> */}
            <ContextMenuButton buttonText={"Privacy statement"} iconName={"address-card"} onClick={() => window.open("https://ctouch.eu/privacy-statement", "_blank", "noreferrer")} />
            <ContextMenuButton buttonText={"Cookie statement"} iconName={"cookie-bite"} onClick={() => window.open("https://ctouch.eu/uk/cookies", "_blank", "noreferrer")} />
        </ul>
    )
}

export default HelpContextMenu;