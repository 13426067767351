import FooterSideButton from '../footerSideButton/FooterSideButton';
import HelpMenu from '../helpMenu/HelpMenu';
import styles from './DisplayFooter.module.css'
import { Button, ButtonColor } from '@ctouch-europe-b-v/react-component-library';
import { useState, useEffect } from 'react';
import { DisplayService } from '../../../../services/DisplayService';
import { useWebSocket } from '../../../../hooks/UseWebSocket';
import DisplayInfoPopUp from '../../atoms/displayInfoPopUp/DisplayInfoPopUp';

function DisplayFooter({ companySub, selectedDisplays, isMultiSelectActive, locations, company_id, handleCreated, isFirmwareUpgradeActive, handleFirmwareUpgradeActive, setMultiUpdate, setSelected }) {
    const { joinRoom, update } = useWebSocket();
    const [showPopup, setShowPopup] = useState(false)
    const delay = ms => new Promise(res => setTimeout(res, ms));


    useEffect(() => {
        if (showPopup) {
            delay(5000).then(() => {
                setShowPopup(false)
            })
        }
    }, [showPopup])


    const updateFirmware = () => {
        setMultiUpdate(true);
        selectedDisplays.forEach((display) => {
            if (display.is_updating !== 1) {
                joinRoom(display.serialNumber)
                update(display.serialNumber)
                display.is_updating = 1;
                const currentTime = new Date();
                const endTime = new Date();
                endTime.setTime(currentTime.getTime() + (25 * 60 * 1000))
                DisplayService.saveUpdateFirmwareData({
                    serial: display.serialNumber,
                    currentFirmware: display.firmware,
                    newFirmware: display.latest_version,
                    startTime: currentTime,
                    endTime: endTime
                })
                // DisplayService.updateFirmware(display).then(() => { return });
            }

        });
        setSelected([])
    }

    return (
        <div className={styles.container}>
            <div className={styles.sideButton}>
                <FooterSideButton hasIcon iconName={"circle-plus"} locations={locations} company_id={company_id} handleCreated={handleCreated} />
            </div>
            <div className={styles.mainFooter}>
                <HelpMenu />
                <div className={styles.buttonsContainer}>
                    {(!isMultiSelectActive && !isFirmwareUpgradeActive) && <span className={styles.popupContainer}><Button title={"Update Firmware"} iconName={"arrow-down-to-bracket"} variant={ButtonColor.grey} onClick={() => { companySub?.toLowerCase() !== "safe" ? setShowPopup(true) : handleFirmwareUpgradeActive() }} /> {showPopup && <DisplayInfoPopUp onClick={() => setShowPopup(false)} variant={""}>Upgrade to <strong>Heartbeat SAFE</strong> to manage displays simultaniously <a target="_blank" rel="noreferrer" href="https://ctouch.eu/heartbeat">Learn more</a>  </DisplayInfoPopUp>} </span>}

                    {isFirmwareUpgradeActive && <> <Button title={"Update"} variant={ButtonColor.grey} onClick={updateFirmware} /> <Button title={"Cancel"} variant={ButtonColor.grey} onClick={handleFirmwareUpgradeActive} /> </>}
                </div>

            </div>
        </div>
    )
}

export default DisplayFooter;