// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ChartWidgetGraphLarge_green__DlhkB{\n  background-color: #00BB00;\n}\n.ChartWidgetGraphLarge_blue__\\+Cdz6{\n  background-color: #009FDA\n}\n.ChartWidgetGraphLarge_grey__rFayi {\n  background-color: #F0F1F3;\n  color: #666;\n}\n.ChartWidgetGraphLarge_yellow__O0N6r {\n  background-color: #FFC800;\n  color: #262626;\n}\n.ChartWidgetGraphLarge_container__Cugla:not(.ChartWidgetGraphLarge_errorContainer__vi3Xt) {\n  position: relative;\n  grid-template-areas:\n        \"header\"\n        \"content\";\n  grid-template-rows: 19px auto;\n  align-content: space-around\n}\n.ChartWidgetGraphLarge_errorContainer__vi3Xt {\n  text-align: center;\n}", "",{"version":3,"sources":["webpack://./src/components/ui/molecules/chartWidgetGraphLarge/ChartWidgetGraphLarge.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;AAC3B;AACA;EACE;AACF;AACA;EACE,yBAAyB;EACzB,WAAW;AACb;AACA;EACE,yBAAyB;EACzB,cAAc;AAChB;AACA;EACE,kBAAkB;EAClB;;iBAEe;EACf,6BAA6B;EAC7B;AACF;AACA;EACE,kBAAkB;AACpB","sourcesContent":[".green{\n  background-color: #00BB00;\n}\n.blue{\n  background-color: #009FDA\n}\n.grey {\n  background-color: #F0F1F3;\n  color: #666;\n}\n.yellow {\n  background-color: #FFC800;\n  color: #262626;\n}\n.container:not(.errorContainer) {\n  position: relative;\n  grid-template-areas:\n        \"header\"\n        \"content\";\n  grid-template-rows: 19px auto;\n  align-content: space-around\n}\n.errorContainer {\n  text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"green": "ChartWidgetGraphLarge_green__DlhkB",
	"blue": "ChartWidgetGraphLarge_blue__+Cdz6",
	"grey": "ChartWidgetGraphLarge_grey__rFayi",
	"yellow": "ChartWidgetGraphLarge_yellow__O0N6r",
	"container": "ChartWidgetGraphLarge_container__Cugla",
	"errorContainer": "ChartWidgetGraphLarge_errorContainer__vi3Xt"
};
export default ___CSS_LOADER_EXPORT___;
