import styles from './PowerTimerDay.module.css';
function PowerTimerDay({days, value, onDayChange}) {
  let buttonList = days.sort((a,b) => {
    return a.id > b.id ? 1 : a.id < b.id ? - 1 : 0
  } ).map(day => {
    return <button key={day.id} className={`${styles.dayButton} ${value !== null && (value.includes(day.name) || value === "Everyday" || (value.includes("Te") && day.name === "Tu"))? styles.active :""}`} defaultValue={day.name} onClick={() => {onDayChange(day)}}>{day.name === "Te" ? "Tu" : day.name}</button>
  })
  return (
    <div className={styles.dayButtonGroup}>
      {buttonList}
    </div>
  )

}

export default PowerTimerDay