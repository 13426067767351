// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DisplayInformation_displayInformation__-ypKK {\n    display: flex;\n    /* position: relative; */\n    flex-direction: column;\n}\n\n.DisplayInformation_popup__oXQPT {\n    bottom: 45px !important;\n}", "",{"version":3,"sources":["webpack://./src/components/ui/molecules/displayInformation/DisplayInformation.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,wBAAwB;IACxB,sBAAsB;AAC1B;;AAEA;IACI,uBAAuB;AAC3B","sourcesContent":[".displayInformation {\n    display: flex;\n    /* position: relative; */\n    flex-direction: column;\n}\n\n.popup {\n    bottom: 45px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"displayInformation": "DisplayInformation_displayInformation__-ypKK",
	"popup": "DisplayInformation_popup__oXQPT"
};
export default ___CSS_LOADER_EXPORT___;
