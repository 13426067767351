import styles from "./LocationTitle.module.css"

function LocationTitle({location}) {
    return (
        <span className={styles.title}>
            {location}
        </span>
    )
}

export default LocationTitle;