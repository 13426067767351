import styles from "./FirstPopUp.module.css"

import { PopUpHeader, PopUpText, PopUpButtons, ButtonColor, PopUp } from '@ctouch-europe-b-v/react-component-library';

function FirstPopUp({setPage}){
    return (
        <PopUp>
            <PopUpHeader headerText={"You are the first!"} />
            <PopUpText bodyText={"You are the first person in your company to make an account! Are you the system admin for the CTOUCH displays in your organization?"} />
            <PopUpText bodyText={"I am:"} />
            <PopUpButtons hasBtn1 onClickBtn1={() => {setPage(1)}} textBtn1={"AN ADMIN"} hasBtn2 onClickBtn2={() => {setPage(2)}} textBtn2={"NOT AN ADMIN"} variantBtn2={ButtonColor.lifeAquatic} />
        </PopUp>
    )
}

export default FirstPopUp;