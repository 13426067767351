// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ChartsSidebarField_container__HLwUi {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    font-size: 13px;\n    font-weight: 350;\n    font-family: 'Quatro';\n    padding-top: 15px;\n    padding-bottom: 15px;\n}\n\n.ChartsSidebarField_value__ROaIk {\n    color: #262626;\n    font-weight: 600;\n    font-size: 10px;\n}\n\n.ChartsSidebarField_icon__VNyky {\n    margin-right: 13px;\n}", "",{"version":3,"sources":["webpack://./src/components/ui/atoms/chartsSidebarField/ChartsSidebarField.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,eAAe;IACf,gBAAgB;IAChB,qBAAqB;IACrB,iBAAiB;IACjB,oBAAoB;AACxB;;AAEA;IACI,cAAc;IACd,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":[".container {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    font-size: 13px;\n    font-weight: 350;\n    font-family: 'Quatro';\n    padding-top: 15px;\n    padding-bottom: 15px;\n}\n\n.value {\n    color: #262626;\n    font-weight: 600;\n    font-size: 10px;\n}\n\n.icon {\n    margin-right: 13px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ChartsSidebarField_container__HLwUi",
	"value": "ChartsSidebarField_value__ROaIk",
	"icon": "ChartsSidebarField_icon__VNyky"
};
export default ___CSS_LOADER_EXPORT___;
