// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ContextSpacer_spacer__6mf88 {\n    margin: 8px 0;\n    height: 1px;\n    opacity: 0.1;\n    background: #262626;\n}", "",{"version":3,"sources":["webpack://./src/components/ui/atoms/contextSpacer/ContextSpacer.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,WAAW;IACX,YAAY;IACZ,mBAAmB;AACvB","sourcesContent":[".spacer {\n    margin: 8px 0;\n    height: 1px;\n    opacity: 0.1;\n    background: #262626;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"spacer": "ContextSpacer_spacer__6mf88"
};
export default ___CSS_LOADER_EXPORT___;
