// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AppListText_textWrapper__a9u79 {\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  gap: 4px 0;\n  width: 320px;\n}\n.AppListText_titleText__zZkw\\+ {\n  font-family: \"Quatro\", \"Verdana\";\n  color: #262626;\n  font-size: 13px;\n  font-style: normal;\n  font-weight: 350;\n  line-height: 13px;\n  letter-spacing: 0.65px;\n}\n.AppListText_descriptionText__a9Mrx {\n  color: #666;\n  font-size: 8px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 8px;\n  letter-spacing: 0.4px;\n}", "",{"version":3,"sources":["webpack://./src/components/ui/atoms/appListText/AppListText.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,2BAA2B;EAC3B,UAAU;EACV,YAAY;AACd;AACA;EACE,gCAAgC;EAChC,cAAc;EACd,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,sBAAsB;AACxB;AACA;EACE,WAAW;EACX,cAAc;EACd,kBAAkB;EAClB,gBAAgB;EAChB,gBAAgB;EAChB,qBAAqB;AACvB","sourcesContent":[".textWrapper {\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  gap: 4px 0;\n  width: 320px;\n}\n.titleText {\n  font-family: \"Quatro\", \"Verdana\";\n  color: #262626;\n  font-size: 13px;\n  font-style: normal;\n  font-weight: 350;\n  line-height: 13px;\n  letter-spacing: 0.65px;\n}\n.descriptionText {\n  color: #666;\n  font-size: 8px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 8px;\n  letter-spacing: 0.4px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textWrapper": "AppListText_textWrapper__a9u79",
	"titleText": "AppListText_titleText__zZkw+",
	"descriptionText": "AppListText_descriptionText__a9Mrx"
};
export default ___CSS_LOADER_EXPORT___;
