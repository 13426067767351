import TableHeaderCell from "../../molecules/tableHeaderCell/TableHeaderCell";
import styles from "./HeaderBar.module.css";
import SearchBar from './../../atoms/searchBar/SearchBar';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fal } from "@fortawesome/pro-light-svg-icons";
import { library } from '@fortawesome/fontawesome-svg-core';
import { useState } from 'react';
import { useEffect } from 'react';
import { InputType } from '@ctouch-europe-b-v/react-component-library';

library.add(fal);
const HeaderBar = (props) => {
    // const [activeFilters, setActiveFilters] = useState([]);
    // useEffect(() => {
    //     const emptyFilters = [];
    //     setActiveFilters(emptyFilters);
    // }, []);

    // const updateActiveFilters = (title, filters) => {
    //     activeFilters[title] = filters;
    //     setActiveFilters(activeFilters);
    //     props.updateActiveFilters && props.updateActiveFilters(activeFilters);
    // }
    return (   
        <div className={[styles.row, props.className].filter(e => !!e).join(' ')}>
            {props.children}
            {
                props.hasFilters ?
                    props.fields.map((field, index) => {
                        return <TableHeaderCell key={index} title={Object.keys(field)[0]} items={[field]} headerType={Object.keys(field)[0] === "serialNumber" ? InputType.text : InputType.checkBox} hasFilter setSort={(state, title) => props.setSort && props.setSort(state, title)}  />
                    }) : props.fields.map((field, index) => {
                        return <TableHeaderCell hasFilter={false} key={index} title={field} />
                    })
            }
            <div className={styles.actionContainer}>
                <FontAwesomeIcon className={styles.refresh} icon={['fal', "refresh"]} onClick={() => window.location.reload(false)} />
                <SearchBar placeholder={"Type to search..."} onChange={props.onSearchChange} />
            </div>
        </div>
    );
}

export default HeaderBar