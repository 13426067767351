import DisplayInfoPopUp from '../displayInfoPopUp/DisplayInfoPopUp'
import styles from './ChartWidgetList.module.css'
import { useState, useRef } from "react";

const ChartWidgetList = ({ data, plusIsPositive, className, fullViewActive, sortIsNeeded, ascending, size, max, hasTopThree, background, containsPlus }) => {
  let dataArray = [];
  const [currentHoveredItem, setCurrentHoveredItem] = useState(null);
  const [popupPosition, setPopupPosition] = useState({ bottom: 0 });
  const containerRef = useRef(null)
  if (!Array.isArray(data)) {
    Object.entries(data).forEach(([key, value]) => dataArray.push({ title: key, value: value }))
    data = dataArray
  }
  if (sortIsNeeded) {
    data.sort((a, b) => {
      const aValue = typeof a.value === "string" ? parseInt(a.value.split("%")[0]) : a.value;
      const bValue = typeof b.value === "string" ? parseInt(b.value.split("%")[0]) : b.value;
      if (plusIsPositive || ascending) {
        if (aValue > bValue) return -1
        else if (aValue < bValue) return 1
        else return 0
      } else {
        if (aValue > bValue) return 1
        else if (aValue < bValue) return -1
        else return 0
      }
    })
  }
  const handleItemHover = (index, event) => {
    const listItem = event.target.getBoundingClientRect();
    const listContainer = containerRef?.current.getBoundingClientRect();
    const itemBottom = listItem.bottom;
    const containerTop = listContainer.bottom;
    const testLocation = containerTop - itemBottom;
    setCurrentHoveredItem(index);
    setPopupPosition({ bottom: testLocation + 72 })
  }
  const handleItemLeave = () => {
    setCurrentHoveredItem(prev => prev)
  }
  const listStyling = (itemValue) => {
    if (plusIsPositive !== undefined) {
      return (itemValue > 0 && plusIsPositive) || (itemValue < 0 && !plusIsPositive) ? styles.positive : itemValue === 0 ? styles.equal : styles.negative
    }
    else {
      return
    }
  }
  const renderTitle = (items) => {
    return items.title !== undefined ? items.title : items.name !== undefined ? items.name.replaceAll('"', "") : items.location_name !== undefined ? items.location_name : items.date !== undefined ? items.date : items[0].replaceAll('"', "")
  }
  const longTextStyling = (text) => {
    let splitAnywhere = false;
    const textSplit = text.split(" ");
    if (textSplit.length > 1) {
      textSplit.forEach(item => splitAnywhere = item.length > 12)
    } else if (textSplit.length === 1) { splitAnywhere = true }
    return splitAnywhere && styles.longTextPopup
  }

  const convertToFloat = (string) => {
    return parseFloat(string.replace(",", "."))
  }

  const renderHourNumber = (string) => {
    return typeof string === "string" && string.includes("h") && convertToFloat(string).toString().length > 4 ? `${Math.ceil(convertToFloat(string))} h` : string
  }
  return (
    <div ref={containerRef} className={[styles.listContent, (data.length > 12 && fullViewActive) && styles.scroll, fullViewActive && styles.fullView, styles[`background${background}`], className].filter(e => !!e).join(" ")}>{
      data.map((item, index) => {

        const itemValue = (typeof item.value === "string" && plusIsPositive !== undefined) ? parseInt(item.value.split("%")[0]) : item.value;
        if (max === undefined && !fullViewActive) {
          return (index === 0 || index === data.length - 1) && <span key={index} onMouseEnter={(e) => handleItemHover(`${index}-${renderTitle(item)}`, e)}
            onMouseLeave={handleItemLeave}
            id={`${index}-${renderTitle(item)}`} className={[styles.listItem, fullViewActive && styles.open, size === "medium" && styles.medium].filter(e => !!e).join(" ")}>
            <span className={styles.listItemContent}>{renderTitle(item)}</span>
            <span className={[styles.listItemValue, ((itemValue > 0 && plusIsPositive) || (itemValue < 0 && !plusIsPositive) ? styles.positive : itemValue === 0 ? styles.equal : styles.negative), typeof item.value === "string" && item.value.includes("h") && styles.withSpace].filter(e => !!e).join(" ")}>{(containsPlus && item.value !== "0%" && !item.value.includes("-")) && "+"}{renderHourNumber(item.value)} </span>
            {currentHoveredItem === `${index}-${renderTitle(item)}` && <DisplayInfoPopUp style={{ bottom: popupPosition.bottom }} className={[styles.popup].filter(e => !!e).join(" ")}>{renderTitle(item)}</DisplayInfoPopUp>}
          </span>
        } else if (max !== undefined) {
          return index < max && <span
            onMouseEnter={(e) => handleItemHover(`${index}-${renderTitle(item)}`, e)}
            onMouseLeave={handleItemLeave}
            id={`${index}-${renderTitle(item)}`}
            key={index}
            className={[
              styles.listItem,
              fullViewActive && styles.open,
              size === "medium" && styles.medium,
              hasTopThree && styles[index === 0 ? "first" : index === 1 ? "second" : index === 2 ? "third" : ""]
            ].filter(e => !!e).join(" ")}>
            <span className={[styles.listItemTitle]}>
              {renderTitle(item)}
            </span>
            {max !== undefined
              ? <span className={[styles.listItemValue, typeof item.value === "string" && item.value.includes("h") && styles.withSpace].filter(e => !!e).join("")}>{(containsPlus && item.value !== "0%" && !item.value.includes("-")) && "+"}{item.value}</span>
              : <span className={[styles.listItemValue, listStyling(itemValue), typeof item.value === "string" && item.value.includes("h") && styles.withSpace].filter(e => !!e).join(" ")}>{(containsPlus && item.value !== "0%" && !item.value.includes("-")) && "+"}{item.current_firmware !== undefined ? item.current_firmware : renderHourNumber(item.value)}</span>
            }
            {currentHoveredItem === `${index}-${renderTitle(item)}` && <DisplayInfoPopUp style={{ bottom: popupPosition.bottom }} className={[styles.popup, longTextStyling(renderTitle(item))].filter(e => !!e).join(" ")}>{renderTitle(item)}</DisplayInfoPopUp>}
          </span>
        } else if (item.$$typeof === Symbol.for('react.element')) {
          return item
        } else {
          return <span
            onMouseEnter={(e) => handleItemHover(`${index}-${renderTitle(item)}`, e)}
            onMouseLeave={handleItemLeave}
            id={`${index}-${renderTitle(item)}`}
            key={index}
            className={[
              styles.listItem,
              fullViewActive && styles.open,
              size === "medium" && styles.medium,
              hasTopThree && styles[index === 0 ? "first" : index === 1 ? "second" : index === 2 ? "third" : ""]
            ].filter(e => !!e).join(" ")}>
            <span className={[styles.listItemTitle]}>
              {renderTitle(item)}
            </span>
            <span className={[styles.listItemValue, listStyling(itemValue), typeof item.value === "string" && item.value.includes("h") && styles.withSpace].filter(e => !!e).join(" ")}>
              {(containsPlus && item.value !== "0%" && !item.value.includes("-")) && "+"}
              {item.current_firmware !== undefined ? item.current_firmware : renderHourNumber(item.value)}
            </span>
            {currentHoveredItem === `${index}-${renderTitle(item)}` && <DisplayInfoPopUp style={{ bottom: popupPosition.bottom }} className={[styles.popup, longTextStyling(renderTitle(item))].filter(e => !!e).join(" ")}>{renderTitle(item)}</DisplayInfoPopUp>}
          </span>
        }
      })}
    </div>
  )
}
export default ChartWidgetList