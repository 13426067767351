// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HelpMenu_container__JT8So {\n    position: relative;\n    height: 100%;\n    box-sizing: border-box;\n    padding: 0 20px;\n    display:flex;\n    align-items: center;\n}\n\n.HelpMenu_HelpContextMenu__fVW8X {\n    position: absolute;\n    bottom: 40px;\n    right: 18px;\n}\n\n.HelpMenu_copyright__HqtPx {\n    font-family:'Quatro';\n    color: #ccc;\n    font-size: 11px;\n}", "",{"version":3,"sources":["webpack://./src/components/ui/organisms/helpMenu/HelpMenu.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,YAAY;IACZ,sBAAsB;IACtB,eAAe;IACf,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,oBAAoB;IACpB,WAAW;IACX,eAAe;AACnB","sourcesContent":[".container {\n    position: relative;\n    height: 100%;\n    box-sizing: border-box;\n    padding: 0 20px;\n    display:flex;\n    align-items: center;\n}\n\n.HelpContextMenu {\n    position: absolute;\n    bottom: 40px;\n    right: 18px;\n}\n\n.copyright {\n    font-family:'Quatro';\n    color: #ccc;\n    font-size: 11px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "HelpMenu_container__JT8So",
	"HelpContextMenu": "HelpMenu_HelpContextMenu__fVW8X",
	"copyright": "HelpMenu_copyright__HqtPx"
};
export default ___CSS_LOADER_EXPORT___;
