import Sidebar from "../../ui/molecules/sidebar/Sidebar";
import HeaderBar from "../../ui/organisms/headerBar/HeaderBar";
import styles from "./SelectCustomer.module.css";
import BusinessName from './../../ui/molecules/businessName/BusinessName';
import HelpMenu from "../../ui/organisms/helpMenu/HelpMenu";
import LocationRow from './../../ui/organisms/locationRow/LocationRow';
import Content from './../../ui/organisms/content/Content';
import { useEffect, useState } from 'react';
import useSearch from './../../../hooks/UseSearch';
import { CustomerService } from "../../../services/CustomerService";
import { useNavigate } from "react-router-dom";
import Routes from "../../../enums/Routes";

const SelectCustomer = ({ updateCurrentCompany, role, companySub }) => {
    const [query, setQuery] = useState('');
    const { search } = useSearch();
    const [customers, setCustomers] = useState([]);
    const [company, setCompany] = useState({});
    const navigate = useNavigate();


    useEffect(() => {
        const primaryRoleFunc = () => {
            if (role.roles.some((role) => role.Name === "CTOUCH")) { return "CTOUCH" }
            else if (role.roles.some((role) => role.Name === "Reseller")) { return "Reseller" }
            else return "User"
        }
        const primaryRole = primaryRoleFunc();
        console.log(primaryRole)
        CustomerService.getAll(role.company_id, primaryRole).then((customers) => {
            setCustomers(customers.data);
            const result = customers.data.Reseller?.filter((customer) => customer.id === role.company_id);
            if (result) {
                setCompany(result[0]);
            }
        });
    }, []);


    const getFilterFields = (customer) => {
        const toRemove = ['id', 'companyDomain', 'role', 'dealerCode'];
        return Object.keys(customer).filter((key) => {
            return toRemove.indexOf(key) < 0;
        })
    }

    return (
        <div className={styles.mainContainer}>
            <Sidebar role={role} companySub={companySub} sphereUser={role} className={styles.sidebar} />
            <HeaderBar hasFilters={false} className={styles.header} fields={['Online', 'Offline', 'Total', 'Dealer']} onSearchChange={(e) => setQuery(e.target.value)}><BusinessName businessName={"Select customer"} hasFilters={false} /></HeaderBar>
            <div className={styles.content}>
                <div className={styles.rowContent}>
                    {
                        customers && Object.keys(customers).map((role) => {
                            return <LocationRow locationTitle={role}>
                                {
                                    search(customers[role], query, getFilterFields(customers[role][0])).map((customer) => {
                                        return <Content isCustomer name={customer.companyName} onClick={() => { updateCurrentCompany(customer.id); navigate('/' + Routes.displayOverview, { state: { company_id: customer.id } }) }} content={[customer.displaysOnline, customer.displaysOffline, customer.totalDisplays, customer.dealerName]} />
                                    })
                                }
                            </LocationRow>
                        })
                    }
                </div>
                <div className={styles.sideBackground}></div>
            </div>
            <div className={styles.sideFooter}>{role.roles.some(role => role.Name === "Reseller") && "Dealer code: " + company.dealerCode}</div>
            <div className={styles.contentFooter}>
                <HelpMenu />
            </div>
        </div>
    );
}

export default SelectCustomer;