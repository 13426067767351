// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RestoreSetupForm_input__KebgC div {\n    width: 100%;\n}", "",{"version":3,"sources":["webpack://./src/components/ui/organisms/restoreSetupForm/RestoreSetupForm.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf","sourcesContent":[".input div {\n    width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": "RestoreSetupForm_input__KebgC"
};
export default ___CSS_LOADER_EXPORT___;
