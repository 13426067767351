import styles from './DisplayInfoPopUp.module.css'

const DisplayInfoPopUp = ({ style, variant, children, className, ...props }) => {

  return (
    <div style={style} className={[styles.dateWrapper, styles[variant], className].filter(e => !!e).join(" ")} onClick={props.onClick}>
      {children}
    </div>
  )
}
export default DisplayInfoPopUp;