import { AxiosService } from "./AxiosService"

const baseUrl = "sphere/backups";

export const BackupService = {
    async create(backup) {
        return await AxiosService.postRequest(`${baseUrl}/create_backup`, backup)
    },

    async getAll(companyId, modelType) {
        return await AxiosService.getRequest(`${baseUrl}/${companyId}/${modelType}/get_all_backups`);
    },

    async restoreBackup(backup) {
        return await AxiosService.postRequest(`${baseUrl}/restore_backup`, backup)
    }
}