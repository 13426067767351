import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './ChartsSidebarField.module.css';

const ChartsSidebarField = ({ label, value, iconName, className }) => {
    return (
        <div className={[styles.container, className].filter(e=> !!e).join(" ")}>
            <span>
                {iconName !== undefined && <FontAwesomeIcon className={styles.icon} icon={['fal', iconName]} />}
                <span>{label}</span>
            </span>
            <span className={styles.value}>{value}</span>
        </div>
    );
}

export default ChartsSidebarField;