// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SettingsHeader_row__J8SQb {\n    width: 100%;\n    height: 46px;\n    display: flex;\n    gap: 12px;\n    align-items: center;\n    padding: 10px 0px 0px 0px;\n    box-sizing: border-box;\n}\n\n.SettingsHeader_label__yJMg1 {\n    font-family: 'Quatro';\n    font-style: normal;\n    font-weight: 700;\n    font-size: 10px;\n    line-height: 91%;\n    letter-spacing: 0.015em;\n    text-transform: uppercase;\n    color: #262626;\n}\n\n.SettingsHeader_prefix__BFvU3 {\n    width: 20px;\n    border: 2px solid #262626;\n}", "",{"version":3,"sources":["webpack://./src/components/ui/atoms/settingsHeader/SettingsHeader.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;IACb,SAAS;IACT,mBAAmB;IACnB,yBAAyB;IACzB,sBAAsB;AAC1B;;AAEA;IACI,qBAAqB;IACrB,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,gBAAgB;IAChB,uBAAuB;IACvB,yBAAyB;IACzB,cAAc;AAClB;;AAEA;IACI,WAAW;IACX,yBAAyB;AAC7B","sourcesContent":[".row {\n    width: 100%;\n    height: 46px;\n    display: flex;\n    gap: 12px;\n    align-items: center;\n    padding: 10px 0px 0px 0px;\n    box-sizing: border-box;\n}\n\n.label {\n    font-family: 'Quatro';\n    font-style: normal;\n    font-weight: 700;\n    font-size: 10px;\n    line-height: 91%;\n    letter-spacing: 0.015em;\n    text-transform: uppercase;\n    color: #262626;\n}\n\n.prefix {\n    width: 20px;\n    border: 2px solid #262626;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": "SettingsHeader_row__J8SQb",
	"label": "SettingsHeader_label__yJMg1",
	"prefix": "SettingsHeader_prefix__BFvU3"
};
export default ___CSS_LOADER_EXPORT___;
