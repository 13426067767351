import styles from './DashboardChart.module.css';
import {
  Chart,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler
} from 'chart.js';
import { useEffect, useRef } from 'react';
import { Line, Bar } from 'react-chartjs-2';

Chart.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const roundToFive = (num, up) => {
  return (up) ? Math.ceil(num / 5) * 5 : Math.floor(num / 5) * 5;
}

const getMinMaxValue = (data, stepAmount = 5, size) => {
  let finalMaxValue, finalMinValue;
  const sortedData = data.toSorted((prevValue, currentValue) => {
    const prevValueInt = typeof prevValue.value === "string" ? parseFloat(prevValue.value) : prevValue.value
    const currentValueInt = typeof currentValue.value === "string" ? parseFloat(currentValue.value) : currentValue.value
    return prevValueInt < currentValueInt ? 1 : prevValueInt > currentValueInt ? -1 : 0
  })
  const minValue = sortedData[sortedData.length - 1]?.value;
  const maxValue = sortedData[0]?.value
  let finalAbsValue = null;
  if (minValue?.toString().includes("%")) {
    finalMaxValue = roundToFive(parseFloat(maxValue), true);
    finalMinValue = roundToFive(parseFloat(minValue), false);
    finalAbsValue = Math.max(Math.abs(finalMaxValue), Math.abs(finalMinValue));
    finalAbsValue = finalAbsValue === 0 ? 5 : finalAbsValue;
  }
  else {
    finalMaxValue = roundToFive(parseFloat(maxValue), true)
    finalMinValue = 0
  }
  return {
    min: finalAbsValue !== null ? -finalAbsValue : finalMinValue,
    max: finalAbsValue !== null ? finalAbsValue : finalMaxValue,
    step: finalAbsValue !== null ? Math.ceil(finalAbsValue / 5) : Math.ceil(finalMaxValue / 5)
  }
}


const DashboardChart = ({ size, colorVariant, data, dataAreDates, stepAmount = 10, valueDataType, fill, type, chartId, currentDate }) => {
  const minMaxValue = getMinMaxValue(data, stepAmount, size);

  const chartRef = useRef();

  const colors = {
    "black": "rgba(38, 38, 38, 0.50)",
    "white": "rgba(255, 255, 255, 0.50)",
    "green": "rgba(0, 187, 0, 1)",
    "blue": "rgba(0, 159, 218, 1)",
    "red": "rgba(246, 89, 22, 1)"
  }
  const getOrCreateTooltip = (chart) => {
    let tooltipEl = chart.canvas.parentNode.querySelector('div');

    if (!tooltipEl) {
      tooltipEl = document.createElement('div');
      tooltipEl.style.fontFamily = "Quatro";
      tooltipEl.style.backgroundColor = 'rgba(255, 255, 255, 0.5)';
      tooltipEl.style.borderRadius = '16px';
      tooltipEl.style.backdropFilter = "blur(2.5px)";
      tooltipEl.style.fontSize = "10px";
      tooltipEl.style.color = colors[colorVariant];
      tooltipEl.style.opacity = 1;
      // tooltipEl.style.pointerEvents = 'none';
      tooltipEl.style.position = 'absolute';
      tooltipEl.style.left = "-10";
      tooltipEl.style.transform = 'translate(-50%, -100%)';
      tooltipEl.style.transition = 'all .1s ease';
      tooltipEl.style.fontWeight = "600";
      tooltipEl.style.width = "105px";
      tooltipEl.style.textAlign = "center";

      const table = document.createElement('table');
      table.style.margin = '0px';
      table.style.width = "inherit";

      tooltipEl.appendChild(table);
      chart.canvas.parentNode.appendChild(tooltipEl);
    }

    return tooltipEl;
  };

  const externalTooltipHandler = (context) => {
    // Tooltip Element
    const { chart, tooltip } = context;
    const tooltipEl = getOrCreateTooltip(chart);

    // Hide if no tooltip
    if (tooltip.opacity === 0) {
      tooltipEl.style.opacity = 0;
      return;
    }

    // Set Text
    if (tooltip.body) {
      const bodyLines = tooltip.body.map(b => { return valueDataType === "percent" ? `${b.lines}%` : valueDataType === "hour" ? `${b.lines} hours` : `${b.lines}` });

      const tableHead = document.createElement('thead');

      const tableBody = document.createElement('tbody');
      bodyLines.forEach((body, i) => {

        const tr = document.createElement('tr');
        tr.style.borderWidth = 0;

        const td = document.createElement('td');
        td.style.borderWidth = 0;

        const text = document.createTextNode(body);

        td.appendChild(text);
        td.style.textAlign = "center";
        td.style.width = "inherit"
        tr.appendChild(td);
        tableBody.appendChild(tr);
      });

      const tableRoot = tooltipEl.querySelector('table');

      // Remove old children
      while (tableRoot.firstChild) {
        tableRoot.firstChild.remove();
      }

      // Add new children
      tableRoot.appendChild(tableHead);
      tableRoot.appendChild(tableBody);
    }

    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

    // Display, position, and set styles for font
    tooltipEl.style.opacity = 1;
    tooltipEl.style.left = positionX + tooltip.caretX + 'px';
    tooltipEl.style.top = positionY + tooltip.caretY - 4 + 'px';
    tooltipEl.style.font = tooltip.options.bodyFont.string;
    tooltipEl.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
  };
  const options = {
    responsive: true,
    clip: false,
    aspectRatio: size === "small" ? 4 : "auto 400/204",
    parsing: {
      xAxisKey: "x",
      yAxisKey: "y"
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: {
        enabled: false,
        position: "nearest",
        mode: 'index',
        intersect: false,
        external: size !== "small" && externalTooltipHandler,
        titleAlign: "center",
        bodyAlign: "center",
        footerAlign: "center",
        width: 110,
        callbacks: {
          label: function (tooltipitem) {
            return `${tooltipitem.label.split(" ")[0].toUpperCase()} ${tooltipitem.formattedValue}`
          },
          afterLabel: function (tooltipItem) {
            const originalTooltip = chartRef.current.tooltip.update;
            chartRef.current.tooltip.update = function (c) {
              originalTooltip.apply(this, arguments);
              let tooltipWidth = this._size.width;

              let minWidth = 110;
              if (tooltipWidth < minWidth) {
                this._size.width = minWidth
              }
            };

          }
        }
      },
    },
    interaction: {
      mode: "index",
      intersect: false
    },
    hover: {
      mode: 'nearest',
      intersect: true
    },
    scales: {
      y: {
        maxTickSize: 5,
        suggestedMin: minMaxValue.min,
        suggestedMax: minMaxValue.max,
        beginAtZero: true,
        ticks: {
          stepSize: (c) => { return chartId === "consumptionChart" && Math.abs(minMaxValue.min, minMaxValue.max) < 10 ? 2 : minMaxValue.step },
          backdropPadding: 10,
          display: size === "small" ? false : true,
          color: (c) => {
            if (valueDataType === "percent") {
              return (c['tick']['value'] === 0) ? 'rgba(0, 159, 218, 1)' : (c['tick']['value'] > 0) ? colors.red : c['tick']['value'] < 0 ? colors.green : "#ccc";
            } else {
              return "#666"
            }
          },
          callback: function (value, index, ticks) {
            return valueDataType === "percent" && value === 0 ? `AVG` : valueDataType === "percent" && value !== 0 ? value > 0 ? `+${value}%` : `${value}%` : valueDataType === "hour" ? `${value} hrs` : value
          }
        },
        grid: {
          display: size === "small" ? false : true,
          color: (c) => { if (valueDataType === "percent" && c['tick']['value'] === 0) return 'rgba(0, 159, 218, 1)'; else return "#666"; }
        },
        border: {
          display: false
        }
      },
      x: {
        border: {
          display: false
        },
        grid: {
          display: false
        },
        ticks: {
          align: "center",
          display: size === "small" ? false : true,
          color: "#666",
          callback: function (value, index, ticks) {
            if (index === 0 || index === ticks.length - 1) {
              this.align = "start"
            }
            if (dataAreDates) {
              return (this.getLabelForValue(value).includes("Mon") || this.getLabelForValue(value).includes("F")) ? this.getLabelForValue(value).toUpperCase() : ''
            } else {
              return this.getLabelForValue(value)
            }
          }
        },

      }

    },

  };
  const lineOptions = {
    ...options,
    scales: {
      y: {
        ...options.scales.y,
      },
      x: {
        ...options.scales.x,
        autoSkipPadding: 25,

      }
    }
  }
  const barOptions = {
    ...options,
    scales: {
      y: { ...options.scales.y },
      x: {
        ...options.scales.x,
        hoverBorderColor: "black",
        hoverBorderWidth: "2"
      }
    }

  }

  const getWeekDates = (referenceDate) => {
    const date = new Date(referenceDate);
    const day = date.getDay();
    const diffToMonday = day === 0 ? -6 : 1 - day;
    const monday = new Date(date.setDate(date.getDate() + diffToMonday));

    const weekDates = Array.from({ length: 5 }, (_, index) => {
      const weekDate = new Date(monday);
      weekDate.setDate(monday.getDate() + index);
      return {
        date: weekDate.toLocaleDateString('en-UK', { weekday: 'short', day: '2-digit' }),
        value: null
      };
    });

    return weekDates;
  };
  const dateLabels = () => {
    const dataLabels = getWeekDates(currentDate)

    data.forEach(item => {
      const date = new Date(Date.parse(item.date));
      const dateString = date.toLocaleDateString(["en-UK"], { weekday: "short", day: "2-digit" })
      dataLabels.map(dateItem => { if (dateItem["date"].includes(dateString.split(" ")[0])) { dateItem["value"] = item.value } })
    })
    return dataLabels
  };
  const getData = (data) => {
    const dataArray = [];
    data.forEach(item => dataArray.push(parseFloat(item.value)))
    return dataArray
  }
  const renderChart = () => {
    const labels = dataAreDates ? dateLabels() : data;

    const filteredLabels = labels.filter(item => {
      if (dataAreDates) {
        // if (!item["date"].includes("Sat") && !item["date"].includes("Sun")) {
        return item["date"]
        // }
      } else {
        return item["name"]
      }
    })
    console.log(filteredLabels)
    const dataSet = {
      labels: filteredLabels.map(item => dataAreDates ? item["date"] : item["name"].split(" ")[0]),
      datasets: [
        {
          data: getData(filteredLabels),
          borderColor: colors[colorVariant],
          backgroundColor: colors[colorVariant],
          fill: fill,
          pointStyle: false,
          tension: 0.35,
          barThickness: 20,
          borderRadius: 200
        }
      ]
    }
    switch (type) {
      case "line":
        return <Line id={chartId} ref={chartRef} className={styles.chartContainer} data={dataSet} options={lineOptions} />
      case "bar":
        return <Bar id={chartId} ref={chartRef} className={styles.chartContainer} data={dataSet} options={barOptions} />
      case "rainbowLine":
        return <Line id={chartId} ref={chartRef} className={styles.chartContainer} data={dataSet} options={lineOptions} />
      default:
        return <Line id={chartId} ref={chartRef} data={dataSet} options={options} />
    }
  }
  return renderChart()
}
export default DashboardChart