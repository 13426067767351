// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HelpContextMenu_helpMenu__95NVh {\n  list-style-type: none;\n  margin: 0;\n  padding: 8px 0;\n  background: #ffffff;\n  border: 1px solid #e6e6e6;\n  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);\n  border-radius: 5px;\n  box-sizing: border-box;\n  width: 169px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/ui/molecules/helpContextMenu/HelpContextMenu.module.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,SAAS;EACT,cAAc;EACd,mBAAmB;EACnB,yBAAyB;EACzB,4CAA4C;EAC5C,kBAAkB;EAClB,sBAAsB;EACtB,YAAY;AACd","sourcesContent":[".helpMenu {\n  list-style-type: none;\n  margin: 0;\n  padding: 8px 0;\n  background: #ffffff;\n  border: 1px solid #e6e6e6;\n  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);\n  border-radius: 5px;\n  box-sizing: border-box;\n  width: 169px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"helpMenu": "HelpContextMenu_helpMenu__95NVh"
};
export default ___CSS_LOADER_EXPORT___;
