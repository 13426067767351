import styles from "./SaveSetupForm.module.css"
import { PopUpHeader, PopUpButtons, Input, InputType, PopUpText } from '@ctouch-europe-b-v/react-component-library';
import { useState } from 'react';
import { BackupService } from "../../../../services/BackupService";

function SaveSetupForm({closeModal, display, companyId}) {
    const [setupName, setSetupName] = useState("");
    const [warningText, setWarningText] = useState("");
    let backup = {};

    const saveSetup = () => {
        backup.model_type = display.model.split(' ')[0];
        backup.company_id = companyId;
        backup.name = setupName;
        backup.display_id = display.id;
        backup.firmware = display.firmware;

        BackupService.create(backup).then((response) => {
            if (response.code === 200) {
                setWarningText("Backup saved succesfully")
            } else {
                setWarningText("Something went wrong, try again later")
            }
        })
    }

    return (
        <>
            <PopUpHeader headerText="Save setup" />
            <Input inputType={InputType.text} className={styles.input} onChange={(e) => setSetupName(e.target.value)} labelText={"Setup name"} isColumn />
            <PopUpText bodyText={warningText} />
            <PopUpButtons hasCancelBtn hasBtn1 textBtn1={"Save"} onClickBtn1={saveSetup} onClickCancel={closeModal} />
        </>
    )
}

export default SaveSetupForm;