import { useContext, useEffect, useState } from "react";
import BusinessName from "../../ui/molecules/businessName/BusinessName";
import Sidebar from "../../ui/molecules/sidebar/Sidebar";
import Content from "../../ui/organisms/content/Content";
import DisplayFooter from "../../ui/organisms/displayFooter/DisplayFooter";
import HeaderBar from "../../ui/organisms/headerBar/HeaderBar";
import LocationRow from "../../ui/organisms/locationRow/LocationRow";
import styles from "./DisplayOverview.module.css"
import useSearch from './../../../hooks/UseSearch';
import ConnectionStatus from './../../ui/atoms/connectionStatus/ConnectionStatus';
import useFilters from './../../../hooks/UseFilters';
import { useLocation, useNavigate } from "react-router-dom";
import Routes from "../../../enums/Routes";
import { CustomerService } from './../../../services/CustomerService';
import useSort from "../../../hooks/UseSort";
import { LocationContext, FilterContext } from "../../../contexts/LocationContext";

const initialFilters = [{ 'connectionStatus': [] }, { 'serialNumber': [] }, { 'firmware': [] }, { 'sphereApp': [] }, { 'subscription': [] }, { 'model': [] }]

function DisplayOverview({ role, currentCompany, companySub }) {
    const [company, setCompany] = useState({});
    const [sphereUser, isAdminValue] = useState(null);
    const [isMultiSelectActive, setIsMultiSelectActive] = useState(false)
    const [isFirmwareUpgradeActive, setIsFirmwareUpgradeActive] = useState(false);
    const [selected, setSelected] = useState([]);
    const [query, setQuery] = useState('')
    const [sort, setSort] = useState(null);
    const [filters, setFilters] = useState(initialFilters);
    const [filterList, setFilterList] = useState([])
    const [businessNameFilterList, setBusinessNameFilterList] = useState()
    const [locationFilters, setLocationFilters] = useState([{ 'location_name': [] }]);
    const { search } = useSearch()
    const { sortList } = useSort()
    const { filter } = useFilters()
    const navigate = useNavigate();
    const location = useLocation();
    const [checkAll, setCheckAll] = useState(false);
    const { locationList, setLocationList } = useContext(LocationContext);
    const { globalFilterList, setGlobalFilterList } = useContext(FilterContext)
    // const [locationList, setLocationList] = useState([])
    const [isMultiUpdateActive, setIsMultiUpdateActive] = useState(false)

    useEffect(() => {
        getCompany()
    }, [])

    useEffect(() => {
        globalFilterList.forEach(field => {
            Object.entries(field).forEach(([key, values]) => {
                key !== 'location_name' ? setFilters(prev => {
                    prev = prev.map(filter => { if (filter[key] !== undefined) { filter[key] = values }; return filter })
                    return prev;
                })
                    :
                    setLocationFilters((prev) => {
                        prev = prev.map(filter => { if (filter[key] !== undefined) { filter[key] = values }; return filter })
                        return prev
                    })
            })
        })
    }, [globalFilterList])

    useEffect(() => {
        if (company.locations !== undefined && company.locations !== null) {
            setLocationList(() => {

                let newList = JSON.parse(JSON.stringify(company.locations))
                newList = filter(newList, locationFilters);
                newList.forEach((location) => {
                    location.displays = search(filter(location.displays, filters), query, getFilterFields[location.displays[0]]);
                    location.displays = sort === null ? location.displays : sortList(location.displays, sort[1], (item) => item[sort[0]])
                    return location.displays
                });
                return newList
            })
        }
    }, [company, locationFilters, filters, query, sort])

    useEffect(() => {
        getCompany()
    }, [])

    useEffect(() => {
        if (selected.length === 0) {
            setIsMultiUpdateActive(false)
        }
    }, [selected])
    const getCompany = () => {
        const company_id = location.state === null ? currentCompany : location.state.company_id;
        CustomerService.getOne(company_id).then((response) => {
            setCompany(response.data)
            setCompany(response.data);
            if (locationList === null) {
                setLocationList(response.data.locations);
            }
            setFilterList(getFilters(response.data))
            getBusinessNameFilters(response.data)
        });
    }
    const checkIsUpdating = (display) => {
        return display.is_updating === 1 || (display.is_updating === 1 && isMultiUpdateActive) ? true : false
    }
    function handleMultiSelectActiveChange() {
        setIsMultiSelectActive(!isMultiSelectActive)
        setSelected([]);
    }

    const handleFirmwareUpgradeActive = () => {
        setIsFirmwareUpgradeActive(!isFirmwareUpgradeActive)
        setSelected([]);
    }

    const handleFilterChange = (newFilters) => {
        const nextFilters = filters.map((filter) => {
            if (Object.keys(newFilters).includes(Object.keys(filter)[0])) {
                filter[Object.keys(filter)[0]] = newFilters[Object.keys(filter)[0]];
            }
            return filter;
        });
        setFilters(nextFilters);
    }

    const getFilters = (data) => {
        const columns = ['connectionStatus', 'serialNumber', 'firmware', 'sphereApp', 'subscription'];
        let filters = [];
        if (data.locations[0] !== undefined) {
            data.locations.forEach((location, index) => {
                if (location.displays.length > 0) {
                    filters = Object.keys(data.locations[index]?.displays[0]).filter((key) => {
                        if (columns.includes(key)) {
                            return true;
                        }
                        return false;
                    }).map((key) => {
                        const fields = [];
                        data.locations.forEach((location) => {
                            let list = location.displays.map((display) => { return display[key] === null ? '-' : display[key] });
                            fields.push(...new Set(list));
                        });
                        let uniqueFields = [...new Set(fields)];
                        return { [key]: uniqueFields };
                    })
                }
            })
        }
        return filters;

    }
    const checkMultiSelectAvailability = (display) => {
        if (isFirmwareUpgradeActive) {
            if (display.connectionStatus && companySub?.toLowerCase() === "safe" && display.subscription?.toLowerCase() === "safe" && display.firmware < display.latest_version) {
                return true;
            }
            if (display.subscription?.toLowerCase() === "fit") {
                return "Fit"
            }
            if (display.firmware >= display.latest_version) {
                return "Firmware"
            }
            if (!display.connectionStatus) {
                return "Offline"
            }
            return "Offline";
        } else if (isMultiSelectActive) {
            if (display.connectionStatus && companySub?.toLowerCase() === "safe" && display.subscription?.toLowerCase() === "safe") {
                return true;
            }
            if (display.subscription?.toLowerCase() === "fit") {
                return "Fit"
            }
            if (!display.connectionStatus) {
                return "Offline"
            }
        }
        return null;
    };
    const onSelectedChange = (isSelected, display) => {
        isSelected ? selected.push(display) : selected.splice(selected.indexOf(display), 1);
        setSelected(selected);
    }

    const getFilterFields = (display) => {
        const toRemove = ['id', 'location_id', 'last_online', 'lan_ip_address', 'rs_232_version', 'firmware_id'];
        if (display !== undefined) {
            return Object.keys(display).filter((key) => {
                return toRemove.indexOf(key) < 0;
            })
        }

    }


    const getBusinessNameFilters = (data) => {
        setBusinessNameFilterList(() => {
            if (location.state?.filters !== undefined) return location.state.filters;
            else if (data.locations[0] !== undefined) {
                const uniqueModelValues = new Set();
                const locations = data.locations.map((l) => l.location_name);
                data.locations.flat().map((location) => location.displays).flat().forEach((display) => {
                    uniqueModelValues.add(display.model.split(" -")[0])
                })
                const models = [...uniqueModelValues];
                return [{ 'title': 'Models', 'values': models.sort(), 'fieldName': 'model' }, { 'title': 'Locations', 'values': locations, 'fieldName': 'location_name' }]
            }
        })
    }


    const loadContent = () => {
        let contentsList = locationList.map((location, index) => {
            return <LocationRow isMultiSelectActive={isMultiSelectActive} isFirmwareUpgradeActive={isFirmwareUpgradeActive} key={index} locationTitle={location.location_name} checkAll={checkAll}>
                {sort === null ? location.displays.sort((a, b) => {
                    const noSpaceA = a.name.split(" ").join("");
                    const noSpaceB = b.name.split(" ").join("");
                    if (noSpaceA > noSpaceB) { return 1 }
                    else if (noSpaceA < noSpaceB) { return -1 }
                    else { return 0 }
                }).map((displays, index) => {
                    return <Content role={role} getCompany={() => getCompany()} isContextMenuVisible={!isFirmwareUpgradeActive} isUpdatingNew={checkIsUpdating(displays)} firstRow={index === 0 ? true : undefined} className={styles.contentHover} companySub={companySub} company={company} companyId={company.id} locations={locationList} key={index} handleDisplayChanges={() => getCompany()} displayLocation={location} onClick={() => { navigate('/' + Routes.displaySettings, { state: { display: displays, company: company, filters: businessNameFilterList, activeFilters: { locationFilters: locationFilters, filters: filters } } }) }} onSelectedChange={onSelectedChange} isMultiSelectActive={checkMultiSelectAvailability(displays)} display={displays} content={{ serial: displays.serialNumber, status: <ConnectionStatus className={!!displays.connectionStatus === false ? styles.statusHover : ""} isOnline={!!displays.connectionStatus} />, firmware: displays.firmware, sphereApp: displays.sphereApp, subscriptionName: displays.subscription }} checkAll={checkAll} />
                })
                    :
                    location.displays.map((displays, index) => {
                        return <Content role={role} getCompany={() => getCompany()} isContextMenuVisible={!isFirmwareUpgradeActive} isUpdatingNew={checkIsUpdating(displays)} firstRow={index === 0 ? true : undefined} className={styles.contentHover} companySub={companySub} company={company} companyId={company.id} locations={locationList} key={index} handleDisplayChanges={() => getCompany()} displayLocation={location} onClick={() => { navigate('/' + Routes.displaySettings, { state: { display: displays, company: company, filters: businessNameFilterList, activeFilters: { locationFilters: locationFilters, filters: filters } } }) }} onSelectedChange={onSelectedChange} isMultiSelectActive={checkMultiSelectAvailability(displays)} display={displays} content={{ serial: displays.serialNumber, status: <ConnectionStatus className={!!displays.connectionStatus === false ? styles.statusHover : ""} isOnline={!!displays.connectionStatus} />, firmware: displays.firmware, sphereApp: displays.sphereApp, subscriptionName: displays.subscription }} checkAll={checkAll} />
                    })}
            </LocationRow>
        })
        return contentsList
    }

    const onAllSelect = () => {
        setCheckAll(!checkAll)
    }

    // console.log(filterList)

    return (
        company.locations &&
        <div className={styles.mainContainer}>
            <Sidebar companySub={companySub} role={role} sphereUser={role} className={styles.sidebar} />
            <div className={styles.content}>
                <div className={styles.header}>
                    <HeaderBar setSort={(state, title) => setSort([title, state])} hasFilters updateActiveFilters={handleFilterChange} onSearchChange={(e) => setQuery(e.target.value)} fields={filterList}>
                        <BusinessName hasFilters onSelectedChange={onAllSelect} filters={businessNameFilterList} setSort={(state) => setSort(["name", state])} multiChange={handleMultiSelectActiveChange} isMultiSelectActive={isMultiSelectActive} isFirmwareUpgradeActive={isFirmwareUpgradeActive} businessName={company.name} isMultiSelectVisible={false} />
                    </HeaderBar>
                </div>
                <div className={styles.rowContent}>
                    {loadContent()}
                </div>
                <div className={styles.sideBackground}></div>
            </div>
            <div className={styles.footer}>
                <DisplayFooter companySub={companySub} selectedDisplays={selected} setSelected={setSelected} handleFirmwareUpgradeActive={handleFirmwareUpgradeActive} setMultiUpdate={setIsMultiUpdateActive} multiUpdate={isMultiUpdateActive} isMultiSelectActive={isMultiSelectActive} isFirmwareUpgradeActive={isFirmwareUpgradeActive} locations={company.locations} company_id={company.id} handleCreated={() => getCompany()} />
            </div>
        </div>
    )
}

export default DisplayOverview;
