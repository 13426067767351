import styles from "./SettingsSidebarRow.module.css"
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fal } from "@fortawesome/pro-light-svg-icons";
library.add(fal);

const SettingsSidebarRow = ({ title, onClick, isDisabled, iconName, isActive }) => {
    return (
        <div className={[styles.container, isActive && styles.active].filter(e => !!e).join(' ')} onClick={!isDisabled ? onClick : null}>
            <FontAwesomeIcon icon={["fal", iconName]} className={[styles.prefix, isDisabled && styles.disabled].filter(e => !!e).join(' ')} />
            <span className={[styles.title, isDisabled && styles.disabled].filter(e => !!e).join(' ')}>{title}</span>
        </div>
    );
}

export default SettingsSidebarRow;