import SidebarButton from "../../atoms/sidebarButton/SidebarButton";
import styles from "./Sidebar.module.css"
import { SphereLogo, SphereLogoColor } from '@ctouch-europe-b-v/react-component-library';
import Roles from './../../../../enums/Roles';
import { Link, NavLink } from "react-router-dom";
import Routes from "../../../../enums/Routes";

function Sidebar({ className, role, companySub, sphereUser }) {
    return (
        <>
            <div className={[styles.sidebar, className].filter(e => !!e).join(' ')}>
                <ul className={styles.section}>
                    <NavLink className={({ isActive, isPending }) => isPending ? "pending" : isActive ? styles.active : styles.link} to={`/${Routes.displayOverview}`}><SphereLogo className={styles.sphereLogo} color={SphereLogoColor.blue} /></NavLink>
                    {(sphereUser.is_admin) &&
                        <NavLink target="_blank" className={({ isActive, isPending }) => isPending ? "pending" : isActive ? styles.active : styles.link} to={Routes.myctouchOverview}><SidebarButton buttonText={"MyCTOUCH"} iconName={"grid-2"} /></NavLink>
                    }
                </ul>
                <ul className={styles.section}>
                    {(role.roles.some(role => role.Name === Roles.ctouch || role.Name === Roles.reseller)) &&
                        <NavLink className={({ isActive, isPending }) => isPending ? "pending" : isActive ? styles.active : styles.link} to={'/' + Routes.customerOverview}><SidebarButton buttonText={"Select customer"} iconName={"user-group"} /></NavLink>
                    }
                    {(companySub === "SAFE") ?
                        <NavLink className={({ isActive, isPending }) => isPending ? "pending" : isActive ? styles.active : styles.link} to={'/' + Routes.dashboard}><SidebarButton companySub={companySub} buttonText={"Dashboard"} iconName={"chart-line-up"} /></NavLink>
                        : <SidebarButton companySub={companySub} buttonText={"Dashboard"} iconName={"chart-line-up"} />
                    }
                    <NavLink className={({ isActive, isPending }) => isPending ? "pending" : isActive ? styles.active : styles.link} to={'/' + Routes.displayOverview}><SidebarButton buttonText={"Displays"} iconName={"tv"} /></NavLink>
                </ul>
                <ul className={styles.section}>
                    <NavLink className={({ isActive, isPending }) => isPending ? "pending" : isActive ? styles.active : styles.link} to={'/' + Routes.accountSettings}><SidebarButton buttonText={"Settings"} iconName={"gear"} /></NavLink>
                </ul>
            </div>
        </>
    )
}

export default Sidebar;
