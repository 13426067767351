import styles from './ChartsSideBarFilters.module.css'
import ChartsSidebarHeader from '../../atoms/chartsSidebarHeader/ChartsSidebarHeader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react';

const ChartsSideBarFilters = ({ className, currentWeek, currentActiveWeek, setCurrentActiveWeek, currentYear, setCurrentYear, currentActiveYear, setCurrentActiveYear }) => {
  const weekSelection = () => {
    const clickHandler = (e, type) => {
      type === "left" ? setCurrentActiveWeek(prev => {
        if (prev === 0) {
          setCurrentActiveYear(prev => prev - 1);
          return 52
        } else { return prev - 1 }
      }) : setCurrentActiveWeek(prev => {
        if (prev === 52) {
          setCurrentActiveYear(prev => prev + 1);
          return 1
        } else {
          return (prev === currentWeek) ? prev : prev + 1
        }

      })
    }
    return (<span><FontAwesomeIcon className={styles.hoverIcon} onClick={(e) => clickHandler(e, "left")} icon={["fas", "chevron-left"]} />Week {currentActiveWeek}, {currentActiveYear}<FontAwesomeIcon className={[styles.hoverIcon, currentActiveWeek === currentWeek && styles.disabled].filter(e => !!e).join(" ")} onClick={(e) => { console.log(((currentActiveWeek < currentWeek) || (currentActiveYear < currentYear))); ((currentActiveWeek < currentWeek) || (currentActiveYear < currentYear)) && clickHandler(e, "right") }} icon={["fas", "chevron-right"]} /></span>)
  }
  return (
    <div className={[className].filter(e => !!e).join(" ")} >
      <span className={styles.sideBarHeader}>Filters</span>
      <ChartsSidebarHeader title={"period"} subTitle={weekSelection()} />
    </div>
  )
}
export default ChartsSideBarFilters