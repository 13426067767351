import styles from "./SettingsHeader.module.css"

const SettingsHeader = ({ title }) => {
    return (
        <div className={styles.row}>
            <span className={styles.prefix} />
            <span className={styles.label}>{title}</span>
        </div >
    );
}

export default SettingsHeader