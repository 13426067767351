import { AxiosService } from './AxiosService';

const baseUrl = "sphere/display/settings";

export const DisplaySettingsService = {
    async update(setting) {
        return await AxiosService.postRequest(`${baseUrl}/${setting.id}/update`, setting.value);
    },

    async getAllSettings(display) {
        return await AxiosService.getRequest(`${baseUrl}/${display.id}/all`);
    }
}