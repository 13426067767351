import styles from "./TableBodyRow.module.css"
import TableBodyCell from "../../molecules/tableBodyCell/TableBodyCell";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, ButtonColor } from '@ctouch-europe-b-v/react-component-library'
import { useRef, useState } from 'react';
import useOnClickOutside from "../../../../hooks/UseOnClickOutside";
import RemoveContextMenu from "../../molecules/removeContextMenu/RemoveContextMenu";

function TableBodyRow({content, cellClass, onClick, onRemove}){
    const [isContextClosed, setIsContextClosed] = useState(true)
    const [isBeingRemoved, setIsBeingRemoved] = useState(false)
    const ref = useRef();
    const containerRef = useRef();
    useOnClickOutside(ref, () => setIsContextClosed(true));
    useOnClickOutside(containerRef, () => { setIsBeingRemoved(false) })
    return (
        <div>
            {
                !isBeingRemoved ? <div innerRef={containerRef} className={styles.tableRow}>
                    {
                        content && content.map((field, index) => {
                            return index == 0 ? <TableBodyCell isConnectCode className={cellClass} setIsContextClosed={(val) => setIsContextClosed(val)}> {field}
                                {!isContextClosed && <RemoveContextMenu innerRef={ref} onClick={() => { setIsContextClosed(true); setIsBeingRemoved(true); onClick && onClick() }} />}</TableBodyCell> : <TableBodyCell className={cellClass}> {field} </TableBodyCell>;

                        })
                    }
                </div>
                    : <div ref={containerRef} className={styles.removedRow}>
                        <p>Are you sure you want to remove this code?</p>
                        <Button className={styles.button} title={"CANCEL"} variant={ButtonColor.white} onClick={() => { setIsBeingRemoved(false) }} />
                        <Button className={styles.button} title={"REMOVE"} iconName={"trash"} variant={ButtonColor.red} onClick={() => { setIsBeingRemoved(false); onRemove() }} />
                    </div>
            }

        </div>
    )
    // return(
    //     <>
    //     <div className={styles.tableRow}>
    //         {
    //             content && content.map((field, index) => {
    //                 return index == 0 ? <TableBodyCell className={cellClass}> {field} <FontAwesomeIcon icon={["fal", "ellipsis-vertical"]} className={styles.menuIcon} onClick={() => setIsContextClosed(false)} />
    //                             {!isContextClosed && <RemoveContextMenu innerRef={ref} onClick={onClick} />}</TableBodyCell> : <TableBodyCell className={cellClass}> {field} </TableBodyCell>;
                    
    //             })
    //         }
    //         </div>
    //     </>
    // )
}

export default TableBodyRow;