import ChartWidgetFooter from '../../atoms/chartWidgetFooter/ChartWidgetFooter';
import ChartWidgetTitle from '../../atoms/chartWidgetTitle/ChartWidgetTitle';
import styles from './ChartWidgetDataSmall.module.css'
import { WidgetContainer } from '@ctouch-europe-b-v/react-component-library';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const ChartWidgetDataSmall = ({ unit, iconLeft, colorVariant, iconRight, unitType, difference, title, children, hasWarning, hasError, className, pageLinkRef, iconRightClassName }) => {

  const getUnitTypes = () => {
    switch (unitType) {
      case "displays":
      case "launches":
        return "x"
      case "percent":
        return "%"
      case "hours":
        return "h"
      default:
        return "x"
    }
  }

  const getUnitTypesClass = () => {
    switch (unitType) {
      case "displays":
      case "launches":
        return "amount"
      case "percent":
        return "percent"
      case "hours":
        return "hour"
      default:
        return "amount"
    }
  }
  console.log(pageLinkRef)
  return (
    <WidgetContainer className={[className, (hasError || hasWarning) && styles.errorContainer, styles[colorVariant]].filter(e => !!e).join(" ")} size="small">
      <ChartWidgetTitle title={title} iconLeft={iconLeft} iconRight={iconRight} iconRightClassName={iconRightClassName} iconRightOnClick={() => pageLinkRef.current.scrollIntoView()} />
      {children}
      <ChartWidgetFooter>
        <span className={styles.unitContainer}>
          {(unit && unit !== "Not Found" && (!hasWarning && !hasError)) && <><span className={styles.unitNumber}>{unit}</span><span className={[styles.unitType, styles[getUnitTypesClass()]].filter(e => !!e).join(" ")}>{getUnitTypes()}</span> </>}
        </span>
        {(difference !== undefined && difference !== "Not Found" && (!hasWarning && !hasError))
          ? <span className={styles.differenceContainer}>
            <FontAwesomeIcon icon={["fas", difference !== undefined && difference.split("")[0] === "-" ? "caret-down" : "caret-up"]} />
            {difference !== null && difference !== "Infinity%" ? difference.substring(difference.search(/[-+]/) + 1) : "0%"}
          </span>
          : ""
        }
      </ChartWidgetFooter>
    </WidgetContainer>
  )
}

export default ChartWidgetDataSmall
