import styles from './FilterMenuButton.module.css';
import { Input } from '@ctouch-europe-b-v/react-component-library';
import SortIcon from './../../atoms/sortIcon/SortIcon';
import { useEffect, useState } from 'react';

const FilterMenuButton = ({ inputType, label, isSortable, onChange, onSortClick, checked, value }) => {
   
    const [isChecked, setIsChecked] = useState(false);

    useEffect(() => {
        setIsChecked(checked)
    }, [checked])

    return (
        <li className={styles.button}>
            {inputType && <Input inputLeft style={{ "gap": "10px" }} onChange={(e) => { setIsChecked(e.target.checked); onChange(e) }} checked={isChecked} inputType={inputType} value={value === undefined ? label : value} labelText={label} />}
            {isSortable &&
                <SortIcon onClick={onSortClick} />
            }
        </li>
    );
}

export default FilterMenuButton;