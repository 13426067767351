import { useState, useEffect } from "react";
import styles from './AppList.module.css';
import { useWebSocket } from "../../../../hooks/UseWebSocket";
import AppListRow from "../../atoms/appListRow/AppListRow";
import AppIconContainer from "../../atoms/appIconContainer/AppIconContainer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AppListText from "../../atoms/appListText/AppListText";
import { Button, ButtonColor } from '@ctouch-europe-b-v/react-component-library';
import { faRocket } from "@fortawesome/pro-regular-svg-icons";

const AppList = ({ appList, serial, displayId }) => {
  const websocket = useWebSocket('sphere');
  const [installedApps, setInstalledApps] = useState([]);

  useEffect(() => {
    retrieveAppListInfo()
  }, [])

  const retrieveAppListInfo = async () => {
    websocket.joinRoom(serial)
    await websocket.getAllApps(serial)
      .then(response => {
        setInstalledApps(response);
      })
  }
  const unInstallAppHandler = async (packageName) => {
    websocket.uninstallApp(packageName, serial)
    setTimeout(() => {
      retrieveAppListInfo();
    }, 10000);
  }

  const installAppHandler = async (url) => {
    websocket.installApp(url, serial)
    setTimeout(() => {
      retrieveAppListInfo();
    }, 10000);
  }

  return (
    appList.map((item) => {
      console.log(installedApps.includes(item.package_name), item.package_name)
      return (
        <AppListRow key={item.package_name} >
          <AppIconContainer hasIcon={item.icon_link !== undefined}>
            {item.icon_link !== undefined ? <img src={item.icon_link} height="30" width="30" alt={"app_icon"} className={styles.imgIcon} /> : <FontAwesomeIcon icon={faRocket} className={styles.icon} />}
          </AppIconContainer>
          <AppListText titleText={item.name} descriptionText={"Short App Description"} />
          {installedApps.includes(item.package_name) ? <Button title={"REMOVE"} className={[styles.removeButton, styles.appButton].filter(e => !!e).join(" ")} onClick={() => unInstallAppHandler(item.package_name)} /> : <Button title={"INSTALL"} variant={ButtonColor.blue} className={styles.appButton} onClick={() => installAppHandler(item.download_link)} />}
        </AppListRow>
      )
    })
  )
}

export default AppList;