// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RemoveContextMenu_contextMenu__kJzgv {\n    position: absolute;\n    top: 15px;\n    left: 200px;\n    list-style-type: none;\n    margin: 0;\n    padding: 1px;\n    background: #ffffff;\n    border: 1px solid #e6e6e6;\n    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);\n    border-radius: 5px;\n    box-sizing: border-box;\n    width: 130px;\n    z-index: 2;\n}", "",{"version":3,"sources":["webpack://./src/components/ui/molecules/removeContextMenu/RemoveContextMenu.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,qBAAqB;IACrB,SAAS;IACT,YAAY;IACZ,mBAAmB;IACnB,yBAAyB;IACzB,4CAA4C;IAC5C,kBAAkB;IAClB,sBAAsB;IACtB,YAAY;IACZ,UAAU;AACd","sourcesContent":[".contextMenu {\n    position: absolute;\n    top: 15px;\n    left: 200px;\n    list-style-type: none;\n    margin: 0;\n    padding: 1px;\n    background: #ffffff;\n    border: 1px solid #e6e6e6;\n    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);\n    border-radius: 5px;\n    box-sizing: border-box;\n    width: 130px;\n    z-index: 2;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contextMenu": "RemoveContextMenu_contextMenu__kJzgv"
};
export default ___CSS_LOADER_EXPORT___;
