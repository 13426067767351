import styles from './AppListRow.module.css';

const AppListRow = ({children, className}) => {
  return (
    <div className={[styles.container, className].filter(e=>!!e).join(" ")}>
      {children}
    </div>
  )
}

export default AppListRow;