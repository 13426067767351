import styles from "./DisplayContextButton.module.css"
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fal } from "@fortawesome/pro-light-svg-icons";
library.add(fal);

function DisplayContextButton({ handleContextClosedChange, isSelected }) {
    return (
        <span className={styles.contextMenuIcon} onClick={handleContextClosedChange}>
            <FontAwesomeIcon icon={["fal", "ellipsis-vertical"]} className={[styles.icon, isSelected && styles.selected].filter(e => !!e).join(' ')} />
        </span>
    )
}

export default DisplayContextButton;