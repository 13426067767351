import styles from './FilterIcon.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fal } from "@fortawesome/pro-light-svg-icons";
import { library } from '@fortawesome/fontawesome-svg-core'
library.add(fal);

const FilterIcon = ({ onClick, active, iconIsBlack }) => {
    return (
        <div className={styles.background} onClick={onClick}>
            {active ?
                <span className={[styles.container, styles.activeFilter].filter((e) => !!e).join(" ")}>
                    <FontAwesomeIcon icon={["fal", "filter"]} className={[styles.icon, styles.whiteIcon].filter((e) => !!e).join(" ")} />
                </span> :
                <span className={styles.container}>
                    <FontAwesomeIcon icon={["fal", "filter"]} className={[styles.icon, styles.greyIcon, iconIsBlack && styles.blackIcon].filter((e) => !!e).join(" ")} />
                </span>
            }
        </div>
    );
}

export default FilterIcon;