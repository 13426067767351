import styles from "./ChartWidgetGraphLarge.module.css"
import ChartWidgetTitle from "../../atoms/chartWidgetTitle/ChartWidgetTitle"
import { WidgetContainer } from '@ctouch-europe-b-v/react-component-library';

const ChartWidgetGraphLarge = ({ title, children, colorVariant, iconLeft, iconRight, hasError, hasWarning, className, chartRef }) => {
  return (
    <div ref={chartRef}>
      <WidgetContainer size="large" className={[className, (hasError || hasWarning) && styles.errorContainer, styles.container, styles[colorVariant]].filter(e => !!e).join(" ")}>
        <ChartWidgetTitle title={title} iconLeft={iconLeft} iconRight={iconRight} />
        {children}
      </WidgetContainer>
    </div>

  )
}

export default ChartWidgetGraphLarge