import { useState } from "react";
import { useWebSocket } from "../../../../hooks/UseWebSocket";
import { DisplayService } from "../../../../services/DisplayService";
import ContextMenuButton from "../../atoms/contextMenuButton/ContextMenuButton";
import ContextSpacer from "../../atoms/contextSpacer/ContextSpacer";
import styles from "./ContextMenu.module.css"
import { useNavigate } from "react-router-dom";
import Routes from "../../../../enums/Routes";


function ContextMenu({ innerRef, className, onRemoveClick, onEditClick, onSaveSetupClick, onRestoreSetupClick, display, onClick, displays, companySub, role, company, setUpdateState }) {
    const navigate = useNavigate();
    const { joinRoom, wakeOnLan, update, getLogs } = useWebSocket(display.serialNumber);
    const turnOn = () => {
        displays.filter((filteredDisplay) => filteredDisplay.connectionStatus === true && filteredDisplay.serialNumber !== display.serialNumber).forEach(peerDisplay => {
            joinRoom(peerDisplay.serialNumber)
            wakeOnLan(peerDisplay.serialNumber, display.mac_address)
        });
    }
    const getScreenLogs = async (display) => {
        joinRoom(display.serialNumber);
        await getLogs(display.serialNumber)
            .then(response => {
                navigate('/' + Routes.screenLogs, { state: { log: response, display: display, company: company } })
            });
    }
    const updateFirmware = (display) => {
        if (display.is_updating !== 1) {
            joinRoom(display.serialNumber)
            update(display.serialNumber)
            display.is_updating = 1;
            const currentTime = new Date();
            const endTime = new Date();
            endTime.setTime(currentTime.getTime() + (25 * 60 * 1000))
            let screenInfo = {
                serial: display.serialNumber,
                currentFirmware: display.firmware,
                newFirmware: display.latest_version,
                startTime: currentTime,
                endTime: endTime
            }
            // DisplayService.saveUpdateFirmwareData(screenInfo).then(response=> console.log(response))
        }
        setUpdateState(true)
        joinRoom(display.serialNumber);
        update(display.serialNumber)
    }
    return (
        <ul className={[styles.contextMenu, className].filter(e => !!e).join(' ')} id={"testID"} ref={innerRef} onClick={onClick}>
            <ContextMenuButton buttonText={"Update"} iconName={"arrow-down-to-bracket"} onClick={() => updateFirmware(display)} isDisabled={!display.connectionStatus} />
            <ContextMenuButton buttonText={"Turn on"} iconName={"power-off"} onClick={() => turnOn(display)} />
            <ContextSpacer />
            <ContextMenuButton buttonText={"Edit display"} iconName={"pen"} onClick={onEditClick} />
            {(role.is_admin === 1) ? <ContextMenuButton isDisabled={!display.connectionStatus || isNaN(parseInt(display.sphereApp)) || display.sphereApp < 2.5} buttonText={"Get Screen Logs"} iconName={"arrow-down-to-bracket"} onClick={() => getScreenLogs(display)} /> : ""}
            <ContextSpacer />
            <ContextMenuButton isDisabled={companySub === "FIT"} buttonText={"Save setup"} iconName={"floppy-disk"} onClick={onSaveSetupClick} />
            <ContextMenuButton isDisabled={!display.connectionStatus || companySub === "FIT"} buttonText={"Restore setup"} iconName={"folder"} onClick={onRestoreSetupClick} />
            <ContextSpacer />
            <ContextMenuButton buttonText={"Remove"} iconName={"trash"} hasRedText onClick={onRemoveClick} />
        </ul>
    )
}

export default ContextMenu;
