// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ChevronButton_icon__kuUP7{\n    cursor: pointer;\n    \n}", "",{"version":3,"sources":["webpack://./src/components/ui/atoms/chevronButton/ChevronButton.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;;AAEnB","sourcesContent":[".icon{\n    cursor: pointer;\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": "ChevronButton_icon__kuUP7"
};
export default ___CSS_LOADER_EXPORT___;
