import styles from "./ConnectionStatus.module.css";
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fal } from "@fortawesome/pro-light-svg-icons";

const ConnectionStatus = ({ isOnline, className }) => {
    return (
        <div className={styles.container}>
            <span className={[isOnline ? styles.onlineIndicator : styles.offlineIndicator, className].filter(e => !!e).join(' ')}> {isOnline ? <FontAwesomeIcon icon={["fas", "link-simple"]} /> : <FontAwesomeIcon icon={["fas", "link-simple-slash"]} />} </span>
            {isOnline === true ? <span className={styles.label}>Online</span> : <span className={styles.label}>Offline</span>}
        </div>);
}

export default ConnectionStatus;