import styles from "./WhoIsAdminPopUp.module.css"
import { useState, useEffect } from "react";

import { PopUpHeader, PopUpText, PopUpButtons, ButtonColor, PopUp, PopUpSpacer, Input, InputType } from '@ctouch-europe-b-v/react-component-library';
import { MailService } from "../../../../services/MailService";

function WhoIsAdminPopUp({setPage, user, setAdminMail, adminMail}){
    const [submitDisabled, setSubmitDisabled] = useState(true);
    const mailRegex  =  /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;

    useEffect(() => {
        if(adminMail.length > 0 && adminMail.match(mailRegex)) setSubmitDisabled(false)
        else setSubmitDisabled(true)
    }, [adminMail])

    const handleSentAdminMail = () => {
        let mail = {"mail": adminMail}
        let userMail = {"mail": user.email}

        MailService.sentRequestAdmin(mail).then(() => {
            MailService.sentSentToAdminConfirmation(userMail).then(() => {
                setPage(3)
            })
        })
    }

    return(
        <PopUp>
            <PopUpHeader headerText={"Who is your admin?"} />
            <PopUpText bodyText={"Please send us the email address of your admin so that they can setup an account."} />
            <PopUpSpacer />
            <Input labelText={"Admin's email adress"} inputType={InputType.text} isColumn className={styles.adminMail} onChange={(e) => setAdminMail(e.target.value)} />
            <PopUpText hasIcon bodyText={"An organisation can only be setup once a system admin has been assigned."} />
            <PopUpButtons hasBtn1 onClickBtn1={() => {setPage(0)}} textBtn1={"BACK"} hasBtn2 btn2Disabled={submitDisabled} onClickBtn2={() => {handleSentAdminMail()}} textBtn2={"SEND"} variantBtn2={ButtonColor.lifeAquatic} />
        </PopUp>
    )
}

export default WhoIsAdminPopUp;