import ChartWidgetFooter from '../../atoms/chartWidgetFooter/ChartWidgetFooter';
import ChartWidgetTitle from '../../atoms/chartWidgetTitle/ChartWidgetTitle';
import styles from './ChartWidgetTextSmall.module.css'
import { WidgetContainer } from '@ctouch-europe-b-v/react-component-library';
import { useEffect, useState } from 'react';
const ChartWidgetTextSmall = ({ className, colorVariant, texts, interval = 5000, title, iconLeft, iconRight, children, rightIconOnClick, rightIconClassName }) => {
  const [currentIndex, setCurrentIndex] = useState(0)
  useEffect(() => {
    if (children === undefined && texts !== undefined) {
      const intervalId = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex === texts.length - 1 ? 0 : prevIndex + 1))
      }, interval)
      return () => clearInterval(intervalId)
    }
  }, [texts, interval])

  return (
    <WidgetContainer size="small" className={[className, styles[colorVariant]].filter(e => !!e).join(" ")}>
      <ChartWidgetTitle title={title} iconLeft={iconLeft} iconRight={iconRight} iconRightOnClick={rightIconOnClick} iconRightClassName={rightIconClassName} />
      {(children === undefined && texts !== undefined)
        ? <div className={[styles.content, styles.carousel].filter(e => !!e).join(" ")}>
          {texts.map((text, index) => {
            return <span key={index} className={styles.carouselItem} style={{ transform: `translateX(-${currentIndex * 100}%)` }}>{text}</span>
          })}

        </div>
        : <div className={[styles.content].filter(e => !!e).join(" ")}>{children}</div>
      }
      <ChartWidgetFooter className={styles.carouselControl}>
        {(children === undefined && texts !== undefined) && texts.map((text, circleIndex) => {
          return (<span className={styles.circles} onClick={() => setCurrentIndex(prev => prev = circleIndex)}>
            <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
              <circle opacity={(circleIndex !== currentIndex) ? "0.5" : "1"} cx="3" cy="3" r="3" fill="white" />
            </svg>
          </span>
          )
        })}
      </ChartWidgetFooter>
    </WidgetContainer>
  )
}
export default ChartWidgetTextSmall