import styles from "./ScreenLogs.module.css";
import Sidebar from './../../ui/molecules/sidebar/Sidebar';
import BusinessName from "../../ui/molecules/businessName/BusinessName";
import { PopUpHeader, Button, ButtonColor } from '@ctouch-europe-b-v/react-component-library';
import usePDFExport from "../../../hooks/UsePDFExport";
import { useRef } from "react";
import { useLocation } from "react-router-dom";
import HelpMenu from "../../ui/organisms/helpMenu/HelpMenu";


const ScreenLogs = ({ companySub, role }) => {
  const { exportToPDF } = usePDFExport();
  const ref = useRef();
  const logs = useLocation();

  const seperateLogLine = (log) => {
    const splitLog = log.split(/[: ]+/);
    const messageType = splitLog[0] + ' ' + splitLog[1];
    const timestamp = splitLog.slice(2, 6).join(' ');
    const message = splitLog.slice(6).join(' ');
    return { messageType, timestamp, message }
  };

  const seperateLogs = (logs) => {
    return logs.split(/(?=Sphere \[)/);
  };

  const formatDate = (inputDate) => {
    const dateObject = new Date(inputDate);

    const day = dateObject.getDate().toString().padStart(2, '0');
    const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
    const year = dateObject.getFullYear();

    const hours = dateObject.getHours().toString().padStart(2, '0');
    const minutes = dateObject.getMinutes().toString().padStart(2, '0');

    return `${day}-${month}-${year} | ${hours}:${minutes}`;
  };

  const originalDateString = logs.state.display.last_online;
  const formattedDate = formatDate(originalDateString);

  const formatMessageDate = (inputDate) => {
    const index = inputDate.indexOf(" ");
    const datePart = inputDate.slice(0, index);
    const timePart = inputDate.slice(index + 1);

    const [year, month, day] = datePart.split('-');
    const formattedDate = `${day}-${month}-${year}`;

    const formattedTime = timePart.replace(/ /g, ':');

    return `${formattedDate} ${formattedTime}`;
  };

  return (
    <>
      <div className={styles.mainContainer}>
        <Sidebar className={styles.sidebar} companySub={companySub} role={role} sphereUser={role} />
        <div className={[styles.pageTitle].filter(e => !!e).join(" ")}>
          <BusinessName businessName={logs.state.company.name} hasFilters={false} />
        </div>
        <div className={styles.contentHeader}>
          <Button variant={ButtonColor.blue} iconName={"file-export"} title="EXPORT TO PDF" onClick={() => { const date = new Date(); exportToPDF(ref.current, `${logs.state.display.name}-logs-${date.toISOString().split('T')[0]}`); }} />
        </div>
        <div className={styles.infoBar}>
          <PopUpHeader className={styles.header} headerText={"Screen logs"} />
          <table className={styles.infoTable}>
            <tr>
              <td className={styles.tdTitle}>Name</td>
              <td className={styles.rightData}>{logs.state.display.name}</td>
            </tr>
            <tr>
              <td className={styles.tdTitle}>Status</td>
              <td className={styles.rightData}>{logs.state.display.connectionStatus ? <span className={styles.statusOnline}>Online</span> : <span className={styles.statusOffline}>Offline</span>}</td>
            </tr>
            <tr>
              <td className={styles.tdTitle}>Since</td>
              <td className={styles.rightData}>{formattedDate}</td>
            </tr>
          </table>

        </div>
        <div className={styles.content}>
          <table className={styles.messageTable} ref={ref}>{seperateLogs(logs.state.log).map((log) => {
            const seperatedLog = seperateLogLine(log);
            let error = false;
            if (seperatedLog.messageType.toLocaleLowerCase().includes("error")) {
              error = true;
            }
            const originalMessageDateString = seperatedLog.timestamp;
            const formatedMessageDate = formatMessageDate(originalMessageDateString);
            const logRow = <tr className={error && styles.error}><td>{seperatedLog.messageType}</td><td>{formatedMessageDate}</td><td>{seperatedLog.message}</td></tr>

            return logRow;
          })}</table>
        </div>
        <div className={styles.infoBarFooter}></div>
        <div className={styles.contentFooter}>
          <HelpMenu />
        </div>
      </div>
    </>
  );
}
export default ScreenLogs;