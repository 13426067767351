import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./SettingsLinkHeader.module.css"

const SettingsLinkHeader = ({ title, onLeave }) => {
    return (
        <div className={styles.row}>
            <FontAwesomeIcon icon={['fal', 'arrow-left']} className={styles.prefix} onClick={onLeave} />
            <span className={styles.label}>{title}</span>
        </div >
    );
}

export default SettingsLinkHeader