import styles from "./RestoreSetupForm.module.css"
import { PopUpHeader, PopUpButtons, Input, InputType, PopUpText } from '@ctouch-europe-b-v/react-component-library';
import { useEffect, useState } from 'react';
import { BackupService } from "../../../../services/BackupService";
import { useWebSocket } from "../../../../hooks/UseWebSocket";

function RestoreSetupForm({ closeModal, display, companyId }) {
    const [warningText, setWarningText] = useState("");
    const [setups, setSetups] = useState();
    const [setupsName, setSetupsName] = useState();
    const [selectedSetupName, setSelectedSetupName] = useState();
    const { send } = useWebSocket();
    let backup = {};


    useEffect(() => {
        BackupService.getAll(companyId, display.model.split(' ')[0]).then((response) => {
            if (response.code !== 200) {
                setWarningText("Failed to get all setups, please try again later")
            } else {
                setWarningText("")
                if (response.data.length === 0) {
                    setWarningText("No setups found for this model")
                } else {
                    setSetups(response.data);
                    console.log(response.data);
                    setSetupsName(response.data.map((setup) => setup.backup_name))
                }
            }
        })
    }, [])

    const restoreSetup = () => {
        const selectedId = setups.find((setup) => setup.backup_name === selectedSetupName)
        backup.display_id = display.id;
        backup.backup_id = selectedId.id;

        BackupService.restoreBackup(backup).then((response) => {
            if (response.code === 200) {
                if (response.results.length > 0) {
                    for (const setting of response.results) {
                        send(setting.name, setting.setting_value, display.serialNumber, setting.id, true)
                    }
                }
                setWarningText("Backup restored successfully")
            } else {
                setWarningText("Resoring backup failed, try again later")
            }
        })

    }

    return (
        <>
            <PopUpHeader headerText="Restore setup" />
            {setupsName && <Input inputType={InputType.dropDown} className={styles.input} labelText={"Choose setup to restore"} isColumn options={setupsName} onChange={(e) => setSelectedSetupName(e.target.value)} />}
            <PopUpText bodyText={warningText} />
            <PopUpButtons hasCancelBtn hasBtn1 textBtn1={"Restore"} onClickBtn1={restoreSetup} onClickCancel={closeModal} />
        </>
    )
}

export default RestoreSetupForm;