import { faCircleExclamation } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./SettingsRow.module.css"

const SettingsRow = (props) => {
    return (
        <div className={[styles.row, props.isClickable === true && styles.clickable, props.className].filter(e => !!e).join(' ')} onClick={props.isClickable ? props.onClick : null}>
            <div className={styles.wrapper}>
                <div className={styles.iconTitleContainer}>
                    {props.hasPrefix && <span><FontAwesomeIcon icon={faCircleExclamation} /></span>}
                    <span className={styles.textContainer}>
                        <span className={styles.warningContainer}>
                            {props.hasWarning && <FontAwesomeIcon className={styles.warningIcon} icon={['fal', "hexagon-exclamation"]} />}
                            <span className={[styles.title, props.hasWarning === true && styles.warning].filter(e => !!e).join(' ')}> {props.title}</span>
                            
                        </span>
                        {props.subTitle && <span className={styles.subTitle}> {props.subTitle}</span>}
                    </span>
                </div>
                {
                    props.hasUpdate ? <FontAwesomeIcon className={styles.updateIcon} icon={['fal', "arrow-down-to-bracket"]} /> :
                    props.children
                }
                {}
            </div>
        </div>
    );
}

export default SettingsRow