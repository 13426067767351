import { useRef } from 'react';
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";

const usePDFExport = () => {
    const addDiv = async (divItem, index, pdf, width, height) => {
        await html2canvas(divItem, { scale: 2 }).then((canvas) => {
            console.log(divItem)
            console.log(index)
            if (index > 0) { pdf.addPage(); console.log("test") }
            const height = divItem.clientHeight;
            console.log(`item height ${height}`);
            console.log(`item width ${divItem.width}`);
            const imgData = canvas.toDataURL(`image/png`);
            const imgWidth = width;
            const imgHeight = (canvas.height * width) / canvas.width;
            console.log("Test")
            pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
        })
    }
    const exportToPDF = async (div, pdfName, withScroll) => {
        if (withScroll) {
            if (!div) return
            const pdf = new jsPDF('p', 'mm', 'a4');
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = pdf.internal.pageSize.getHeight();
            if (Array.isArray(div)) {
                let index = 0;

                for (const divItem of div) {

                    await addDiv(divItem, index, pdf, pdfWidth, pdfHeight)
                    index += 1
                }
                pdf.save(`${pdfName}.pdf`)
                return false;
            } else {
                console.log(div)


                html2canvas(div, { height: div.scrollHeight, logging: false }).then((canvas) => {
                    const imgData = canvas.toDataURL(`image/png`);
                    const imgWidth = pdfWidth;
                    const imgHeight = (canvas.height * pdfWidth) / canvas.width;
                    console.log(`canvas ${canvas.height}`);
                    console.log(`Img Height ${imgHeight}`)
                    let heightLeft = imgHeight;
                    let position = 0;

                    const originalHeight = div.style.height;
                    div.style.height = 'auto';

                    pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                    heightLeft -= pdfHeight;
                    while (heightLeft >= 0) {
                        console.log(`Remaining Height ${heightLeft}`);
                        position = heightLeft - imgHeight;
                        pdf.addPage();
                        pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                        heightLeft -= pdfHeight;
                    }

                    pdf.save(`${pdfName}.pdf`)
                    div.style.height = originalHeight;
                })
            }

        } else {

            let width = div.clientWidth;
            let height = withScroll ? div.scrollHeight : div.clientHeight;
            console.log(width, height)
            console.log(div)

            html2canvas(div, { scale: 2 }).then((canvas) => {
                let doc = new jsPDF({
                    unit: "px",
                    format: [width, height]
                });
                doc.addImage(canvas.toDataURL("image/png"), "PNG", 0, 0, width, height);
                doc.save(`${pdfName}.pdf`);
            });
        }
    }
    return { exportToPDF }
}

export default usePDFExport