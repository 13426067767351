const useFilters = () => {
    const filter = (data, filters) => {
        let list = data;
        const filterList = [];
        filters.forEach((filter) => {
            filter = Object.entries(filter);
            filterList[filter[0][0]] = filter[0][1];
        })
        data.forEach((object) => {
            Object.keys(object).forEach((key) => {
                if (filterList[key] === undefined || filterList[key]?.length === 0) {
                    return;
                }
                else if (key === "model") {
                    list = list.filter(item => { return filterList[key].map((filter) => filter.value).includes(item[key]?.toString().split(" -")[0]) });
                }
                else if (filterList[key][0]?.value  === ('-')) {
                    list = list.filter(item => { return item[key] === null });
                }
                else if (filterList[key][0]?.inputType === 'text') {
                    list = list.filter(item => { return item[key].toLowerCase().includes(filterList[key][0].value.toLowerCase()) })
                }
                else {
                    list = list.filter(item => { return filterList[key].map((filter) => filter.value).includes(item[key]?.toString()) });
                }
            })
        })
        return list;

    }
    return { filter };
}

export default useFilters
