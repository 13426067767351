import styles from "./DisplayInformation.module.css"

import DisplaySubTitle from "../../atoms/displaySubtitle/DisplaySubtitle";
import DisplayTitle from "../../atoms/displayTitle/DisplayTitle";
import DisplayInfoPopUp from "../../atoms/displayInfoPopUp/DisplayInfoPopUp";

function DisplayInformation({ displayName, displayModel, isSelected, showPopup, popupText, onPopupClick }) {
    return (
        <span className={styles.displayInformation}>
            <DisplayTitle displayName={displayName} isSelected={isSelected} />
            <DisplaySubTitle displayModel={displayModel} isSelected={isSelected} />
            {showPopup && <DisplayInfoPopUp onClick={onPopupClick} className={styles.popup}>{popupText}</DisplayInfoPopUp>}

        </span>
    )
}

export default DisplayInformation;