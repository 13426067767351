const useSearch = () => {
    const search = (data, query, fields) => {
        const list = [];
        const exceptedFields = ["connectCode", "latest_version"]
        if (query === '') return data;
        data.forEach((item) => {
            if (typeof item === 'object') {
                if (fields !== undefined) {
                    fields.some((field) => {
                        if (!exceptedFields.includes(field) && item[field] !== null && item[field].toString().toLowerCase().includes(query.toLowerCase()) !== false) {
                            list.push(item);
                            return true
                        }
                        return false;
                    })

                }
                else if (Object.values(item).find((value) => value !== null && value.toString().toLowerCase().includes(query.toLowerCase())) !== undefined) {
                    list.push(item);
                }
            } else if (item.toString().toLowerCase().includes(query.toLowerCase())) {
                list.push(item);
            }
        })
        return list;
    }
    return { search };
}

export default useSearch