import styles from "./ChevronButton.module.css"
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fal } from "@fortawesome/pro-light-svg-icons";
library.add(fal);

function ChevronButton({ className, isClosed, onClick }){
    return (
        <span onClick={ onClick}>
            {isClosed ? <FontAwesomeIcon icon={["fal", "chevron-right"]} className={[className, styles.icon].filter((e) => !!e).join(" ")}  /> : <FontAwesomeIcon icon={["fal", "chevron-down"]} className={[className, styles.icon].filter((e) => !!e).join(" ")} />  }
        </span>
    )
}

export default ChevronButton;