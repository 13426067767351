import styles from './SortIcon.module.css';
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fad } from "@fortawesome/pro-duotone-svg-icons";
import { useState } from 'react';
library.add(fad);

const SortIcon = ({ onClick }) => {
    const [state, setState] = useState(0)
    return (
        <div className={styles.background} onClick={() => { setState(getNextState(state)); onClick && onClick(state) }}>
            {
                state === 0 ?
                    <span className={styles.container}>
                        <FontAwesomeIcon style={{ "--fa-primary-opacity": ".4", "color": "#CCCCCC" }} className={styles.icon} icon="fa-duotone fa-arrow-up-arrow-down" /> </span> :
                    state === 1 ? <span style={{ background: "#009FDA" }} className={styles.container}>
                        <FontAwesomeIcon className={styles.icon} style={{ "--fa-primary-opacity": ".4", "--fa-secondary-opacity": "1", "color": "white" }} icon="fa-duotone fa-arrow-up-arrow-down" /> </span> :
                        <span style={{ "background": "#009FDA" }} className={styles.container}>
                            <FontAwesomeIcon className={styles.icon} style={{ "color": "white" }} icon="fa-duotone fa-arrow-up-arrow-down" /></span>
            }
        </div>
    );
}

const getNextState = (state) => {
    if (state === 2) {
        return 0;
    }
    return state + 1;
}

export default SortIcon;