// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FilterIcon_container__mZFBi {\n    padding: 3px;\n    box-sizing: border-box;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    cursor: pointer;\n    width: 18px;\n    height: 18px;\n    background: white;\n    border-radius: 2.5px;\n}\n\n.FilterIcon_container__mZFBi:hover {\n    cursor: pointer;\n}\n\n.FilterIcon_container__mZFBi.FilterIcon_activeFilter__bgaJl {\n    background: #009FDA;\n}\n\n.FilterIcon_icon__GH4yo {\n    width: 10px;\n    height: 10px;\n}\n\n.FilterIcon_icon__GH4yo.FilterIcon_whiteIcon__jlYQS {\n    color: #ffffff;\n}\n\n.FilterIcon_icon__GH4yo.FilterIcon_greyIcon__PUROB {\n    color: #CCCCCC;\n}\n\n.FilterIcon_icon__GH4yo.FilterIcon_blackIcon__IB9ne {\n    color: #262626;\n}\n\n.FilterIcon_background__dCMIu {\n    display: inline-block;\n}", "",{"version":3,"sources":["webpack://./src/components/ui/atoms/filterIcon/FilterIcon.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,sBAAsB;IACtB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,eAAe;IACf,WAAW;IACX,YAAY;IACZ,iBAAiB;IACjB,oBAAoB;AACxB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,qBAAqB;AACzB","sourcesContent":[".container {\n    padding: 3px;\n    box-sizing: border-box;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    cursor: pointer;\n    width: 18px;\n    height: 18px;\n    background: white;\n    border-radius: 2.5px;\n}\n\n.container:hover {\n    cursor: pointer;\n}\n\n.container.activeFilter {\n    background: #009FDA;\n}\n\n.icon {\n    width: 10px;\n    height: 10px;\n}\n\n.icon.whiteIcon {\n    color: #ffffff;\n}\n\n.icon.greyIcon {\n    color: #CCCCCC;\n}\n\n.icon.blackIcon {\n    color: #262626;\n}\n\n.background {\n    display: inline-block;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "FilterIcon_container__mZFBi",
	"activeFilter": "FilterIcon_activeFilter__bgaJl",
	"icon": "FilterIcon_icon__GH4yo",
	"whiteIcon": "FilterIcon_whiteIcon__jlYQS",
	"greyIcon": "FilterIcon_greyIcon__PUROB",
	"blackIcon": "FilterIcon_blackIcon__IB9ne",
	"background": "FilterIcon_background__dCMIu"
};
export default ___CSS_LOADER_EXPORT___;
