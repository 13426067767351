import styles from './HelpMenuButton.module.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fal } from "@fortawesome/pro-light-svg-icons";
import { library } from '@fortawesome/fontawesome-svg-core'
library.add(fal);

function HelpMenuButton({handleHelpMenuClosedChange}){
    return(
        <div className={styles.helpMenuButton} onClick={handleHelpMenuClosedChange}>
            <FontAwesomeIcon icon={["fal", "circle-question"]} className={styles.icon} />
        </div>
    )
}

export default HelpMenuButton;