import styles from "./BusinessName.module.css"
import { Checkbox, inputColor, InputType } from '@ctouch-europe-b-v/react-component-library';
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fal } from "@fortawesome/pro-light-svg-icons";
import BusinessTitle from "../../atoms/businessTitle/BusinessTitle";
import FilterIcon from "../../atoms/filterIcon/FilterIcon";
import MultiSelectButton from "../../atoms/multiSelectButton/MultiSelectButton";
import SortIcon from "../../atoms/sortIcon/SortIcon";
import FilterMenu from "../filterMenu/FilterMenu";
import { useState, useRef } from "react";
import useOnClickOutside from './../../../../hooks/UseOnClickOutside';

library.add(fal);

function BusinessName({ businessName, isMultiSelectActive, className, multiChange, hasFilters, filters, setSort, onSelectedChange, isFirmwareUpgradeActive, isMultiSelectVisible, displaySettingsClassName }) {
    //can be removed when multiselectbutton no longer needs to be hidden
    const ref = useRef();
    useOnClickOutside(ref, () => setIsOpen(false));
    const showultiSelectBtn = false;
    const [isOpen, setIsOpen] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [checked, setChecked] = useState(false);
    return (
        <div className={[styles.businessName, className].filter(e => !!e).join(' ')}>
            <div className={styles.flexBox}>
                {isMultiSelectActive || isFirmwareUpgradeActive ? <Checkbox inputColor={"blue"} onChange={(e) => { setChecked(e.target.checked); onSelectedChange(e.target.checked); }} checked={checked} /> : <FontAwesomeIcon icon={["fal", "user-group"]} className={styles.icon} />}
                <BusinessTitle businessName={businessName} />
            </div>
            {
                hasFilters &&
                <FilterMenu className={[!isOpen && styles.hidden, displaySettingsClassName].filter(e => !!e).join(" ")} headerType={InputType.checkBox} innerRef={ref} isSorted={(state) => { setSort(state, "name"); setIsActive(state < 2) }} items={filters} onFilterIconChange={(active) => setIsActive(active)} />
            }
            {
                hasFilters &&
                <div className={styles.buttonsFlex}>
                    <FilterIcon onClick={() => { setIsOpen(!isOpen) }} iconIsBlack active={isActive} />
                    {isMultiSelectVisible && <MultiSelectButton onClick={multiChange} isMultiSelectActive={isMultiSelectActive} />}
                </div>
            }
        </div>
    )
}

BusinessName.defaultProps = {
    hasFilters: true
}

export default BusinessName;