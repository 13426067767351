import { PopUpHeader, PopUpText, PopUpButtons, ButtonColor, PopUp } from '@ctouch-europe-b-v/react-component-library';
import { useAuth0 } from "@auth0/auth0-react";
import { RoleService } from "../../../../services/RoleService";

function VerifyMailPopUp() {
    const { logout } = useAuth0();

    return (
        <PopUp>
            <PopUpHeader headerText={"Verify email"} />
            <PopUpText bodyText={"Your account has been created successfully. Please check your e-mail inbox for a CTOUCH email, and click the Verify Account button."} />
            <PopUpButtons hasBtn1 textBtn1={"RESEND EMAIL"} variantBtn1={""} hasBtn2 onClickBtn2={() => { RoleService.removeStorage(); logout({ logoutParams: { returnTo: window.location.origin } }) }} textBtn2={"CLOSE"} variantBtn2={ButtonColor.lifeAquatic} />
        </PopUp>
    )
}

export default VerifyMailPopUp;