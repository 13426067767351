// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ChartWidgetFooter_footerContainer__JhElN {\n  padding-bottom: 16px;\n  display: flex;\n  justify-content: space-between;\n}", "",{"version":3,"sources":["webpack://./src/components/ui/atoms/chartWidgetFooter/ChartWidgetFooter.module.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,aAAa;EACb,8BAA8B;AAChC","sourcesContent":[".footerContainer {\n  padding-bottom: 16px;\n  display: flex;\n  justify-content: space-between;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footerContainer": "ChartWidgetFooter_footerContainer__JhElN"
};
export default ___CSS_LOADER_EXPORT___;
