import styles from './HelpMenu.module.css'

import { useState, useRef } from "react";
import HelpContextMenu from "../../molecules/helpContextMenu/HelpContextMenu"
import HelpMenuButton from '../../atoms/helpMenuButton/HelpMenuButton';

import useOnClickOutside from '../../../../hooks/UseOnClickOutside';
import ReleaseNotes from '../releaseNotes/ReleaseNotes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function HelpMenu({ className }) {
    const [isHelpMenuClosed, setIsHelpMenuClosed] = useState(true)
    const [isReleaseNotesOpen, setIsReleaseNotesOpen] = useState(false)
    const ref = useRef();

    useOnClickOutside(ref, () => setIsHelpMenuClosed(true));

    function handleHelpMenuClosedChange() {
        setIsHelpMenuClosed(false);
    }

    return (
        <div className={[className, styles.container].filter(e => !!e).join(' ')}>
            <span className={styles.copyright}>CTOUCH Sphere <FontAwesomeIcon icon={["fal", "copyright"]} /> {new Date().getFullYear()} </span>
            {isReleaseNotesOpen && <ReleaseNotes closeReleaseNotes={() => setIsReleaseNotesOpen(false)} />}
            {!isHelpMenuClosed && <HelpContextMenu innerRef={ref} className={styles.HelpContextMenu} openReleaseNotes={() => setIsReleaseNotesOpen(true)} />}
            <HelpMenuButton handleHelpMenuClosedChange={handleHelpMenuClosedChange} />
        </div>
    )
}

export default HelpMenu;