// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TextNote_container__DIC7g {\n    background: #FFF7D9;\n    border-radius: 21px;\n    width: 99px;\n    height: 22px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n.TextNote_label__jcQkF {\n    font-family: 'Quatro';\n    font-style: normal;\n    font-weight: 350;\n    font-size: 8px;\n    line-height: 100%;\n    letter-spacing: 0.05em;\n    color: #262626;\n}", "",{"version":3,"sources":["webpack://./src/components/ui/atoms/textNote/TextNote.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,mBAAmB;IACnB,WAAW;IACX,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,qBAAqB;IACrB,kBAAkB;IAClB,gBAAgB;IAChB,cAAc;IACd,iBAAiB;IACjB,sBAAsB;IACtB,cAAc;AAClB","sourcesContent":[".container {\n    background: #FFF7D9;\n    border-radius: 21px;\n    width: 99px;\n    height: 22px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n.label {\n    font-family: 'Quatro';\n    font-style: normal;\n    font-weight: 350;\n    font-size: 8px;\n    line-height: 100%;\n    letter-spacing: 0.05em;\n    color: #262626;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "TextNote_container__DIC7g",
	"label": "TextNote_label__jcQkF"
};
export default ___CSS_LOADER_EXPORT___;
