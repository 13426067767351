import { AxiosService } from './AxiosService';

const baseUrl = "sphere/companies";

export const CompanyService = {
    async updateCompany(company, companyId) {
        return await AxiosService.postRequest(`${baseUrl}/${companyId}/update`, company)
    },

    async getCompany(company) {
        return await AxiosService.getRequest(`${baseUrl}/${company.id}/`)
    },

    async getAllSimple(role, id) {
        return await AxiosService.getRequest(`${baseUrl}/${role}/${id}/simple_all`)
    },

    async createCompany(company) {
        return await AxiosService.postRequest(`${baseUrl}/create`, company)
    },
    async getCompaniesByEmail(email) {
        return await AxiosService.getRequest(`${baseUrl}/get_by_email/${email}`)
    }
};
