import styles from './TableBodyCell.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fas } from "@fortawesome/pro-solid-svg-icons";
import { fal } from "@fortawesome/pro-light-svg-icons";
import { library } from '@fortawesome/fontawesome-svg-core'
import { useState } from 'react';
import DisplayInfoPopUp from '../../atoms/displayInfoPopUp/DisplayInfoPopUp';
import FirmwareUpdateBullet from './../firmwareUpdateBullet/FirmwareUpdateBullet';
library.add(fas);
library.add(fal);
const getTimeString = (timeString, withSeconds) => {
    const date = new Date(timeString);
    const year = date.getFullYear();
    const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
    const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
    const hour = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours();
    const minute = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
    const seconds = date.getSeconds() < 10 ? `0${date.getSeconds()}` : date.getSeconds();
    return (
        <div>
            <span className={styles.date}>{day}-{month}-{year} {hour}:{minute}{withSeconds && `:${seconds}`}</span>
        </div>)
}

const TableBodyCell = ({ isSelected, isHovered, className, newFirmware, endDate, lastOnline, isOnline, hasSphereUpdate, hasFirmwareUpdate, hasText, serial, children, setIsContextClosed, isConnectCode, firmware, isUpdating, getCompany, showPopup, updateFirmware, ...props }) => {
    const [isCellHovered, setIsCellHovered] = useState(false)
    let timeStamp = hasText !== undefined ? getTimeString(hasText[1]) : "";
    return (
        <div onMouseEnter={() => setIsCellHovered(true)} onMouseLeave={() => setIsCellHovered(false)} className={[styles.container, className, isHovered && styles.hovered, isSelected && styles.selected].filter(e => !!e).join(' ')} {...props}>
            <span className={[styles.value, hasSphereUpdate && styles.hasSphereUpdate].filter(e => !!e).join(' ')}>
                {children}
                {
                    (hasText && hasText[0] === "endDate") && (isCellHovered || showPopup) ?
                        <DisplayInfoPopUp variant={""}>Trial Expires On {timeStamp} </DisplayInfoPopUp>
                        : (hasText && hasText[0] === "lastOnline") && (isCellHovered || showPopup) ?
                            <DisplayInfoPopUp variant="lastOnline">{isOnline ? "Online Since" : " Last Active Connection"} {timeStamp}</DisplayInfoPopUp>
                            : ""
                }
            </span>
            {(hasSphereUpdate) && <FirmwareUpdateBullet className={styles.sphereUpdate} isOnline={isOnline} getCompany={getCompany} isUpdating={isUpdating} newFirmware={newFirmware} firmware={firmware} serial={serial} />}
            {isConnectCode && <FontAwesomeIcon icon={["fal", "ellipsis-vertical"]} className={styles.menuIcon} onClick={() => setIsContextClosed(false)} />}
        </div >
    );
}
export default TableBodyCell;
