// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ConnectionStatus_container__dmvfl {\n    display: flex;\n    align-items: center;\n    gap: 14px;\n    font-size: 11px;\n}\n\n.ConnectionStatus_label__DV14Z {\n    font-size: 13px;\n}\n\n.ConnectionStatus_onlineIndicator__bpVx4 {\n    color: #00bb00;\n}\n\n.ConnectionStatus_offlineIndicator__nG8dI {\n    color: #ccc;\n}", "",{"version":3,"sources":["webpack://./src/components/ui/atoms/connectionStatus/ConnectionStatus.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;IACT,eAAe;AACnB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,WAAW;AACf","sourcesContent":[".container {\n    display: flex;\n    align-items: center;\n    gap: 14px;\n    font-size: 11px;\n}\n\n.label {\n    font-size: 13px;\n}\n\n.onlineIndicator {\n    color: #00bb00;\n}\n\n.offlineIndicator {\n    color: #ccc;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ConnectionStatus_container__dmvfl",
	"label": "ConnectionStatus_label__DV14Z",
	"onlineIndicator": "ConnectionStatus_onlineIndicator__bpVx4",
	"offlineIndicator": "ConnectionStatus_offlineIndicator__nG8dI"
};
export default ___CSS_LOADER_EXPORT___;
