import styles from './ChartWidgetListSmall.module.css';
import ChartWidgetFooter from '../../atoms/chartWidgetFooter/ChartWidgetFooter';
import ChartWidgetTitle from '../../atoms/chartWidgetTitle/ChartWidgetTitle';
import { WidgetContainer } from '@ctouch-europe-b-v/react-component-library';
import { useEffect, useState } from 'react';
import ChartWidgetList from '../../atoms/chartWidgetList/ChartWidgetList';

const ChartWidgetListSmall = ({ title, className, colorVariant, dataList, plusIsPositive, iconLeft, setOverLayActive, overLayActive, hasWarning, hasError, iconRight, containsPlus }) => {
  const [fullViewActive, setFullViewActive] = useState(false);
  useEffect(() => {
    if (!overLayActive) {
      setFullViewActive(false)
    }
  }, [overLayActive])
  return (
    <div className={fullViewActive && styles.wrapper}>
      <WidgetContainer size="small" className={[className, (hasError || hasWarning) && styles.errorContainer, styles.container, styles[colorVariant], fullViewActive && styles.fullViewContainer].filter(e => !!e).join(" ")}>
        <ChartWidgetTitle title={title} iconLeft={iconLeft} iconRight={iconRight} />
        {
          hasWarning
            ? <span className={styles.warning}>There is not enough data to display this information...</span>
            : hasError
              ? <span className={styles.error}>Something went wrong displaying this data...</span>
              : <ChartWidgetList background={colorVariant} containsPlus={containsPlus} data={dataList} fullViewActive={fullViewActive} plusIsPositive={plusIsPositive} sortIsNeeded={true} />
        }
        <ChartWidgetFooter>
          {!fullViewActive && (!hasWarning && !hasError)
            ? <span className={styles.footerButton} onClick={() => { setFullViewActive(true); setOverLayActive(true) }}>View All</span>
            : !fullViewActive && (hasError || hasWarning)
              ? <span></span>
              : <span className={styles.footerButton} onClick={() => { setFullViewActive(false); setOverLayActive(false) }}>Close</span>
          }
        </ChartWidgetFooter>
      </WidgetContainer>
    </div>
  )
}

export default ChartWidgetListSmall
