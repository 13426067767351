import Sidebar from '../../ui/molecules/sidebar/Sidebar';
import styles from './AccountSettings.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Input, InputType, Button, ButtonColor } from '@ctouch-europe-b-v/react-component-library';
import TableHeaderCell from './../../ui/molecules/tableHeaderCell/TableHeaderCell';
import Roles from './../../../enums/Roles';
import { useEffect, useRef, useState } from 'react';
import useOnClickOutside from './../../../hooks/UseOnClickOutside';
import { Modal } from "@ctouch-europe-b-v/react-component-library"
import CreateConnectCodeForm from './../../ui/organisms/createConnectCodeForm/CreateConnectCodeForm';
import TableBodyRow from '../../ui/organisms/tableBodyRow/TableBodyRow';
import { useAuth0 } from "@auth0/auth0-react";
import { CompanyService } from '../../../services/CompanyService';
import { ProvisionCodeService } from '../../../services/ProvisionCodeService';
import HelpMenu from '../../ui/organisms/helpMenu/HelpMenu';
import axios from 'axios';
import TabMenu from "../../ui/molecules/tabMenu/TabMenu"
import Tab from '../../ui/atoms/tab/Tab';
import { RoleService } from '../../../services/RoleService';
const AccountSettings = ({ role, user, company, companySub }) => {

    /*mock data*/

    const companyInformation = {
        // "id": 28,
        // "name": "Company 1",
        // "email_domain_name": "company1.com",
        // "external_management_connect_code": "bb",
        // "CTOUCH_support": true
    }

    const provisionCodesList =
        [
            // {
            //     "provision_code": "aaaaaaaa",
            //     "company": "company 1",
            //     "created_by": null,
            //     "project_name": "Test",
            //     "valid_until": "2023-10-23 09:00:00"
            // },
            // {
            //     "provision_code": "bbbbbbbb",
            //     "company": "company 2",
            //     "created_by": "ctouch.eu",
            //     "project_name": "external test",
            //     "valid_until": "2023-10-28 14:00:00"
            // }
        ]
    /* end mock data */


    const { logout } = useAuth0();
    const isInitialRender = useRef(true);
    const [isAddConnectCodeClosed, setIsAddConnectCodeClosed] = useState(true)
    const modalRef = useRef();
    const [newPassword, setNewPassword] = useState("");
    const [repeatPassword, setRepeatPassword] = useState("");
    const [passwordChangeMessage, setPasswordChangeMessage] = useState("");
    const [provisionCodeList, setProvisionCodeList] = useState(provisionCodesList);
    const [dealerCode, setDealerCode] = useState(companyInformation.external_management_connect_code);
    const [ctouchSupport, setCtouchSupport] = useState();
    const [companiesList, setCompaniesList] = useState([]);
    const [companyName, setCompanyName] = useState(companyInformation.name)
    const [companyId, setCompanyId] = useState(null);
    const [selectedTab, setSelectedTab] = useState(0);
    const [tableContent, setTableContent] = useState();

    const refreshProvisionCodes = () => {
        ProvisionCodeService.getAll(company).then((response) => {
            setProvisionCodeList(response.data);
        });
    }

    useEffect(() => {
        const primaryRoleFunc = () => {
            if (role.roles.some((role) => role.Name === "CTOUCH")) { return "CTOUCH" }
            else if (role.roles.some((role) => role.Name === "Reseller")) { return "Reseller" }
            else return "User"
        }
        refreshProvisionCodes();
        CompanyService.getCompany({ id: company }).then((response) => {
            setCompanyId(response.data.id);
            setDealerCode(response.data.external_management_connect_code);
            setCtouchSupport(response.data.CTOUCH_support);
            setCompanyName(response.data.name);
        })
        CompanyService.getAllSimple(primaryRoleFunc(), company).then((response) => {
            const sortedCompanies = response.data.sort((a, b) => {
                const aName = a.name.toLowerCase();
                const bName = b.name.toLowerCase();
                if (aName > bName) return 1
                else if (aName < bName) return -1
                else return 0
            })
            setCompaniesList(sortedCompanies);
        });
    }, [])

    useEffect(() => {
        if (isInitialRender.current) {
            isInitialRender.current = false;
        } else {
            const now = new Date();
            (selectedTab === 0) ? setTableContent(provisionCodeList.filter((code) => { const validUntil = new Date(code.valid_until); return validUntil.getTime() > now.getTime() }).map((provisionCode) => { return <TableBodyRow onRemove={() => { handleDeleteConnectCode(provisionCode.provision_code) }} cellClass={styles.cell} content={[provisionCode.provision_code, provisionCode.company, provisionCode.project_name, calculateRemainingTime(provisionCode.valid_until)]} /> }))
                : setTableContent(provisionCodeList.filter((code) => { const validUntil = new Date(code.valid_until); return validUntil.getTime() < now.getTime() }).map((provisionCode) => { return <TableBodyRow onRemove={() => { handleDeleteConnectCode(provisionCode.provision_code) }} cellClass={styles.cell} content={[provisionCode.provision_code, provisionCode.company, provisionCode.project_name, calculateRemainingTime(provisionCode.valid_until)]} /> }));
        }
    }, [selectedTab, provisionCodeList])


    const handleChangePassword = async () => {
        if (newPassword === repeatPassword) {
            var options = {
                method: 'PATCH',
                url: `https://${process.env.REACT_APP_AUTH_DOMAIN}/api/v2/users/${user.sub}`,
                headers: {
                    'content-Type': 'application/json',
                    authorization: `Bearer placeholder`,
                },
                data: { password: newPassword, connection: process.env.REACT_APP_AUTH_CONNECTION },
            };

            axios.request(options).then(function (response) {
                // Success message or redirect to another page
                setPasswordChangeMessage("Password has been changed succesfully")
            }).catch(function (error) {
                console.error(error);
                setPasswordChangeMessage("Password change failed")
            });
        } else {
            setPasswordChangeMessage("new and repeat password do not match")
        }
    };

    const handleSupportSettings = () => {
        companyInformation.external_management_connect_code = dealerCode;
        companyInformation.CTOUCH_support = ctouchSupport;
        CompanyService.updateCompany(companyInformation, companyId);
        ;
    }

    const handleCtouchSupport = (currentSetting) => {
        if (currentSetting === 0) {
            setCtouchSupport(1);
        } else {
            setCtouchSupport(0);
        }
    }

    const handleDeleteConnectCode = (connectCode) => {
        const provisionCode = {
            "provisionCode": connectCode
        }

        ProvisionCodeService.delete(provisionCode).then(() => {
            refreshProvisionCodes();
        });
    }
    const calculateRemainingTime = (validUntil) => {
        const currentTime = new Date();
        const validUntilTime = new Date(validUntil);
        const difference = validUntilTime - currentTime;
        const days = difference / (1000 * 3600 * 24);

        if (days < 0) {
            return <span className={styles.expired}>Expired</span>;
        } else if (days < 1) {
            const hoursRemaining = days * 24;
            return `${Math.ceil(hoursRemaining)} Hours Remaining`;
        } else {
            return `${Math.ceil(days)} Days Remaining`;
        }
    }
    useOnClickOutside(modalRef, () => setIsAddConnectCodeClosed(true));
    // const tableContent = provisionCodeList.map((provisionCode) =>  { return <TableBodyRow onClick={() => { handleDeleteConnectCode(provisionCode.provision_code) }} cellClass={styles.cell} content={[provisionCode.provision_code, provisionCode.company, provisionCode.project_name,calculateRemainingTime(provisionCode.valid_until)]} />} )

    return (
        <>
            {!isAddConnectCodeClosed && <Modal grey innerRef={modalRef}><CreateConnectCodeForm refreshProvisionCodes={refreshProvisionCodes} company={companyName} companiesList={companiesList} closeAddConnectCode={() => setIsAddConnectCodeClosed(true)} /></Modal>}
            <div className={styles.grid}>
                <Sidebar companySub={companySub} role={role} sphereUser={role} className={styles.sidebar} />
                <div className={styles.header}>
                    <span>
                        <FontAwesomeIcon className={styles.settingsIcon} icon={["fal", "gear"]} />
                        <span className={styles.pageTitle}>Settings</span>
                    </span>
                    <span className={styles.logout} onClick={() => { RoleService.removeStorage(); logout({ logoutParams: { returnTo: window.location.origin } }) }}>Logout</span>
                </div>
                <div className={styles.general}>
                    <span className={styles.generalTitle}>General</span>
                    <Input className={styles.input} disabled value={companyName} inputType={InputType.text} labelText="Company name" isColumn />
                    <Input className={styles.input} disabled value={user.email} inputType={InputType.text} labelText="Email" isColumn />
                </div>
                {//!user.sub.startsWith("waad") &&
                    //<div className={styles.password}>
                    //    <span className={styles.generalTitle}>Account password</span>
                    //    <Input className={styles.input} placeholder={"Type here..."} type={"password"} inputType={InputType.text} labelText="New password" isColumn onChange={(e) => setNewPassword(e.target.value)} />
                    //    <Input className={styles.input} placeholder={"Type here..."} type={"password"} inputType={InputType.text} labelText="Repeat password" isColumn onChange={(e) => setRepeatPassword(e.target.value)} />
                    //    <span>{passwordChangeMessage}</span>
                    //    <Button className={styles.saveButton} title="Save password" iconName={"save"} variant={ButtonColor.blue} isDisabled={newPassword.length < 1 && true} onClick={handleChangePassword} />
                    //</div>
                }
                <div className={styles.dealerCode}>
                    {role.roles.some(role => role.Name === Roles.customer) &&
                        <>
                            <span className={styles.generalTitle}>Dealer code</span>
                            <Input className={styles.input} placeholder={"Type here..."} inputType={InputType.text} value={dealerCode} onChange={(e) => setDealerCode(e.target.value)} labelText="Enter your dealer's code here so that they can add screens for you into Sphere while installing:" isColumn />
                        </>
                    }
                    {(role.roles.some(role => role.Name === Roles.customer || role.Name === Roles.reseller)) &&
                        <>
                            <span className={styles.generalTitle}>Ctouch support</span>
                            <Input className={styles.checkbox} inputType={InputType.checkBox} checked={ctouchSupport === 1} onChange={() => handleCtouchSupport(ctouchSupport)} inputLeft labelText="Allow CTOUCH Service access to provide support" />
                            <Button className={styles.saveButton} title="Save support settings" iconName={"save"} variant={ButtonColor.blue} onClick={handleSupportSettings} />
                        </>
                    }
                </div>
                <div className={styles.connect}>
                    <div className={styles.connectRow}>
                        <div className={styles.textContainer}>
                            <span className={styles.connectTitle}>Connect displays to Sphere</span>
                            <span className={styles.connectBody}>With most CTOUCH displays a connect code can be used to add one or more display(s) to Sphere.</span>
                            <span className={[styles.connectBody, styles.error].filter((e) => !!e).join(" ")}>Currently only available on CTOUCH Neo, Riva R2, Riva D2 and displays equipped with an Android 12 module.</span>
                        </div>
                        <Button className={styles.saveButton} iconName={"plus"} title="Create code" variant={ButtonColor.blue} onClick={() => setIsAddConnectCodeClosed(false)} />
                    </div>
                    <TabMenu onSelectedChange={(index) => { setSelectedTab(index) }}>
                        <Tab title="Active" icon={'bolt'} />
                        <Tab title="Expired" icon={'bolt-slash'} />
                    </TabMenu>
                    <div className={styles.table}>
                        <div className={[styles.tableRow, styles.headerRow].filter((e) => !!e).join(" ")}>
                            <TableHeaderCell className={styles.cell} hasFilter={false} title={"Connect code"} />
                            <TableHeaderCell className={styles.cell} hasFilter={false} title={"Company name"} />
                            <TableHeaderCell className={styles.cell} hasFilter={false} title={"Project name"} />
                            <TableHeaderCell className={styles.cell} hasFilter={false} title={"Time left"} />
                        </div>
                        {tableContent}
                        <div className={styles.connectDisplay}><span onClick={() => setIsAddConnectCodeClosed(false)}>+ Create a Connect Code</span></div>
                    </div>
                </div>
                <div className={styles.footer}>
                    <HelpMenu className={styles.helpMenu} />
                </div>
            </div>
        </>
    );
}

export default AccountSettings;