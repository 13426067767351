import { createContext, useContext, useEffect } from "react";
import { io } from "socket.io-client";
export const socket = io(process.env.REACT_APP_WS_URL);
const WebSocketContext = createContext(null);
export const WebSocketProvider = ({ serial, children, handleErrorMessage }) => {
  useEffect(() => {
    socket.connect();
    function onConnect() {
      if (serial) {
        joinRoom(serial, socket);
      }
      socket.emit("set-nickname", "frontend");
    }
    socket.on("connect_error", (err) => {
    });
    socket.on("connection", onConnection);
    function onConnection() {
    }
    function onDisconnect() {
    }

    socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);

 
    socket.on("errorMessage", (message) => {
      handleErrorMessage(message.data)
    })


    return () => {
      socket.off("connect", onConnect);
      socket.off("disconnect", onDisconnect);
    };
    
  }, [serial]);

  const joinRoom = (serial) => {
    socket.emit("NewClient", serial);
  };

  const send = (name, value, serial, setting_id, backup = false) => {
    if (!socket || !socket.connected) {
      return;
    }
    socket.emit("updateSetting", name, value, serial, setting_id, backup, (msg) => {
      //   console.log(msg);
    });
  };

  const update = (display) => {
    socket.emit('updateFirmware', display);
  }

  const wakeOnLan = (serialNumber, macAddress) => {
    socket.emit('wakeOnLan', serialNumber, macAddress)
  }

  const restoreToDefault = (display) => {
    socket.emit('restoreToDefault', display.serialNumber)
  }

  const configExport = (serialNumber) => {
    socket.emit('configExport', serialNumber)
  }
  const getLogs = (serialNumber) => {
    socket.emit('getLog', serialNumber)
    return new Promise((resolve, reject) => socket.on('logResponse', (data) => {
      resolve(data);
    }))
  }

  const installApp = (url, serial) => {
    socket.emit("appInstall", url, serial);
  }

  const uninstallApp = (appName, serial) => {
    socket.emit("appUninstall", appName, serial);
  }

  const getAllApps = (serial) => {
    socket.emit("getAllApps", serial)
    return new Promise((resolve, reject) => socket.on("allAppsResponse", (data) => {
      resolve(data)
    }))
  }
  return (<WebSocketContext.Provider value={{ send, joinRoom, wakeOnLan, update, socket, restoreToDefault, configExport, getLogs, installApp, uninstallApp, getAllApps }}> {children} </WebSocketContext.Provider>);
  // return { send, joinRoom, wakeOnLan, update };
};
export const useWebSocket = () => { const context = useContext(WebSocketContext); if (!context) { throw new Error("useWebSocket must be used within a WebSocketProvider component"); } return context; };