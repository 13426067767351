import { AxiosService } from './AxiosService';

const baseUrl = "/mailservice";

export const MailService = {
    async sentRequestAdmin(mail) {
            return await AxiosService.postRequest(`${baseUrl}/adminRequested`, mail)
    },

    async sentSentToAdminConfirmation(mail) {
        return await AxiosService.postRequest(`${baseUrl}/mailSentToAdmin`, mail)
    },

    async sentNewUserRequestToAdmin(mail, new_user_email) {
        return await AxiosService.postRequest(`${baseUrl}/adminNewAccountRequest`, {mail, new_user_email})
    }
}