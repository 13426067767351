import styles from "./ReleaseNotes.module.css";
import { Modal, PopUpButtons, PopUpHeader, PopUpText } from "@ctouch-europe-b-v/react-component-library"
const ReleaseNotes = ({ closeReleaseNotes }) => {
    return (
        <Modal>
            <PopUpHeader className={styles.title} headerText={"Release notes"} />
            <PopUpText bodyText={"Version 2.4.0"} hasIcon />
            <PopUpText bodyText={"• The dashboard page received a UI update"} />
            <PopUpText bodyText={"• The dashboard page shows more display data"} />

            <PopUpButtons hasBtn1 textBtn1={"BACK"} btn1HasArrow onClickBtn1={closeReleaseNotes} />
        </Modal>
    );
}

export default ReleaseNotes;