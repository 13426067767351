import styles from "./StatusBullet.module.css"
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fal } from "@fortawesome/pro-light-svg-icons";

function StatusBullet({isHovered, status, className}) {
    return (
        <>
        <div className={[styles.bullet, status == 1 && styles.online, status == 0 && styles.offline, isHovered && styles.hover, className !== undefined && className].filter(e => !!e).join(' ')}>{status == 1 ? <FontAwesomeIcon icon={["fas", "link-simple"]} /> : <FontAwesomeIcon icon={["fas", "link-simple-slash"]}/>}</div>
        </>
    )
}

export default StatusBullet;