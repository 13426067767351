import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AppListRow from '../appListRow/AppListRow';
import styles from './BetaFeature.module.css';

const BetaFeature = ({ text }) => {
  return (
    <AppListRow className={styles.betaFeature}>
      <span className={styles.iconWrapper}><FontAwesomeIcon className={styles.icon} icon={["fas", "flask"]} /></span>
      <span className={styles.betaFeatureText}>{text ? text : "Feature in beta. Requires Sphere 2.5.5 app or newer."}</span>
    </AppListRow>
  )
}

export default BetaFeature