import styles from "./TextNote.module.css"

const TextNote = ({ value }) => {
    return (
        <div className={styles.container}>
            <span className={styles.label}>{value}</span>
        </div>
    );
}

export default TextNote;