// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FooterSideButton_container__Rmbg6 {\n    width: 100%;\n    height: 100%;\n    display: flex;\n    align-items: center;\n    padding-left: 31px;\n    gap: 13px;\n}", "",{"version":3,"sources":["webpack://./src/components/ui/organisms/footerSideButton/FooterSideButton.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,kBAAkB;IAClB,SAAS;AACb","sourcesContent":[".container {\n    width: 100%;\n    height: 100%;\n    display: flex;\n    align-items: center;\n    padding-left: 31px;\n    gap: 13px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "FooterSideButton_container__Rmbg6"
};
export default ___CSS_LOADER_EXPORT___;
