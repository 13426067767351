// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Content_row__TKab- {\n    grid-template-rows: 100%;\n    width: 100%;\n    max-height: 46px;\n    background: white;\n    display: inline-grid;\n    grid-template-columns: 287px repeat(6, minmax(auto, 200px));\n    cursor: pointer;\n    /* gap: 10px; */\n}\n\n.Content_row__TKab- .Content_emptyBox__vz6Li:last-child {\n    grid-column: -1;\n    min-width: 220px;\n    width: auto;\n}\n\n.Content_row__TKab-:not(.Content_selected__TrzIR):hover .Content_title__EqX6- {\n    background: #E6E6E6;\n}\n\n.Content_row__TKab-:not(.Content_selected__TrzIR):hover .Content_customer__HzrF1 {\n    background: #E6E6E6;\n}\n\n.Content_row__TKab-:not(.Content_selected__TrzIR):hover {\n    background: #F0F1F3;\n}\n\n.Content_selected__TrzIR {\n    background: #C7F0FF;\n}\n\n.Content_emptyBox__vz6Li {\n    height: auto;\n    overflow: hidden;\n}", "",{"version":3,"sources":["webpack://./src/components/ui/organisms/content/Content.module.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;IACxB,WAAW;IACX,gBAAgB;IAChB,iBAAiB;IACjB,oBAAoB;IACpB,2DAA2D;IAC3D,eAAe;IACf,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,WAAW;AACf;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,gBAAgB;AACpB","sourcesContent":[".row {\n    grid-template-rows: 100%;\n    width: 100%;\n    max-height: 46px;\n    background: white;\n    display: inline-grid;\n    grid-template-columns: 287px repeat(6, minmax(auto, 200px));\n    cursor: pointer;\n    /* gap: 10px; */\n}\n\n.row .emptyBox:last-child {\n    grid-column: -1;\n    min-width: 220px;\n    width: auto;\n}\n\n.row:not(.selected):hover .title {\n    background: #E6E6E6;\n}\n\n.row:not(.selected):hover .customer {\n    background: #E6E6E6;\n}\n\n.row:not(.selected):hover {\n    background: #F0F1F3;\n}\n\n.selected {\n    background: #C7F0FF;\n}\n\n.emptyBox {\n    height: auto;\n    overflow: hidden;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": "Content_row__TKab-",
	"emptyBox": "Content_emptyBox__vz6Li",
	"selected": "Content_selected__TrzIR",
	"title": "Content_title__EqX6-",
	"customer": "Content_customer__HzrF1"
};
export default ___CSS_LOADER_EXPORT___;
