// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ThanksPopUp_flexContainer__N0y9A {\n    display: flex;\n    align-items: center;\n    flex-direction: column;\n    gap: 27px;\n    padding: 64px 0;\n}\n\n.ThanksPopUp_reward__ZvYxc {\n    height: auto;\n    width: 144px;\n}", "",{"version":3,"sources":["webpack://./src/components/ui/organisms/thanksPopUp/ThanksPopUp.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,sBAAsB;IACtB,SAAS;IACT,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,YAAY;AAChB","sourcesContent":[".flexContainer {\n    display: flex;\n    align-items: center;\n    flex-direction: column;\n    gap: 27px;\n    padding: 64px 0;\n}\n\n.reward {\n    height: auto;\n    width: 144px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"flexContainer": "ThanksPopUp_flexContainer__N0y9A",
	"reward": "ThanksPopUp_reward__ZvYxc"
};
export default ___CSS_LOADER_EXPORT___;
