import styles from "./ThanksPopUp.module.css"
import { ReactComponent as Reward } from "../../../../assets/reward.svg"
import { PopUpHeader, PopUpButtons, ButtonColor, PopUp } from '@ctouch-europe-b-v/react-component-library';
import { useAuth0 } from "@auth0/auth0-react";
import { RoleService } from "../../../../services/RoleService";

function ThanksPopUp() {
    const { logout } = useAuth0();
    return (
        <PopUp>
            <div className={styles.flexContainer}>
                <Reward className={styles.reward} />
                <PopUpHeader headerText={"Thanks"} />
                <PopUpButtons hasBtn2 btn2HasArrow textBtn2={"GET STARTED"} onClickBtn2={() => { RoleService.removeStorage(); logout({ logoutParams: { returnTo: window.location.origin } }) }} variantBtn2={ButtonColor.lifeAquatic} />
            </div>
        </PopUp>
    )
}

export default ThanksPopUp;