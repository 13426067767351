// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AppList_removeButton__sXa83  {\n  background-color: #262626;\n  color: #fff;\n}\n.AppList_removeButton__sXa83 p {\n  color: #fff;\n}\n.AppList_icon__bIE-U {\n  color: white;\n}\n.AppList_imgIcon__KIj8m {\n  border-radius: 8px;\n}\n.AppList_appButton__rGZ1- {\n  padding: 9px 12px;\n}\n.AppList_appButton__rGZ1-:active {\n  background-color: #009FDA;\n  box-shadow:inset 1px 2px 1px 1px rgba(0,0,0,0.25);\n}\n.AppList_removeButton__sXa83:active {\n  background-color: #262626;\n  box-shadow: inset 1px 2px 1px 1px rgba(255,255,255,0.25);\n}", "",{"version":3,"sources":["webpack://./src/components/ui/molecules/appList/AppList.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,WAAW;AACb;AACA;EACE,WAAW;AACb;AACA;EACE,YAAY;AACd;AACA;EACE,kBAAkB;AACpB;AACA;EACE,iBAAiB;AACnB;AACA;EACE,yBAAyB;EACzB,iDAAiD;AACnD;AACA;EACE,yBAAyB;EACzB,wDAAwD;AAC1D","sourcesContent":[".removeButton  {\n  background-color: #262626;\n  color: #fff;\n}\n.removeButton p {\n  color: #fff;\n}\n.icon {\n  color: white;\n}\n.imgIcon {\n  border-radius: 8px;\n}\n.appButton {\n  padding: 9px 12px;\n}\n.appButton:active {\n  background-color: #009FDA;\n  box-shadow:inset 1px 2px 1px 1px rgba(0,0,0,0.25);\n}\n.removeButton:active {\n  background-color: #262626;\n  box-shadow: inset 1px 2px 1px 1px rgba(255,255,255,0.25);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"removeButton": "AppList_removeButton__sXa83",
	"icon": "AppList_icon__bIE-U",
	"imgIcon": "AppList_imgIcon__KIj8m",
	"appButton": "AppList_appButton__rGZ1-"
};
export default ___CSS_LOADER_EXPORT___;
