// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SettingsSidebar_sidebar__jGlUb {\n    background: #FFC800;\n    width: 287px;\n    height: 100%;\n}\n\n.SettingsSidebar_list__5x5IH {\n    padding: 0;\n    margin: 0px;\n}", "",{"version":3,"sources":["webpack://./src/components/ui/molecules/settingsSidebar/SettingsSidebar.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI,UAAU;IACV,WAAW;AACf","sourcesContent":[".sidebar {\n    background: #FFC800;\n    width: 287px;\n    height: 100%;\n}\n\n.list {\n    padding: 0;\n    margin: 0px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sidebar": "SettingsSidebar_sidebar__jGlUb",
	"list": "SettingsSidebar_list__5x5IH"
};
export default ___CSS_LOADER_EXPORT___;
