// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SortIcon_container__afH8X {\n    padding: 3px;\n    box-sizing: border-box;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    cursor: pointer;\n    width: 18px;\n    height: 18px;\n    background: #F0F1F3;\n    border-radius: 2.5px;\n}\n\n.SortIcon_icon__JNwnM {\n    width: 10px;\n    height: 10px;\n}\n\n.SortIcon_background__JR9Xm {\n    display: inline-block;\n}", "",{"version":3,"sources":["webpack://./src/components/ui/atoms/sortIcon/SortIcon.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,sBAAsB;IACtB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,eAAe;IACf,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,oBAAoB;AACxB;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,qBAAqB;AACzB","sourcesContent":[".container {\n    padding: 3px;\n    box-sizing: border-box;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    cursor: pointer;\n    width: 18px;\n    height: 18px;\n    background: #F0F1F3;\n    border-radius: 2.5px;\n}\n\n.icon {\n    width: 10px;\n    height: 10px;\n}\n\n.background {\n    display: inline-block;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "SortIcon_container__afH8X",
	"icon": "SortIcon_icon__JNwnM",
	"background": "SortIcon_background__JR9Xm"
};
export default ___CSS_LOADER_EXPORT___;
