import styles from "./MultiSelectButton.module.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fal } from "@fortawesome/pro-light-svg-icons";
import { library } from '@fortawesome/fontawesome-svg-core'
library.add(fal);

function MultiSelectButton({isMultiSelectActive, onClick}) {
    return(
        <div onClick={onClick} className={[styles.multiSelectBackground, isMultiSelectActive && styles.Blackbackground].filter((e) => !!e).join(" ")}>
            <FontAwesomeIcon icon={["fal", "list-ul"]} className={[styles.icon, isMultiSelectActive && styles.whiteIcon].filter((e) => !!e).join(" ")} />
        </div>
    )
}

export default MultiSelectButton;