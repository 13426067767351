import styles from "./LocationRow.module.css"
import { useState, Children, cloneElement, useEffect } from "react";
import Location from "../../molecules/location/Location";


function LocationRow({ locationTitle, onSelectedChange, isMultiSelectActive, isFirmwareUpgradeActive, children, checkAll, onLocationCheckedChange }) {
    const [isLocationClosed, setIsLocationClosed] = useState(false)
    const [locationChecked, setLocationChecked] = useState(false);
    // useEffect(() => {
    //     console.log(isMultiSelectActive)
    //     isMultiSelectActive !== true && setLocationChecked(false);
    // }, [isMultiSelectActive])

    useEffect(() => {
        setLocationChecked(false);
        ((isMultiSelectActive || isFirmwareUpgradeActive) && checkAll) ? setLocationChecked(true) : setLocationChecked(false);
    }, [isMultiSelectActive, isFirmwareUpgradeActive, checkAll])

    const handleLocationCheck = () => {
        setLocationChecked(!locationChecked);
        onLocationCheckedChange && onLocationCheckedChange(!locationChecked)
    }

    const renderChildren = () => {
        return Children.map(children, (child) => {
            return cloneElement(child, {
                locationChecked: locationChecked
            })
        })
    }

    function handleLocationClosedChange() {
        setIsLocationClosed(!isLocationClosed)
    }
    return (
        <>
            <div className={styles.row}>
                <Location isMultiSelectActive={isMultiSelectActive} isFirmwareUpgradeActive={isFirmwareUpgradeActive} isClosed={isLocationClosed} locationTitle={locationTitle} className={styles.location} checked={locationChecked} setChecked={handleLocationCheck} onClick={handleLocationClosedChange} />
            </div>
            <div className={isLocationClosed && styles.hidden}>
                {renderChildren()}
            </div>
        </>
    )
}

export default LocationRow;