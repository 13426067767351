import { AxiosService } from './AxiosService';

const baseUrl = "sphere/provision_code";

export const ProvisionCodeService = {
    async getAll(id) {
        return await AxiosService.getRequest(`${baseUrl}/${id}/all`);
    },

    async create(provisionCode) {
        return await AxiosService.postRequest(`${baseUrl}/create`, provisionCode);
    },

    async delete(provisionCode) {
        return await AxiosService.postRequest(`${baseUrl}/delete`, provisionCode);
    },

};