import styles from "./ChooseCompanyPopup.module.css"
import { PopUpHeader, PopUpText, PopUpButtons, ButtonColor, PopUp, Input, InputType } from '@ctouch-europe-b-v/react-component-library';
import { useEffect, useState } from "react";
import { CompanyService } from './../../../../services/CompanyService';

function ChooseCompanyPopup({ email, setCompany, setPage }) {
    const [companies, setCompanies] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [selectedCompany, setSelectedCompany] = useState(null);
    useEffect(() => {
        CompanyService.getCompaniesByEmail(email?.substring(email?.indexOf("@") + 1)).then((response) => {
            setCompanies(response.data);
        })
    }, [])


    useEffect(() => {
    }, [errorMessage])

    return (
        <PopUp>
            <PopUpHeader headerText={"Setup"} />
            <PopUpText bodyText={"Please select your organization"} />
            <span className={styles.content}>
                <Input inputType={InputType.dropDown} className={styles.dropDown} options={companies.map((company) => company.name)} onChange={(e) => setSelectedCompany(e.target.value)} />
                {errorMessage && <span className={styles.error}>{errorMessage}</span>}
            </span>
            <PopUpButtons hasBtn1 textBtn1={"CREATE NEW"} onClickBtn1={() => { setPage(2) }} hasBtn2 textBtn2={"NEXT"} onClickBtn2={() => { if (selectedCompany) { setCompany(companies.filter((company) => company.name === selectedCompany)[0]); setPage(1); } else { setErrorMessage("Select an organization before continuing") } }} variantBtn2={ButtonColor.lifeAquatic} />
        </PopUp>
    )
}

export default ChooseCompanyPopup