// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LocationTitle_title__oFg5w {\n  font-family: \"Quatro\";\n  font-style: normal;\n  font-weight: 900;\n  font-size: 10px;\n  line-height: 91%;\n  letter-spacing: 0.015em;\n  text-transform: uppercase;\n  color: #262626;\n  padding-right: 5px;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  overflow: hidden;\n  max-width: 210px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/ui/atoms/locationTitle/LocationTitle.module.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,gBAAgB;EAChB,uBAAuB;EACvB,yBAAyB;EACzB,cAAc;EACd,kBAAkB;EAClB,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB;EAChB,gBAAgB;AAClB","sourcesContent":[".title {\n  font-family: \"Quatro\";\n  font-style: normal;\n  font-weight: 900;\n  font-size: 10px;\n  line-height: 91%;\n  letter-spacing: 0.015em;\n  text-transform: uppercase;\n  color: #262626;\n  padding-right: 5px;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  overflow: hidden;\n  max-width: 210px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "LocationTitle_title__oFg5w"
};
export default ___CSS_LOADER_EXPORT___;
