// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EditDisplayForm_dropDown__JBstV div {\n    height: auto;\n    border: 1px solid #CCCCCC;\n}\n\n.EditDisplayForm_checkBox__yVZyn {\n    width: 280px;\n}\n\n.EditDisplayForm_connectCode__uxCK3 {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n}\n\n.EditDisplayForm_connectCodeWrapper__9xxwW {\n    display: flex;\n    align-items: center;\n}\n\n.EditDisplayForm_copyIcon__nsy9l {\n    cursor: pointer;\n    z-index: 2;\n    width: 100%;\n    position: absolute;\n    float: right;\n}", "",{"version":3,"sources":["webpack://./src/components/ui/organisms/editDisplayForm/EditDisplayForm.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,yBAAyB;AAC7B;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;AAClC;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,UAAU;IACV,WAAW;IACX,kBAAkB;IAClB,YAAY;AAChB","sourcesContent":[".dropDown div {\n    height: auto;\n    border: 1px solid #CCCCCC;\n}\n\n.checkBox {\n    width: 280px;\n}\n\n.connectCode {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n}\n\n.connectCodeWrapper {\n    display: flex;\n    align-items: center;\n}\n\n.copyIcon {\n    cursor: pointer;\n    z-index: 2;\n    width: 100%;\n    position: absolute;\n    float: right;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropDown": "EditDisplayForm_dropDown__JBstV",
	"checkBox": "EditDisplayForm_checkBox__yVZyn",
	"connectCode": "EditDisplayForm_connectCode__uxCK3",
	"connectCodeWrapper": "EditDisplayForm_connectCodeWrapper__9xxwW",
	"copyIcon": "EditDisplayForm_copyIcon__nsy9l"
};
export default ___CSS_LOADER_EXPORT___;
