import { useEffect, useState } from 'react';
import styles from './FirmwareUpdateBullet.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DisplayService } from '../../../../services/DisplayService';
import { useWebSocket } from '../../../../hooks/UseWebSocket';
import DisplayInfoPopUp from '../../atoms/displayInfoPopUp/DisplayInfoPopUp';

const getTimeFormat = (date) => {
    date.setTime(date.getTime() - (60 * 60 * 1000))
    const year = date.getFullYear();
    const day = ("0" + date.getDate()).slice(-2);
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const hour = (`0${date.getHours()}`.slice(-2))
    const minute = (`0${date.getMinutes()}`.slice(-2))
  
    return `${year}-${month}-${day}T${hour}:${minute}`;
  }

const getTimeDifference = (t1, t2) => {
    const startTime = t1;
    const endTime = t2
    const diff = endTime - startTime
    const seconds = 1000, minutes = 60 * seconds;

    const min = Math.ceil(diff / minutes);
    return min
}

const FirmwareUpdateBullet = ({ serial, newFirmware, firmware, isUpdating, getCompany, isOnline, className }) => {
    const { update, joinRoom } = useWebSocket();
    const [updateState, setUpdateState] = useState()
    const [timeRemaining, setTimeRemaining] = useState(25)
    const [isCellHovered, setIsCellHovered] = useState(false)
    const [startedTime, setStartedTime] = useState()
    const [currentFirmware, setCurrentFirmware] = useState(firmware)
    const [showOfflinePopup, setShowOfflinePopup] = useState(false)
    const delay = ms => new Promise(resolve => setTimeout(resolve, ms))
    useEffect(() => {
        isUpdating ? setUpdateState("active") : setUpdateState("start");
    }, [isUpdating])
    useEffect(() => {
        if (showOfflinePopup) {
            delay(5000).then(() => {
                setShowOfflinePopup(false)
            })
        }
    }, [showOfflinePopup])
    useEffect(() => {
        let timeDifference = 25;
        if (updateState === "active") {
            delay(1000).then(() => {
                DisplayService.getUpdateFirmwareData(serial).then(response => {
                    if (response.code === 200) {
                        const startDate = getTimeFormat( new Date(response.result.start_time))
                        const endDate = getTimeFormat( new Date(response.result.end_time))
                        const currentDate = new Date()
                        setStartedTime(startDate)
                        timeDifference = getTimeDifference(currentDate, new Date(endDate));
                        setCurrentFirmware(response.result.currentFirmware)
                        setTimeRemaining(prev=> prev = timeDifference)
                        DisplayService.checkIsLatestVersion(serial).then(response => {
                            if (response.result === 1) {
                                setUpdateState("success")
                            } else if (response.result === 0 && timeDifference <= 0) {
                                setUpdateState("failed")
                            }
                        })
                        let timeRemainingCheck = timeDifference
                        for (let i = 0, p = Promise.resolve(); i <= timeDifference; i++) {
                            p = p.then(() => delay(60 * 1000))
                                .then(() => {
                                    DisplayService.checkIsLatestVersion(serial).then(response => {
                                        if (response.result === 1) {
                                            setUpdateState("success")
                                        } else if (response.result === 0 && timeRemainingCheck === 0) {
                                            setUpdateState("failed")
                                        }
                                    })
                                    setTimeRemaining(prev => {prev = prev - 1; timeRemainingCheck = prev; return prev})
                                })
                        }
                    }
                })

            })

        }
        if (updateState === "failed" || updateState === "success") {
            DisplayService.deleteUpdateFirmwareData({ serial: serial })
        }
    }, [updateState])
    const updateFirmware = () => {
        joinRoom(serial);
        const currentTime = new Date();
        const endTime = new Date();
        endTime.setTime(currentTime.getTime() + (25 * 60 * 1000))
        let screenInfo = {
            serial: serial,
            currentFirmware: firmware,
            newFirmware: newFirmware,
            startTime: getTimeFormat(currentTime),
            endTime: getTimeFormat(endTime)
        }
        DisplayService.saveUpdateFirmwareData(screenInfo).then(response => {
            if (response.code === 200) {
                update(serial)
            }
        })
        const timeDifference = getTimeDifference(currentTime, endTime)
        setCurrentFirmware(firmware)
        setTimeRemaining(timeDifference)
        setStartedTime(currentTime);
        setUpdateState("active")
    }

    const convertTimeString = (timeString) => {
        return timeString.replaceAll("-", "/").replaceAll("T", " ")
    }

    const firmwareUpdateVersion = (version) => {
        switch (version) {
            case "start":
                return (
                    <span onMouseEnter={() => setIsCellHovered(true)} onMouseLeave={() => setIsCellHovered(false)} onClick={(e) => { if (isOnline) { e.stopPropagation(); updateFirmware(); } else { e.stopPropagation(); setShowOfflinePopup(true) } }} className={styles.sphereUpdate}>
                        <>
                            {showOfflinePopup && <DisplayInfoPopUp onClick={() => setShowOfflinePopup(false)} className={styles.popup}>{"This display is offline, turn it on to continue"}</DisplayInfoPopUp>}
                            <FontAwesomeIcon className={styles.sphereUpdateIcon} icon={["fas", "fa-arrow-up"]} />
                        </>
                        {(isCellHovered && isOnline) &&
                            <DisplayInfoPopUp className={styles.popup}>
                                <span className={[styles.updateContent, styles.start].filter(e => !!e).join(" ")}>Update available</span>
                                <span className={styles.updateContent}>Update from <span className={[styles.updateContent, styles.important].filter(e => !!e).join(" ")}>{currentFirmware}</span><br /> to <span className={[styles.updateContent, styles.important].filter(e => !!e).join(" ")}>{newFirmware}</span></span>
                            </DisplayInfoPopUp>
                        }
                    </span>
                )
            case "active":
                return (
                    <span onMouseEnter={() => setIsCellHovered(true)} onMouseLeave={() => setIsCellHovered(false)} className={[styles.sphereUpdate, styles.active].filter(e => !!e).join(" ")}>
                        <FontAwesomeIcon className={styles.sphereUpdateIcon} icon={["fas", "spinner"]} />
                        {isCellHovered &&
                            <DisplayInfoPopUp className={styles.popup}>
                                <span className={styles.updateContent}>Update started at <span className={[styles.updateContent, styles.important].filter(e => !!e).join(" ")}>{convertTimeString(startedTime?.toLocaleString("nl-NL", { dateStyle: "short", timeStyle: "short" }))}</span></span>
                                <span className={styles.updateContent}>Estimated time<br /> <span className={[styles.updateContent, styles.important].filter(e => !!e).join(" ")}>{timeRemaining} minutes</span></span>
                            </DisplayInfoPopUp>
                        }
                    </span>
                )
            case "success":
                return (
                    <span onMouseEnter={() => setIsCellHovered(true)} onMouseLeave={() => setIsCellHovered(false)} onClick={(e) => { e.stopPropagation(); getCompany() }} className={[styles.sphereUpdate, styles.success].filter(e => !!e).join(" ")}>
                        <FontAwesomeIcon className={styles.sphereUpdateIcon} icon={["fas", "check"]} />
                        {isCellHovered &&
                            <DisplayInfoPopUp className={styles.popup}>
                                <span className={[styles.updateContent, styles.success].filter(e => !!e).join(" ")}>Update successful</span>
                                <span className={styles.updateContent}>Updated from <span className={[styles.updateContent, styles.important].filter(e => !!e).join(" ")}>{currentFirmware}</span> To <span className={[styles.updateContent, styles.important].filter(e => !!e).join(" ")}>{newFirmware}</span></span>
                            </DisplayInfoPopUp>
                        }
                    </span>
                )
            case "failed":
                return (
                    <span onMouseEnter={() => setIsCellHovered(true)} onMouseLeave={() => setIsCellHovered(false)} onClick={(e) => { e.stopPropagation(); setUpdateState("start") }} className={[styles.sphereUpdate, styles.failed].filter(e => !!e).join(" ")}>
                        <FontAwesomeIcon className={styles.sphereUpdateIcon} icon={["fal", "circle-exclamation"]} />
                        {isCellHovered &&
                            <DisplayInfoPopUp className={styles.popup}>
                                <span className={[styles.updateContent, styles.failed].filter(e => !!e).join(" ")}>Status Unknown</span>
                                <span className={styles.updateContent}>The update might not have succeeded. Please check your display or try again</span>
                            </DisplayInfoPopUp>
                        }
                    </span>
                )
            default:
                return (
                    <span onMouseEnter={() => setIsCellHovered(true)} onMouseLeave={() => setIsCellHovered(false)} onClick={(e) => { e.stopPropagation(); updateFirmware(); }} className={styles.sphereUpdate}>
                        <FontAwesomeIcon className={styles.sphereUpdateIcon} icon={["fas", "fa-arrow-up"]} />
                        {isCellHovered &&
                            <DisplayInfoPopUp className={styles.popup}>
                                <span className={styles.updateAvailable}>Update successfull</span>
                                <span className={styles.updateContent}>Update from <span className={styles.updateContentImportant}>{currentFirmware}</span> To <span className={styles.updateContentImportant}>{newFirmware}</span></span>
                            </DisplayInfoPopUp>
                        }
                    </span>
                )
        }

    }

    return (
        firmwareUpdateVersion(updateState)
    )
}

export default FirmwareUpdateBullet;