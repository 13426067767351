import styles from './AppListText.module.css';

const AppListText =({titleText, descriptionText, className}) => {
  return (
    <div className={[styles.textWrapper, className].filter(e=>!!e).join(" ")}>
      <span className={styles.titleText}>{titleText}</span>
      {descriptionText && <span className={styles.descriptionText}>{descriptionText}</span>}
    </div>
  )
}

export default AppListText;