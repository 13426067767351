// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Location_location__cVGM- {\n    padding: 8px 27px 0 27px;\n    display: flex;\n    height: 46px;\n    width: 100%;\n    box-sizing: border-box;\n    align-items: center;\n    justify-content: space-between;\n    background: #F0F1F3;\n}\n\n.Location_locationButton__nN\\+rT {\n    height: 13px;\n    display: flex;\n    align-items: center;\n}\n\n.Location_flexContainer__ln\\+BE {\n    display: flex;\n    align-items: center;\n    gap: 11px;\n}", "",{"version":3,"sources":["webpack://./src/components/ui/molecules/location/Location.module.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;IACxB,aAAa;IACb,YAAY;IACZ,WAAW;IACX,sBAAsB;IACtB,mBAAmB;IACnB,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;AACb","sourcesContent":[".location {\n    padding: 8px 27px 0 27px;\n    display: flex;\n    height: 46px;\n    width: 100%;\n    box-sizing: border-box;\n    align-items: center;\n    justify-content: space-between;\n    background: #F0F1F3;\n}\n\n.locationButton {\n    height: 13px;\n    display: flex;\n    align-items: center;\n}\n\n.flexContainer {\n    display: flex;\n    align-items: center;\n    gap: 11px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"location": "Location_location__cVGM-",
	"locationButton": "Location_locationButton__nN+rT",
	"flexContainer": "Location_flexContainer__ln+BE"
};
export default ___CSS_LOADER_EXPORT___;
