import styles from './TableHeaderCell.module.css';
import { useState, useRef } from 'react';
import FilterMenu from '../filterMenu/FilterMenu';
import useOnClickOutside from './../../../../hooks/UseOnClickOutside';
import FilterIcon from '../../atoms/filterIcon/FilterIcon';

const TableHeaderCell = ({ title, hasFilter, className, items, headerType, currentFilters, updateActiveFilters, setSort }) => {
    const ref = useRef();
    useOnClickOutside(ref, () => setIsOpen(false));
    const [isOpen, setIsOpen] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [activeFilters, setActiveFilters] = useState([]);

    // const changeActiveFilters = (e, inputType) => {
    //     if (inputType === 'check') {
    //         e.target.checked ? activeFilters.push({ value: e.target.value, inputType: inputType })
    //             : activeFilters.splice(activeFilters.indexOf({ value: e.target.value, inputType: inputType }), 1)
    //     }
    //     else {
    //         if (activeFilters.length > 0) {
    //             if (e.target.value.length === 0) {
    //                 activeFilters.splice({ value: e.target.value, inputType: inputType })
    //             } else {
    //                 activeFilters[0] = { value: e.target.value, inputType: inputType }
    //             }
    //         }
    //         else {
    //             activeFilters.push({ value: e.target.value, inputType: inputType });
    //         }
    //     }
    //     setActiveFilters(activeFilters);
    //     updateActiveFilters && updateActiveFilters(title, activeFilters)
    // }

    return (
        <>
            <div className={[styles.container, className].filter(e => !!e).join(' ')}>
            {hasFilter && <FilterMenu className={!isOpen && styles.hidden} headerType={headerType} currentFilters={currentFilters} innerRef={ref} isSorted={(state) => { setSort(state, title); setIsActive(state < 2) }} items={items} onFilterIconChange={(active) => setIsActive(active)} />}
                <span className={styles.title}>
                    {title.replace(/([A-Z])/g, " $1")}
                </span>
                {hasFilter && <FilterIcon active={isActive} onClick={() => { setIsOpen(!isOpen)}} />}
            </div>
        </>

    );
}

TableHeaderCell.defaultProps = {
    hasFilter: true
}

export default TableHeaderCell;