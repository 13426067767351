import { PopUpHeader, PopUpText, PopUpButtons, Modal } from '@ctouch-europe-b-v/react-component-library';


function WarningModal({headerText, bodyText, closeCancelWarning, innerRef, onClickBtn2, onClickBtn1, textBtn1, textBtn2}){
    return (
        <Modal innerRef={innerRef}>
            <PopUpHeader headerText={headerText} />
            <PopUpText hasHeader bodyText={bodyText} />
            <PopUpButtons hasBtn1 hasBtn2 textBtn1={textBtn1} textBtn2={textBtn2} onClickBtn1={onClickBtn1} onClickBtn2={onClickBtn2} />
        </Modal>
    )
}

export default WarningModal;