// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AppListRow_container__\\+GOgM {\n  box-sizing: border-box;\n  border-bottom: 1px solid #00000010;\n  display: flex;\n  flex-direction: row;\n  /* justify-content: space-between; */\n  align-content: center;\n  flex-shrink:1;\n  padding: 8px 32px;\n  height: 46px;\n  gap: 0 12px;\n}", "",{"version":3,"sources":["webpack://./src/components/ui/atoms/appListRow/AppListRow.module.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,kCAAkC;EAClC,aAAa;EACb,mBAAmB;EACnB,oCAAoC;EACpC,qBAAqB;EACrB,aAAa;EACb,iBAAiB;EACjB,YAAY;EACZ,WAAW;AACb","sourcesContent":[".container {\n  box-sizing: border-box;\n  border-bottom: 1px solid #00000010;\n  display: flex;\n  flex-direction: row;\n  /* justify-content: space-between; */\n  align-content: center;\n  flex-shrink:1;\n  padding: 8px 32px;\n  height: 46px;\n  gap: 0 12px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "AppListRow_container__+GOgM"
};
export default ___CSS_LOADER_EXPORT___;
