import styles from "./Tab.module.css"
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fal } from "@fortawesome/pro-light-svg-icons";
import { useState } from "react";
library.add(fal);

const Tab = ({ icon, title, onClick, isSelected }) => {

    return (
        <div className={[styles.container, isSelected && styles.selected].filter(e => !!e).join(' ')} onClick={() => onClick && onClick()}>
            <FontAwesomeIcon className={styles.icon} icon={["fal", icon]} />
            <p className={styles.title}>{title}</p>
        </div>
    );
}

export default Tab