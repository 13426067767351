import styles from "./ResentMailPopUp.module.css"

import { PopUpHeader, PopUpText, PopUpButtons, ButtonColor, PopUp } from '@ctouch-europe-b-v/react-component-library';

function ResentMailPopUp({}){
    return (
        <PopUp>
            <PopUpHeader headerText={"Resent"} />
            <PopUpText bodyText={"Another email has been sent. Please check it is not in your Spam email folder. If you are still having trouble please contact CTOUCH service."} />
            <a className={styles.link} target="_blank" href="https://support.ctouch.eu"><PopUpText hasIcon bodyText={"https://support.ctouch.eu"} /></a>
            <PopUpButtons hasBtn2 textBtn2={"CLOSE"} variantBtn2={ButtonColor.lifeAquatic} />
        </PopUp>
    )
}

export default ResentMailPopUp