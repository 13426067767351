import LocationTitle from "../../atoms/locationTitle/LocationTitle";
import styles from "./Location.module.css"
import ChevronButton from "../../atoms/chevronButton/ChevronButton";
import { Checkbox, InputColor } from '@ctouch-europe-b-v/react-component-library';

function Location({ isClosed, className, locationTitle, isMultiSelectActive, checked, setChecked, onClick, isFirmwareUpgradeActive }) {
    return (
        <div className={[styles.location, className].filter(e => !!e).join(' ')}>
            <div className={styles.flexContainer}>
                {(isMultiSelectActive !== undefined || isFirmwareUpgradeActive !== undefined) && (isMultiSelectActive || isFirmwareUpgradeActive) &&
                    <div onClick={(e) => e.stopPropagation()}>
                        <Checkbox onChange={(e) => { setChecked(e.target.checked) }} inputColor={InputColor.blue} checked={checked} />
                    </div>}
                <LocationTitle location={locationTitle} />
            </div>
            <ChevronButton isClosed={isClosed} className={styles.locationButton} onClick={onClick} />
        </div>
    )
}

export default Location;