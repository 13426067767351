// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ChartsContentContainer_header__2F3mo {\n  font-family: 'Quatro';\n  font-size: 17px;\n  font-style: normal;\n  font-weight: 900;\n  line-height: 17px;\n  letter-spacing: 0.51px;\n  text-transform: uppercase;\n}\n.ChartsContentContainer_headerIcon__cV63z {\n  padding-right: 2px;\n}\n.ChartsContentContainer_content__lQmWy {\n  width: calc(95vw - 345px);\n  padding-top: 24px;\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap; \n  /* grid-template-columns: auto 1fr;\n  grid-auto-flow: dense; */\n  gap: 24px;\n}", "",{"version":3,"sources":["webpack://./src/components/ui/atoms/chartsContentContainer/ChartsContentContainer.module.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,sBAAsB;EACtB,yBAAyB;AAC3B;AACA;EACE,kBAAkB;AACpB;AACA;EACE,yBAAyB;EACzB,iBAAiB;EACjB,aAAa;EACb,mBAAmB;EACnB,eAAe;EACf;0BACwB;EACxB,SAAS;AACX","sourcesContent":[".header {\n  font-family: 'Quatro';\n  font-size: 17px;\n  font-style: normal;\n  font-weight: 900;\n  line-height: 17px;\n  letter-spacing: 0.51px;\n  text-transform: uppercase;\n}\n.headerIcon {\n  padding-right: 2px;\n}\n.content {\n  width: calc(95vw - 345px);\n  padding-top: 24px;\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap; \n  /* grid-template-columns: auto 1fr;\n  grid-auto-flow: dense; */\n  gap: 24px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "ChartsContentContainer_header__2F3mo",
	"headerIcon": "ChartsContentContainer_headerIcon__cV63z",
	"content": "ChartsContentContainer_content__lQmWy"
};
export default ___CSS_LOADER_EXPORT___;
