// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Sidebar_sidebar__I4Ivs {\n  width: 58px;\n  height: 100vh;\n  background: #262626;\n  transition: 0.1s ease-in-out;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  overflow: hidden;\n  position: fixed;\n  z-index: 3;\n}\n\n.Sidebar_link__759Ia {\n  text-decoration: none;\n}\n\n.Sidebar_sidebar__I4Ivs:hover {\n  width: 160px;\n}\n\n.Sidebar_section__Sfnyk {\n  text-decoration: none;\n  list-style-type: none;\n  margin: 0;\n  padding: 0;\n}\n\n.Sidebar_sphereLogo__FlWNW {\n  display: flex;\n  height: 50px;\n  padding-left: 6px\n}\n\n.Sidebar_active__UAhYa {\n  text-decoration: none;\n}\n\n.Sidebar_active__UAhYa li {\n  background: #009FDA;\n}", "",{"version":3,"sources":["webpack://./src/components/ui/molecules/sidebar/Sidebar.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;EAEnB,4BAA4B;EAC5B,aAAa;EACb,sBAAsB;EACtB,8BAA8B;EAC9B,gBAAgB;EAChB,eAAe;EACf,UAAU;AACZ;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,qBAAqB;EACrB,qBAAqB;EACrB,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,aAAa;EACb,YAAY;EACZ;AACF;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".sidebar {\n  width: 58px;\n  height: 100vh;\n  background: #262626;\n  -webkit-transition: 0.1s ease-in-out;\n  transition: 0.1s ease-in-out;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  overflow: hidden;\n  position: fixed;\n  z-index: 3;\n}\n\n.link {\n  text-decoration: none;\n}\n\n.sidebar:hover {\n  width: 160px;\n}\n\n.section {\n  text-decoration: none;\n  list-style-type: none;\n  margin: 0;\n  padding: 0;\n}\n\n.sphereLogo {\n  display: flex;\n  height: 50px;\n  padding-left: 6px\n}\n\n.active {\n  text-decoration: none;\n}\n\n.active li {\n  background: #009FDA;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sidebar": "Sidebar_sidebar__I4Ivs",
	"link": "Sidebar_link__759Ia",
	"section": "Sidebar_section__Sfnyk",
	"sphereLogo": "Sidebar_sphereLogo__FlWNW",
	"active": "Sidebar_active__UAhYa"
};
export default ___CSS_LOADER_EXPORT___;
