import styles from "./AreYouAdminPopup.module.css"
import { PopUpHeader, PopUpText, PopUpButtons, ButtonColor, PopUp } from '@ctouch-europe-b-v/react-component-library';
import { useEffect } from "react";

function AreYouAdminPopup({ setPage, setCompanyId }) {
    useEffect(() => {

    }, [])

    return (
        <PopUp>
            <PopUpHeader headerText={"Create new"} />
            <PopUpText bodyText={"You are about to create a new organization. Are you the system admin for the CTOUCH displays in your organization?"} />
            <PopUpText bodyText={"I am:"} />
            <PopUpButtons hasBtn1 textBtn1={"AN ADMIN"} onClickBtn1={() => { setCompanyId(null); setPage(1) }} hasBtn2 textBtn2={"NOT AN ADMIN"} onClickBtn2={() => { setCompanyId(null); setPage(2) }} variantBtn2={ButtonColor.lifeAquatic} />
        </PopUp>
    )
}

export default AreYouAdminPopup