import LandingPage from './components/pages/landingPage/LandingPage';
import SelectCustomer from './components/pages/selectCustomer/SelectCustomer';
import DisplayOverview from './components/pages/displayOverview/DisplayOverview'
import DisplaySettings from './components/pages/displaySettings/DisplaySettings'
import AccountSettings from './components/pages/accountSettings/AccountSettings';
import ChartsPage from './components/pages/chartsPage/ChartsPage'
import ScreenLogs from './components/pages/screenLogs/ScreenLogs';
import { Routes, Route, Navigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import Roles from './enums/Roles';
import Registration from './components/pages/registration/Registration';
import { useEffect, useState } from 'react';
import { RoleService } from './services/RoleService';
import { CustomerService } from './services/CustomerService';
import { WebSocketProvider } from "./hooks/UseWebSocket";
import { default as routes } from './enums/Routes';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import * as React from 'react';
import { LocationContext, FilterContext } from './contexts/LocationContext';
import LoadingPage from './components/pages/loadingPage/LoadingPage';

const initialFilters = [{ 'connectionStatus': [] }, { 'serialNumber': [] }, { 'firmware': [] }, { 'sphereApp': [] }, { 'subscription': [] }, { 'model': [] }, { 'location_name': [] }]
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const currentDateToISO = () => {
  const currentDate = new Date();
  const isoDate = currentDate.toISOString();

  return isoDate;
}

const addDateToISO = (year) => {
  const currentDate = new Date();
  currentDate.setFullYear(currentDate.getFullYear() + year);
  const isoDate = currentDate.toISOString();

  return isoDate;
}

function App() {
  const [sphereUser, setSphereUser] = useState(null);
  const [currentCompany, setCurrentCompany] = useState();
  const [mainCompany, setMainCompany] = useState();
  const { logout, user, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const [displays, setDisplays] = useState([]);
  const [companySub, setCompanySub] = useState("FIT");
  const [errorMessage, setErrorMessage] = useState("");
  const [showSnackbar, setShowSnackbar] = useState(false)
  const [locationList, setLocationList] = useState(null)
  const [globalFilterList, setGlobalFilterList] = useState(initialFilters)
  const [dataFetched, setDataFetched] = useState(false)
  const [newUser, setNewUser] = useState(false)

  const handleErrorMessage = (message) => {
    setErrorMessage(message)
    setShowSnackbar(true)
  }

  useEffect(() => {
    const delay = ms => new Promise(r => setTimeout(r, ms))
    const dataFetch = async () => {
      setDataFetched(false)
      let aNewUser = false;
      let newUser = await RoleService.getRole(user).catch(e => {
        if (e.response.data.error.message === "Request failed with status code 400") {
          setNewUser(true)
          aNewUser = true;
        }
      });
      if (newUser?.company_id === null || newUser?.user_status === null) {
        setNewUser(true)
        aNewUser = true;
      }
      if (aNewUser === false) {
        let roleFetched = 0;
        const roleData = {
          userEmail: "email",
          roleName: "roleName",
          authorization: {
            grantedByEmail: "authorization@ctouch.eu",
            startDate: currentDateToISO(),
            endDate: addDateToISO(2)
          }
        }
        if (newUser.roles === undefined || newUser.roles.length === 0 || !newUser.roles.some(role => role.Name === "User")) {
          roleData.userEmail = user.email;
          roleData.roleName = "User"
          await RoleService.createRole(roleData)
          roleFetched = 1
        }

        if (newUser.is_admin === 1 && !newUser.roles.some(role => role.Name === "Administrator")) {
          roleData.userEmail = user.email;
          roleData.roleName = "Administrator"
          await RoleService.createRole(roleData)
          roleFetched = 1
        }
        if (roleFetched === 1) {
          await delay(3000);
          newUser = await RoleService.getRole(newUser);
        }
        setSphereUser(newUser);
        setCurrentCompany(newUser.company_id)
        setMainCompany(newUser.company_id)
        if (newUser.company_id != null) {
          CustomerService.getOne(newUser.company_id).then((response) => {
            response.data.locations.map((location) => {
              location.displays.map((display) => {
                displays.push(display)
              })
            })
            displays.map((display) => {
              if (display.subscription === "SAFE") {
                setCompanySub("SAFE")
              }

            })
            setDataFetched(true)
          })
        }
      } else {
        setDataFetched(true)
      }
    }

    const checkAuth = async () => {
      try {
        const token = await getAccessTokenSilently({ cacheMode: 'on' });
      } catch (err) {
        if (err.error === "login_required") {
          return <Navigate path={routes.landing} />
        }
        else if (err.error === 'consent_required') {
          logout({ returnTo: window.location.origin })
        }
      }
    }

    if (isAuthenticated && !isLoading) {
      checkAuth()
      dataFetch()
    }


  }, [isAuthenticated, user])
  return (
    ((dataFetched || !isAuthenticated) && !isLoading) ?
      <WebSocketProvider handleErrorMessage={handleErrorMessage}>
        <>

          <Routes>
            {!sphereUser?.verified_by_admin || sphereUser?.verified_by_admin !== 1
              ? <Route path="*" element={isLoading ? "" : isAuthenticated && newUser ? <Registration user={user} /> : <LandingPage />} />
              :
              <>
                <Route path={routes.landing} element={isLoading ? "" : isAuthenticated ? (sphereUser.roles.some(role => role.Name === Roles.ctouch || role.Name === Roles.reseller)) ? <Navigate to='/customer' /> : <Navigate to='/displayOverview' /> : <LandingPage />} />
                <Route path={routes.customerOverview} element={isLoading ? "" : isAuthenticated ? (sphereUser.roles.some(role => role.Name === Roles.ctouch || role.Name === Roles.reseller)) ? <SelectCustomer companySub={companySub} role={sphereUser} updateCurrentCompany={(id) => { setCurrentCompany(id); setGlobalFilterList(initialFilters) }} /> : <FilterContext.Provider value={{ globalFilterList, setGlobalFilterList }}><LocationContext.Provider value={{ locationList, setLocationList }}><DisplayOverview role={sphereUser} currentCompany={currentCompany !== null && currentCompany} /> </LocationContext.Provider></FilterContext.Provider> : <LandingPage />} />
                <Route path={routes.displayOverview} element={isLoading ? "" : isAuthenticated ? <FilterContext.Provider value={{ globalFilterList, setGlobalFilterList }}><LocationContext.Provider value={{ locationList, setLocationList }}><DisplayOverview role={sphereUser} companySub={companySub} currentCompany={currentCompany !== null && currentCompany} /> </LocationContext.Provider></FilterContext.Provider> : <LandingPage />} />
                <Route path={routes.displaySettings} element={isLoading ? "" : isAuthenticated ? <FilterContext.Provider value={{ globalFilterList, setGlobalFilterList }}><LocationContext.Provider value={{ locationList, setLocationList }}><DisplaySettings errorMessage={errorMessage} companySub={companySub} role={sphereUser} /></LocationContext.Provider></FilterContext.Provider> : <LandingPage />} />
                <Route path={routes.dashboard} element={isLoading ? "" : isAuthenticated ? <ChartsPage role={sphereUser} companySub={companySub} company={currentCompany !== null && currentCompany} /> : <LandingPage />} />
                <Route path={routes.accountSettings} element={isLoading ? "" : isAuthenticated ? <AccountSettings user={user} companySub={companySub} role={sphereUser} company={mainCompany !== null && mainCompany} /> : <LandingPage />} />
                <Route path={routes.screenLogs} element={isLoading ? "" : isAuthenticated && sphereUser.is_admin === 1 ? <ScreenLogs role={sphereUser} companySub={companySub} /> : <LandingPage />} />
                <Route path="*" element="404 page not found" />
              </>
            }
          </Routes>

          <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={showSnackbar} autoHideDuration={5000} onClose={() => { setShowSnackbar(false) }} message={errorMessage}>
            <Alert severity="error">{errorMessage}</Alert>
          </Snackbar>

        </>
      </WebSocketProvider>
      :
      <WebSocketProvider handleErrorMessage={handleErrorMessage}>
        <LoadingPage />
      </WebSocketProvider>
  );
}

export default App;
