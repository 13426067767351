// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AppIconContainer_container__Wy\\+tk {\n  width: 30px;\n  height: 30px;\n  flex-shrink: 0;\n  border-radius: 6px;\n  background-color: #262626;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n.AppIconContainer_noBackground__1HTEM {\n  background-color: transparent;\n}", "",{"version":3,"sources":["webpack://./src/components/ui/atoms/appIconContainer/AppIconContainer.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,cAAc;EACd,kBAAkB;EAClB,yBAAyB;EACzB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;AACA;EACE,6BAA6B;AAC/B","sourcesContent":[".container {\n  width: 30px;\n  height: 30px;\n  flex-shrink: 0;\n  border-radius: 6px;\n  background-color: #262626;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n.noBackground {\n  background-color: transparent;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "AppIconContainer_container__Wy+tk",
	"noBackground": "AppIconContainer_noBackground__1HTEM"
};
export default ___CSS_LOADER_EXPORT___;
