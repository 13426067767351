import { useState, useRef, useEffect } from "react";
import DisplayContextButton from "../../atoms/displayContextButton/DisplayContextButton";

import ContextMenu from "../../molecules/contextMenu/ContextMenu";
import DisplayInformation from "../../molecules/displayInformation/DisplayInformation";
import styles from "./Display.module.css"
import useOnClickOutside from "../../../../hooks/UseOnClickOutside"
import { Checkbox, Modal, inputColor, InputColor } from '@ctouch-europe-b-v/react-component-library';
import WarningModal from './../warningModal/WarningModal';
import EditDisplayForm from "../editDisplayForm/EditDisplayForm";
import { DisplayService } from "../../../../services/DisplayService";
import StatusBullet from "../../atoms/statusBullet/StatusBullet";
import SaveSetupForm from "../saveSetupForm/SaveSetupForm";
import RestoreSetupForm from "../restoreSetupForm/RestoreSetupForm";

function Display({ isMultiSelectActive, style, isSelected, isHovered, className, display, locations, displayLocation, onSelectedChange, hasStatus, onClick, companyId, handleDisplayChanges, role, companySub, setUpdateState, checkAll, locationCheck, locationChecked, isShowPopup, isContextMenuVisible, company }) {
    const [isContextClosed, setIsContextClosed] = useState(true)
    const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [isSaveSetupModalOpen, setIsSaveSetupModalOpen] = useState(false);
    const [isRestoreSetupModalOpen, setIsRestoreSetupModalOpen] = useState(false);
    const [isCellHovered, setIsCellHovered] = useState(isHovered)
    const [showPopup, setShowPopup] = useState(false);
    const [popupText, setPopupText] = useState("")
    const ref = useRef();
    const removeRef = useRef();
    const editRef = useRef();
    const saveSetupRef = useRef();
    const restoreSetupRef = useRef();
    const [checked, setChecked] = useState(false);

    useEffect(() => {
        isMultiSelectActive === true && checkAll ? selectAll() : setChecked(false);
    }, [checkAll, isMultiSelectActive])

    useEffect(() => {
        (isMultiSelectActive === true && locationChecked) ? setChecked(true) : setChecked(false);
    }, [locationChecked, isMultiSelectActive])

    const selectAll = () => {
        setChecked(checkAll);
        onSelectedChange(checkAll);
    }

    useEffect(() => {
        if (isShowPopup) {
            handlePopUp()
        }
    }, [isShowPopup])


    useOnClickOutside(ref, () => setIsContextClosed(true));
    useOnClickOutside(removeRef, () => setIsRemoveModalOpen(false));
    useOnClickOutside(editRef, () => setIsEditModalOpen(false));
    useOnClickOutside(saveSetupRef, () => setIsSaveSetupModalOpen(false));
    useOnClickOutside(restoreSetupRef, () => setIsRestoreSetupModalOpen(false));
    const delay = ms => new Promise(res => setTimeout(res, ms));

    function handleContextClosedChange(e) {
        e.stopPropagation()
        setIsContextClosed(false);
    }

    useEffect(() => {
        // console.log(isMultiSelectActive)
        switch (isMultiSelectActive) {
            case "Fit":
                setPopupText("Multiselect is not possible with FIT displays")
                break;
            case "Subscription":
                setPopupText("This display is already using Heartbeat SAFE")
                break;
            case "Multiselect":
                setPopupText("Multiselect is not possible with FIT displays")
                break;
            case "Offline":
                setPopupText("This display is offline, turn it on to continue")
                break;
            case "Riva":
                setPopupText("Please contact sales to upgrade Riva 1 to Heartbeat SAFE")
                break;
            case "Firmware":
                setPopupText("This display is already up to date")
                break
            default:
                break;
        }
    }, [isMultiSelectActive])
    const handlePopUp = async () => {
        setShowPopup(true);
        setPopupText("This display is offline, turn it on to continue");
        await delay(5000);
        setShowPopup(false);
    }
    const handleOnClick = async () => {
        console.log("handle");
        console.log(isMultiSelectActive);
        if (!display.connectionStatus && !isMultiSelectActive) {
            handlePopUp()
        }
        onClick()
    }
    const removeDisplay = () => {
        DisplayService.delete(display).then(() => {
            setIsRemoveModalOpen(false);
            handleDisplayChanges();
        });
    }

    return (
        <span className={styles.displayWrapper} onClick={() => handleOnClick()} onMouseEnter={() => setIsCellHovered(true)} onMouseLeave={() => setIsCellHovered(false)} >
            <div style={style} className={[styles.display, className, isSelected && styles.selected].filter(e => !!e).join(' ')}>
                {!isContextClosed && <ContextMenu role={role} onClick={(e) => e.stopPropagation()} innerRef={ref} setUpdateState={setUpdateState} display={display} company={company} companySub={companySub} displays={displayLocation.displays} className={styles.contextMenu} onEditClick={() => setIsEditModalOpen(true)} onRemoveClick={() => setIsRemoveModalOpen(true)} onSaveSetupClick={() => setIsSaveSetupModalOpen(true)} onRestoreSetupClick={() => setIsRestoreSetupModalOpen(true)} />}
                <div className={styles.flexContainer}>
                    {isMultiSelectActive !== null &&
                        <div className={styles.customCheck} onClick={(e) => e.stopPropagation()}>
                            <Checkbox onClick={async () => { typeof (isMultiSelectActive) == 'string' && setShowPopup(true); await delay(5000); setShowPopup(false) }}
                                disabled={typeof (isMultiSelectActive) == 'string'}
                                onChange={(e) => { setChecked(e.target.checked); onSelectedChange(e.target.checked, display) }}
                                checked={checked} />
                        </div>}
                    <DisplayInformation popupText={popupText} showPopup={showPopup} isSelected={isSelected} displayName={display.name} displayModel={display.model} onPopupClick={(e) => { e.stopPropagation(); setShowPopup(false) }} />
                </div>
                <div className={styles.statusFlexContainer}>
                    {hasStatus === true && <StatusBullet isHovered={isCellHovered || isHovered} status={display.connectionStatus} className={styles.statusBullet} />}
                    {isContextMenuVisible && <DisplayContextButton isSelected={isSelected} handleContextClosedChange={handleContextClosedChange} />}
                </div>
            </div>
            {isRemoveModalOpen && <WarningModal onClick={(e) => e.stopPropagation()} innerRef={removeRef} headerText={"Remove display"} bodyText={"Are you sure you want to remove this display?"} textBtn1={"Cancel"} textBtn2={"Remove"} onClickBtn1={(e) => { e.stopPropagation(); setIsRemoveModalOpen(false) }} onClickBtn2={(e) => { e.stopPropagation(); removeDisplay() }} />}
            {isEditModalOpen && <Modal onClick={(e) => e.stopPropagation()} innerRef={editRef} grey> <EditDisplayForm companyId={companyId} display={display} locations={locations} displayLocation={displayLocation} closeEditDisplay={() => setIsEditModalOpen(false)} handleUpdated={handleDisplayChanges} /> </Modal>}
            {isSaveSetupModalOpen && <Modal onClose={(e) => e.stopPropagation()} innerRef={saveSetupRef} grey><SaveSetupForm companyId={companyId} display={display} closeModal={() => setIsSaveSetupModalOpen(false)} /></Modal>}
            {isRestoreSetupModalOpen && <Modal onClose={(e) => e.stopPropagation()} innerRef={restoreSetupRef} grey><RestoreSetupForm companyId={companyId} display={display} closeModal={() => { setIsRestoreSetupModalOpen(false) }} /></Modal>}
            { }
        </span>

    )
}


export default Display;