import { AxiosService } from './AxiosService';
import CryptoJS from "crypto-js";

const baseUrl = "myCTOUCH/Roles";
const secretKey = 'secretKey';

export const RoleService = {
    async getRole(user) {
        let roleObject = null;
        let decryptedUser = null;
        try {
            const auth0User = localStorage.getItem("auth0User");
            if (auth0User !== null) {
                decryptedUser = this.decryptObject(auth0User, secretKey)
            }
            if (auth0User !== null && Date.now() - localStorage.getItem("setupTime") <= 24 * 60 * 60 * 1000 && decryptedUser.data.auth_user_id === user.sub && (decryptedUser.data.is_admin === 1 || decryptedUser.data.verified_by_admin === 1)) {
                roleObject = decryptedUser
            } else {
                roleObject = await AxiosService.getRequest(`${baseUrl}/userRole/${user.email}`);
                localStorage.setItem("auth0User", this.encryptObject(roleObject, secretKey))
                localStorage.setItem("setupTime", Date.now())
            }
        } catch (exception) {
            roleObject = await AxiosService.getRequest(`${baseUrl}/userRole/${user.email}`);
            localStorage.setItem("auth0User", this.encryptObject(roleObject, secretKey))
            localStorage.setItem("setupTime", Date.now())
        }
        return roleObject.data;
    },
    async createRole(roleData) {
        await AxiosService.postRequest(`${baseUrl}/createRole`, roleData)
    },
    decryptObject(ciphertext, secretKey) {
        const bytes = CryptoJS.AES.decrypt(ciphertext, secretKey);
        const plaintext = bytes.toString(CryptoJS.enc.Utf8);
        return JSON.parse(plaintext);
    },
    encryptObject(object, secretKey) {
        const plaintext = JSON.stringify(object);
        const ciphertext = CryptoJS.AES.encrypt(plaintext, secretKey).toString();
        return ciphertext;
    },
    removeStorage() {
        const localUserExists = localStorage.getItem("auth0User")
        if (localUserExists !== undefined) {
            localStorage.removeItem("auth0User")

        }
    }
};