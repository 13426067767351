import { useState } from "react"
import PowerTimerTime from "../../atoms/PowerTimerTime/PowerTimerTime";
import { Input, PopUpButtons } from '@ctouch-europe-b-v/react-component-library';
import PowerTimerDay from "../../molecules/PowerTimerDay/PowerTimerDay";
import styles from './PowerTimer.module.css';

const PowerTimer = ({ onTimerChange, timer }) => {
  let day, time;
  timer.forEach(setting => {
    if (setting.type === "TimerTime") { time = setting }
    else { day = setting }
  })
  const [isChecked, setIsChecked] = useState((day.setting_value !== null && day.setting_value !== "Once" && day.setting_value !== "Off") ? true : false);
  const [isHour, setIsHour] = useState(time.setting_value === null || day.setting_value === "Off" ? undefined : time.setting_value.split(":")[0]);
  const [isMinute, setIsMinute] = useState(time.setting_value === null || day.setting_value === "Off" ? undefined : time.setting_value.split(":")[1]);
  const [isDayString, setIsDayString] = useState((!isChecked) ? "Once" : day.setting_value !== null ? day.setting_value : "");
  const [invalidMessage, setInvalidMessage] = useState("")

  const days = [
    { id: 1, name: "Mo" },
    { id: 2, name: "Te" },
    { id: 3, name: "We" },
    { id: 4, name: "Th" },
    { id: 5, name: "Fr" },
    { id: 6, name: "Sa" },
    { id: 0, name: "Su" },
  ];

  const changeTimeHandler = (e, minValue, maxValue, setFunction) => {
    setInvalidMessage("")
    const [firstMinValue, secondMinValue] = minValue.split("")
    const [firstMaxValue, secondMaxValue] = maxValue.split("")

    const firstRegEx = new RegExp(`^[${firstMinValue}-${firstMaxValue}]{1}$`, 'g')
    const undefinedRegEx = new RegExp(`undefined[${firstMinValue}-${firstMaxValue}]{1}`, 'g')
    const secondRegEx = new RegExp(`(^[${secondMinValue === 0 ? firstMinValue : parseInt(firstMinValue) + 1}-${(secondMaxValue !== 9) ? firstMaxValue - 1 : firstMaxValue}]{1}[\\d]{1}$)${(secondMinValue !== 0 ? `|(^${firstMinValue}[${secondMinValue}-9]{1}$)` : ``)}${(secondMaxValue !== 9) ? `|(^${firstMaxValue}[${secondMinValue}-${secondMaxValue}]{1}$)` : ""}`)
    let value = e.key;
    if (e.key === "Backspace") {
      setFunction((prevValue) => {
        if (firstRegEx.test(prevValue)) { return undefined }
        if (secondRegEx.test(prevValue)) { return prevValue.slice(0, 1) }
      })

    } else if (/^[\d]$/.test(e.key)) {
      value = value.toString()

      setFunction((prevValue) => {
        let testString = (prevValue !== undefined && prevValue.length === 2) ? prevValue : `${prevValue}${value}`;
        switch (true) {
          case undefinedRegEx.test(testString):
            prevValue = value
            break;
          case secondRegEx.test(testString):
            prevValue = testString
            break;
          case firstRegEx.test(prevValue) && !secondRegEx.test(testString):
            setInvalidMessage(`Number must be between ${minValue} - ${maxValue}`);
            break;
          default:
            setInvalidMessage(`Number must be ${minValue} - ${maxValue}`);
            prevValue = undefined
        }

        return prevValue
      })

    }
  }
  const createString = (oldString, newString) => {
    let startString;
    switch (oldString) {
      case "Everyday":
        startString = "MoTeWeThFrSaSu"
        break;
      case "MonToFri":
        startString = "MoTeWeThFr"
        break;
      case "MonToSat":
        startString = "MoTeWeThFrSa"
        break;
      case "SatToSun":
        startString = "SaSu"
        break;
      case "Once":
        startString = ""
        break;
      default:
        startString = oldString
    }
    let stringArray = startString.split(/(Mo|Tu|Te|We|Th|Fr|Sa|Su)/)
    if (newString !== null && stringArray.includes(newString)) { stringArray.splice(stringArray.indexOf(newString), 1) }
    else if (stringArray !== null && !stringArray.includes(newString)) {
      stringArray.push(newString)
      stringArray = days.map(day => {
        if (stringArray.includes(day.name)) {
          return day.name
        }
        else { return "" }
      })
    }
    return stringArray.filter(item => item !== "").join("")
  }

  const changeDayHandler = (day) => {
    setIsDayString(prevString => {
      return prevString = createString(prevString, day.name)
    })
  }

  const saveTimerHandler = () => {
    let endString;
    switch (isDayString) {
      case "":
        endString = "Once"
        break;
      default:
        endString = isDayString
    }
    endString = (isHour === undefined && isMinute === undefined) ? "Off" : (!isChecked) ? "Once" : endString.replace(/Tu/i, "Te")
    let timer = [
      { name: time.name, value: `${isHour === undefined ? "00" : isHour}:${isMinute === undefined ? "00" : isMinute}`, id: time.id },
      { name: day.name, value: endString, id: day.id }
    ]
    onTimerChange(timer)
  }

  return (
    <div className={styles.timer}>
      <span title={invalidMessage} className={styles.timerTime}>
        <PowerTimerTime className={styles.timerTimeInput} type="hour" value={isHour} time={time.setting_value !== null ? time.setting_value : undefined} onTimerChange={(e) => changeTimeHandler(e, "00", "23", setIsHour)} />
        : <PowerTimerTime type="minute" value={isMinute} time={time.setting_value !== null ? time.setting_value : undefined} onTimerChange={(e) => changeTimeHandler(e, "00", "59", setIsMinute)} /> </span>
      {(invalidMessage !== "") && <span>{invalidMessage}</span>}
      <div className={styles.timerRepeat} >
        <Input className={styles.checkbox} inputLeft onClick={() => { setIsChecked((prevIsChecked) => prevIsChecked = !prevIsChecked) }} checked={isChecked} value={isChecked} inputType={"checkBox"} labelText={"repeat"} />
      </div>
      {isChecked &&
        <PowerTimerDay days={days} value={isDayString !== "" ? createString(isDayString) : ""} onDayChange={(day) => changeDayHandler(day)} />
      }
      <PopUpButtons hasBtn1 textBtn1={"SAVE"} onClickBtn1={saveTimerHandler} hasCancelBtn />
    </div>
  )
}

export default PowerTimer