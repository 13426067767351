// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TableBodyRow_tableRow__-jsT2 {\n    width: 100%;\n    height: 50px;\n    display: inline-grid;\n    grid-template-columns: repeat(auto-fit, minmax(auto, 200px));\n}\n\n.TableBodyRow_removedRow__7Eqcq {\n    background-color: #FCE0E0;\n    width: 100%;\n    height: 50px;\n    align-items: center;\n    display: flex;\n    padding-left: 30px;\n    font-weight: 700;\n    font-size: 14px;\n}\n\n.TableBodyRow_button__R7l6h {\n    margin-left: 30px;\n}\n.TableBodyRow_menuIcon__cISW- {\n    color: #666666;\n    cursor: pointer;\n}", "",{"version":3,"sources":["webpack://./src/components/ui/organisms/tableBodyRow/TableBodyRow.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,oBAAoB;IACpB,4DAA4D;AAChE;;AAEA;IACI,yBAAyB;IACzB,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,aAAa;IACb,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,iBAAiB;AACrB;AACA;IACI,cAAc;IACd,eAAe;AACnB","sourcesContent":[".tableRow {\n    width: 100%;\n    height: 50px;\n    display: inline-grid;\n    grid-template-columns: repeat(auto-fit, minmax(auto, 200px));\n}\n\n.removedRow {\n    background-color: #FCE0E0;\n    width: 100%;\n    height: 50px;\n    align-items: center;\n    display: flex;\n    padding-left: 30px;\n    font-weight: 700;\n    font-size: 14px;\n}\n\n.button {\n    margin-left: 30px;\n}\n.menuIcon {\n    color: #666666;\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableRow": "TableBodyRow_tableRow__-jsT2",
	"removedRow": "TableBodyRow_removedRow__7Eqcq",
	"button": "TableBodyRow_button__R7l6h",
	"menuIcon": "TableBodyRow_menuIcon__cISW-"
};
export default ___CSS_LOADER_EXPORT___;
