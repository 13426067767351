// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PowerTimerDay_dayButtonGroup__ZxFtZ {\n  padding: 15px 0px;\n  border-bottom: 1px solid rgba(38,38,38,0.1);\n  border-top: 1px solid  rgba(38,38,38,0.1);\n  display: flex;\n  justify-content: space-between;\n}\n\n.PowerTimerDay_dayButton__a-Rya {\n  font-family: \"Quatro\";\n  width: 30px;\n  height: 30px;\n  padding-inline: 0px;\n  color: rgba(38,38,38,0.5);\n  border-radius: 50%;\n  background-color: white;\n  border: 1px solid gray\n}\n.PowerTimerDay_dayButton__a-Rya.PowerTimerDay_active__BS7i3, .PowerTimerDay_dayButton__a-Rya:hover {\n  color: #262626;\n  border-color: #262626;\n  box-shadow: 1px 2px 2px 0px rgba(0,0,0,0.25)\n}\n.PowerTimerDay_dayButton__a-Rya:hover {background-color: #f2f2f2}", "",{"version":3,"sources":["webpack://./src/components/ui/molecules/PowerTimerDay/PowerTimerDay.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,2CAA2C;EAC3C,yCAAyC;EACzC,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,qBAAqB;EACrB,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,yBAAyB;EACzB,kBAAkB;EAClB,uBAAuB;EACvB;AACF;AACA;EACE,cAAc;EACd,qBAAqB;EACrB;AACF;AACA,uCAAkB,yBAAyB","sourcesContent":[".dayButtonGroup {\n  padding: 15px 0px;\n  border-bottom: 1px solid rgba(38,38,38,0.1);\n  border-top: 1px solid  rgba(38,38,38,0.1);\n  display: flex;\n  justify-content: space-between;\n}\n\n.dayButton {\n  font-family: \"Quatro\";\n  width: 30px;\n  height: 30px;\n  padding-inline: 0px;\n  color: rgba(38,38,38,0.5);\n  border-radius: 50%;\n  background-color: white;\n  border: 1px solid gray\n}\n.dayButton.active, .dayButton:hover {\n  color: #262626;\n  border-color: #262626;\n  box-shadow: 1px 2px 2px 0px rgba(0,0,0,0.25)\n}\n.dayButton:hover {background-color: #f2f2f2}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dayButtonGroup": "PowerTimerDay_dayButtonGroup__ZxFtZ",
	"dayButton": "PowerTimerDay_dayButton__a-Rya",
	"active": "PowerTimerDay_active__BS7i3"
};
export default ___CSS_LOADER_EXPORT___;
