import styles from "./TabMenu.module.css"
import React, { Component, useState } from 'react';

const TabMenu = ({ children, onSelectedChange }) => {
    const [selectedTab, setSelectedTab] = useState(0)

    return (
        <div className={styles.container}>
            {React.Children.map(children, (child, index) => {
                return React.cloneElement(child, {
                    onClick: () => { setSelectedTab(index); onSelectedChange(index) },
                    isSelected: index === selectedTab
                })
            })}
        </div>
    );
}

export default TabMenu