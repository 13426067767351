import styles from "./Registration.module.css"

import VerifyMailPopUp from "../../ui/organisms/verifyMailPopUp/VerfifyMailPopUp";
import ResentMailPopUp from "../../ui/organisms/resentMailPopUp/ResentMailPopUp";
import VerifiedPopUp from "../../ui/organisms/verifiedPopUp/VerifiedPopUp";
import IAmAdminPopUp from "../../ui/organisms/iAmAdminPopUp/IAmAdminPopUp";
import WhoIsAdminPopUp from "../../ui/organisms/whoIsAdminPopUp/WhoIsAdminPopUp";
import AdminMailSentPopUp from "../../ui/organisms/adminMailSentPopUp/AdminMailSentPopUp";
import FirstPopUp from "../../ui/organisms/firstPopUp/FirstPopUp";
import SetupAdminPopUp from "../../ui/organisms/setupAdminPopUp/SetupAdminPopUp";
import ThanksPopUp from "../../ui/organisms/thanksPopUp/ThanksPopUp";
import { UserService } from "../../../services/UserService";
import { useEffect, useState } from "react";
import { CustomerService } from "../../../services/CustomerService";
import IllegalMail from "../../ui/organisms/illegalMail/IllegalMail";
import ChooseCompanyPopup from "../../ui/organisms/chooseCompanyPopup/ChooseCompanyPopup";
import AreYouAdminPopup from './../../ui/organisms/areYouAdminPopup/AreYouAdminPopup';

function Registration({ user }) {
    const [pageIndex, setPageIndex] = useState(0);
    const [adminMail, setAdminMail] = useState("");
    const [companyId, setCompanyId] = useState(null);
    const [company, setCompany] = useState(null);
    const [isAdmin, setIsAdmin] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const illegalDomains = ["gmail", "outlook", "hotmail", "protonmail", "icloud", "yahoo", "me"];

    function handlePageIndexChange(index) {
        setPageIndex(index)
    }

    useEffect(() => {
    }, [companyId])

    const setCompanyAndAdmin = (company) => {
        setIsLoading(true);
        setCompany(company.name);
        CustomerService.checkIfCompanyExists(user.email).then((response) => {
            setIsAdmin(response.data.filter((data) => data.id === company.id)[0].admin)
            UserService.update({ email: user.email, company_id: company.id })
            setIsLoading(false);
        });
    }

    useEffect(() => {
        UserService.create({ email: user.email, company_id: null, verified_by_admin: 0, is_admin: 0 }).then((response) => {
            CustomerService.checkIfCompanyExists(user.email).then((response) => {
                if (!response.data[0]?.id) {
                    setCompanyId(null);
                    setIsLoading(false);
                } else {
                    setCompanyId(response.data[0].id);
                    setIsLoading(false);
                }
            })
        })
    }, [user.email])



    const companyExistsArray = [<ChooseCompanyPopup email={user.email} setCompany={setCompanyAndAdmin} setPage={handlePageIndexChange} />, <VerifiedPopUp adminMail={isAdmin} newUserMail={user.email} setPage={handlePageIndexChange} />, <AreYouAdminPopup company={company} setPage={handlePageIndexChange} setCompanyId={setCompanyId} />, <IAmAdminPopUp setPage={handlePageIndexChange} />]
    const companyNotExistArray = [<FirstPopUp setPage={handlePageIndexChange} />, <SetupAdminPopUp user={user} setPage={handlePageIndexChange} />, <WhoIsAdminPopUp setPage={handlePageIndexChange} setAdminMail={setAdminMail} adminMail={adminMail} user={user} />, <AdminMailSentPopUp adminMail={adminMail} setPage={handlePageIndexChange} />, <ThanksPopUp />]


    const verifiedFlow = companyId ? companyExistsArray[pageIndex] : companyNotExistArray[pageIndex]
    const isVerified = user.email_verified ? verifiedFlow : <VerifyMailPopUp />
    const showPopUp = illegalDomains.includes(user.email.split("@")[1].split(".")[0]) ? <IllegalMail /> : isVerified
    return (
        <div className={styles.wrapper}>
            {
            }
            {!isLoading && showPopUp
            }
        </div>
    )
}

export default Registration;