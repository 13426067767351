// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SettingsSidebarHeader_container__W3Xhb {\n    padding-left: 40px;\n    height: 46px;\n    width: 100%;\n    padding-top: 25px;\n    box-sizing: border-box;\n}\n\n.SettingsSidebarHeader_title__pJXW7 {\n    font-family: 'Quatro';\n    font-style: normal;\n    font-weight: 900;\n    font-size: 10px;\n    letter-spacing: 0.015em;\n    text-transform: uppercase;\n    float: left;\n}", "",{"version":3,"sources":["webpack://./src/components/ui/atoms/settingsSidebarHeader/SettingsSidebarHeader.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,YAAY;IACZ,WAAW;IACX,iBAAiB;IACjB,sBAAsB;AAC1B;;AAEA;IACI,qBAAqB;IACrB,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,uBAAuB;IACvB,yBAAyB;IACzB,WAAW;AACf","sourcesContent":[".container {\n    padding-left: 40px;\n    height: 46px;\n    width: 100%;\n    padding-top: 25px;\n    box-sizing: border-box;\n}\n\n.title {\n    font-family: 'Quatro';\n    font-style: normal;\n    font-weight: 900;\n    font-size: 10px;\n    letter-spacing: 0.015em;\n    text-transform: uppercase;\n    float: left;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "SettingsSidebarHeader_container__W3Xhb",
	"title": "SettingsSidebarHeader_title__pJXW7"
};
export default ___CSS_LOADER_EXPORT___;
