import styles from './EditDisplayForm.module.css'
import { PopUpHeader, PopUpSpacer, Input, InputType, PopUpText, PopUpButtons, Button, Label, ButtonColor } from '@ctouch-europe-b-v/react-component-library';
import { useState } from 'react';
import useConnectCode from '../../../../hooks/UseConnectCode';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DisplayService } from '../../../../services/DisplayService';

function EditDisplayForm({ closeEditDisplay, display, locations, displayLocation, companyId, handleUpdated }) {
    const [displayName, setDisplayName] = useState(display.name);
    const [location, setLocation] = useState(displayLocation.location_name);
    const [isGenerateDisabled, setIsGenerateDisabled] = useState(true);
    const [connectCode, setConnectCode] = useState(display.connectCode);
    const { getProvisioningCode } = useConnectCode();

    const editDisplay = () => {
        display.name = displayName;
        display.location_name = location
        display.connectCode = connectCode;
        display.company_id = companyId;
        DisplayService.update(display).then(() => {
            closeEditDisplay();
            handleUpdated();
        });
    }

    return (
        <>
            <PopUpHeader headerText={"Edit display"} />
            <PopUpSpacer />
            <Input inputType={InputType.text} labelText={"Display name"} name={"displayName"} value={displayName} onChange={(e) => setDisplayName(e.target.value)} />
            <Input inputType={InputType.dataList} value={location} labelText={"Location"} name={"location"} title={location} options={locations.map((location) => location.location_name)} className={styles.dropDown} onChange={(e) => setLocation(e.target.value)} />
            <Input inputType={InputType.text} isDisabled labelText={"Serial number"} name={"serialNumber"} value={display.serialNumber} />
            <PopUpSpacer />
            <PopUpText hasIcon bodyText={"Generating a new ‘connect code’ removes the existing display connection. Use the new code to reconnect this display."} />
            <Input className={styles.checkBox} inputLeft inputType={InputType.checkBox} checked={!isGenerateDisabled} labelText={"I have read the above statement"} onChange={() => setIsGenerateDisabled(!isGenerateDisabled)} />
            <Label labelText={"Connect code"} className={styles.connectCode}>
                {
                    connectCode === display.connectCode ? <Button variant={isGenerateDisabled ? ButtonColor.white : ButtonColor.blue} onClick={() => setConnectCode(getProvisioningCode)} className={styles.connectCode} isDisabled={isGenerateDisabled} title={"Generate new connect code"} />
                        : <span className={styles.connectCodeWrapper}><Input disabled inputType={InputType.text} value={connectCode} /><FontAwesomeIcon className={styles.copyIcon} icon={["fal", "copy"]} onClick={() => navigator.clipboard.writeText(connectCode)} /></span>
                }
            </Label>
            <PopUpSpacer />
            <PopUpButtons hasCancelBtn hasBtn1 textBtn1={"Apply"} onClickBtn1={editDisplay} onClickCancel={closeEditDisplay} />
        </>
    )
}

export default EditDisplayForm;