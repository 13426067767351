import styles from "./LandingPage.module.css";
import { CtouchLogo, CtouchLogoColor, Button, ButtonColor, SphereLogo, SphereLogoColor } from '@ctouch-europe-b-v/react-component-library';
import landingContent from "../../../assets/landingContentImage.png"
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "react-router-dom";
const LandingPage = () => {
    const { loginWithRedirect } = useAuth0();
    return (
        <>
            <div className={styles.header}>
                <CtouchLogo color={CtouchLogoColor.black} className={styles.ctouchLogo} />
                <span className={styles.headerInlineContainer}>
                    <a href="https://ctouch.eu/contact" className={styles.ctouchLink} target="_blank"><Button variant={ButtonColor.red} title={"Get in touch"} /></a>
                </span>
            </div>
            <div className={styles.content}>
                <span className={styles.contentContainer}>
                    <SphereLogo className={styles.sphereLogo} color={SphereLogoColor.white} />
                    <span className={styles.textField}>
                        <p className={styles.headerText}>Managing displays remotely</p>
                        <p className={styles.bodyText}>Conveniently manage an entire fleet of CTOUCH displays in mere seconds so that your users get the maximum potential & security out of their displays.</p>
                        <Button variant={ButtonColor.white} className={styles.authButton} onClick={() => loginWithRedirect()} title={"Log in / Sign up"} />
                    </span>
                </span>
                <img className={styles.contentImage} src={landingContent} alt="Landing content" />
            </div>
        </>
    );
}

export default LandingPage;