// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BusinessTitle_title__OYoA3 {\n  font-family: \"Quatro\";\n  font-style: normal;\n  font-weight: 700;\n  font-size: 12px;\n  line-height: 91%;\n  letter-spacing: 0.015em;\n  text-transform: uppercase;\n  color: #262626;\n  word-break: break-all;\n  width: 150px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/ui/atoms/businessTitle/BusinessTitle.module.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,gBAAgB;EAChB,uBAAuB;EACvB,yBAAyB;EACzB,cAAc;EACd,qBAAqB;EACrB,YAAY;AACd","sourcesContent":[".title {\n  font-family: \"Quatro\";\n  font-style: normal;\n  font-weight: 700;\n  font-size: 12px;\n  line-height: 91%;\n  letter-spacing: 0.015em;\n  text-transform: uppercase;\n  color: #262626;\n  word-break: break-all;\n  width: 150px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "BusinessTitle_title__OYoA3"
};
export default ___CSS_LOADER_EXPORT___;
