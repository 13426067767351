// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../assets/backgroundLanding.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Registration_wrapper__tSz7Q {\n    display: flex;\n    overflow: hidden;\n    flex-wrap: wrap;\n    gap: 40px;\n    height: 100vh;\n    align-items: flex-start;\n    align-content: center;\n    justify-content: center;\n    background-repeat: no-repeat;\n    background-size: cover;\n    background-position: center;\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n\n", "",{"version":3,"sources":["webpack://./src/components/pages/registration/Registration.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,gBAAgB;IAChB,eAAe;IACf,SAAS;IACT,aAAa;IACb,uBAAuB;IACvB,qBAAqB;IACrB,uBAAuB;IACvB,4BAA4B;IAC5B,sBAAsB;IACtB,2BAA2B;IAC3B,yDAA8D;AAClE","sourcesContent":[".wrapper {\n    display: flex;\n    overflow: hidden;\n    flex-wrap: wrap;\n    gap: 40px;\n    height: 100vh;\n    align-items: flex-start;\n    align-content: center;\n    justify-content: center;\n    background-repeat: no-repeat;\n    background-size: cover;\n    background-position: center;\n    background-image: url(\"../../../assets/backgroundLanding.png\");\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "Registration_wrapper__tSz7Q"
};
export default ___CSS_LOADER_EXPORT___;
