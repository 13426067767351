import styles from "./IAmAdminPopUp.module.css"
import { PopUpHeader, PopUpText, PopUpButtons, ButtonColor, PopUp } from '@ctouch-europe-b-v/react-component-library';
import { useAuth0 } from "@auth0/auth0-react";
import { RoleService } from "../../../../services/RoleService";

function IAmAdminPopUp({ setPage }) {
    const { logout } = useAuth0();
    return (
        <PopUp>
            <PopUpHeader headerText={"But... I am the admin"} />
            <PopUpText bodyText={"It seems another user at your organization has already created an admin account. Please contact CTOUCH Service for support."} />
            <a className={styles.link} target="_blank" rel="noreferrer" href="https://support.ctouch.eu/hc/en-us/requests/new"><PopUpText hasIcon bodyText={"https://support.ctouch.eu"} /></a>
            <PopUpButtons hasBtn1 onClickBtn1={() => { setPage(0) }} textBtn1={"BACK"} hasBtn2 btn1HasArrow textBtn2={"CLOSE"} onClickBtn2={() => { RoleService.removeStorage(); logout({ logoutParams: { returnTo: window.location.origin } }) }} variantBtn2={ButtonColor.lifeAquatic} />
        </PopUp>
    )
}

export default IAmAdminPopUp