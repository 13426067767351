import styles from "./RemoveContextMenu.module.css";
import ContextMenuButton from './../../atoms/contextMenuButton/ContextMenuButton';

const RemoveContextMenu = ({ className, innerRef, onClick }) => {
    return (
        <ul className={[styles.contextMenu, className].filter(e => !!e).join(' ')} ref={innerRef}>
            <ContextMenuButton buttonText={"Remove"} iconName={"trash"} onClick={onClick} hasRedText />
        </ul>
    );
}

export default RemoveContextMenu;