// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PowerTimerTime_timeInput__CLgyD {\n  font-family: \"Quatro\";\n  width: 25px;\n  padding: 0\n}", "",{"version":3,"sources":["webpack://./src/components/ui/atoms/PowerTimerTime/PowerTimerTime.module.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,WAAW;EACX;AACF","sourcesContent":[".timeInput {\n  font-family: \"Quatro\";\n  width: 25px;\n  padding: 0\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"timeInput": "PowerTimerTime_timeInput__CLgyD"
};
export default ___CSS_LOADER_EXPORT___;
