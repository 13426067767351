import styles from './PowerTimerTime.module.css'
const PowerTimerTime = ({type, value, onTimerChange}) => {
  return (
      (type === "minute") ?
      <input className={styles.timeInput} type="text" defaultValue={type === "minute" && value !== undefined ? value : ""} value={type === "minute" && value !== undefined ? value : ""}  placeholder="MM" pattern="/[0-5]{1}[\d]{1}/" maxLength="2" onChange={(e) => value = e.target.value} onKeyDown={(e) => {onTimerChange(e)}} /> 
      :
      <input className={styles.timeInput} type="text" defaultValue={type === "hour" && value !== undefined ? value : ""} value={type === "hour" && value !== undefined ? value : ""} placeholder="HH" pattern="/([0-1]{1}[\d]{1}|2[0-3]{1})/" maxLength="2" onChange={(e) => value = e.target.value}  onKeyDown={(e) => {onTimerChange(e)}} />
  )
}

export default PowerTimerTime