// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SidebarButton_sidebarButton__so\\+Wa {\n  font-family: \"Quatro\";\n  font-style: normal;\n  font-weight: 700;\n  font-size: 12px;\n  line-height: 91%;\n  letter-spacing: 0.015em;\n  text-transform: uppercase;\n  text-align: left;\n  color: #ffffff;\n  height: 50px;\n  width: 160px;\n  display: flex;\n  align-items: center;\n  padding: 0 21px;\n  gap: 23px;\n  box-sizing: border-box;\n  cursor: pointer;\n}\n\n.SidebarButton_sidebarButton__so\\+Wa:hover {\n  background: #009FDA;\n}\n\n.SidebarButton_icon__BP9VD {\n  height: 17px;\n}\n\n.SidebarButton_disabled__vEzlf {\n  color: #CCCCCC;\n  background: #808080;\n  cursor: default;\n}\n\n.SidebarButton_disabled__vEzlf:hover {\n  background: #808080;\n}", "",{"version":3,"sources":["webpack://./src/components/ui/atoms/sidebarButton/SidebarButton.module.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,gBAAgB;EAChB,uBAAuB;EACvB,yBAAyB;EACzB,gBAAgB;EAChB,cAAc;EACd,YAAY;EACZ,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,eAAe;EACf,SAAS;EACT,sBAAsB;EACtB,eAAe;AACjB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,cAAc;EACd,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".sidebarButton {\n  font-family: \"Quatro\";\n  font-style: normal;\n  font-weight: 700;\n  font-size: 12px;\n  line-height: 91%;\n  letter-spacing: 0.015em;\n  text-transform: uppercase;\n  text-align: left;\n  color: #ffffff;\n  height: 50px;\n  width: 160px;\n  display: flex;\n  align-items: center;\n  padding: 0 21px;\n  gap: 23px;\n  box-sizing: border-box;\n  cursor: pointer;\n}\n\n.sidebarButton:hover {\n  background: #009FDA;\n}\n\n.icon {\n  height: 17px;\n}\n\n.disabled {\n  color: #CCCCCC;\n  background: #808080;\n  cursor: default;\n}\n\n.disabled:hover {\n  background: #808080;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sidebarButton": "SidebarButton_sidebarButton__so+Wa",
	"icon": "SidebarButton_icon__BP9VD",
	"disabled": "SidebarButton_disabled__vEzlf"
};
export default ___CSS_LOADER_EXPORT___;
