// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BusinessName_businessName__PBB3X {\n    background-color: #F0F1F3;\n    height: 50px;\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    padding: 0 27px;\n}\n\n.BusinessName_icon__z8Wsq {\n    height: 15px;\n    display: flex;\n    align-items: center;\n}\n\n.BusinessName_flexBox__-UNpa {\n    display: flex;\n    align-items: center;\n    gap: 11px;\n}\n\n.BusinessName_buttonsFlex__XJZJX {\n    display: flex;\n    align-items: center;\n    gap: 6px;\n}\n.BusinessName_hidden__7avtb {\n    display: none;\n}", "",{"version":3,"sources":["webpack://./src/components/ui/molecules/businessName/BusinessName.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;AACb;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,QAAQ;AACZ;AACA;IACI,aAAa;AACjB","sourcesContent":[".businessName {\n    background-color: #F0F1F3;\n    height: 50px;\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    padding: 0 27px;\n}\n\n.icon {\n    height: 15px;\n    display: flex;\n    align-items: center;\n}\n\n.flexBox {\n    display: flex;\n    align-items: center;\n    gap: 11px;\n}\n\n.buttonsFlex {\n    display: flex;\n    align-items: center;\n    gap: 6px;\n}\n.hidden {\n    display: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"businessName": "BusinessName_businessName__PBB3X",
	"icon": "BusinessName_icon__z8Wsq",
	"flexBox": "BusinessName_flexBox__-UNpa",
	"buttonsFlex": "BusinessName_buttonsFlex__XJZJX",
	"hidden": "BusinessName_hidden__7avtb"
};
export default ___CSS_LOADER_EXPORT___;
