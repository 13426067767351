// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TableHeaderCell_container__STpkK {\n    position: relative;\n    gap: 15px;\n    height: 50px;\n    display: flex;\n    padding: 0px 0px 0px 30px;\n    align-items: center;\n    width: auto;\n    background: white;\n    min-width: 88px;\n}\n\n.TableHeaderCell_icon__Z2TFF {\n    height: 10px;\n    margin-right: 10px;\n    cursor: pointer;\n}\n\n.TableHeaderCell_title__wwY-x {\n    color: #666666;\n    font-family: 'Quatro';\n    font-weight: 700;\n    font-size: 10px;\n    letter-spacing: 0.015em;\n    text-transform: uppercase;\n}\n\n.TableHeaderCell_hidden__\\+h07E {\n    display: none !important;\n}", "",{"version":3,"sources":["webpack://./src/components/ui/molecules/tableHeaderCell/TableHeaderCell.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,SAAS;IACT,YAAY;IACZ,aAAa;IACb,yBAAyB;IACzB,mBAAmB;IACnB,WAAW;IACX,iBAAiB;IACjB,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,cAAc;IACd,qBAAqB;IACrB,gBAAgB;IAChB,eAAe;IACf,uBAAuB;IACvB,yBAAyB;AAC7B;;AAEA;IACI,wBAAwB;AAC5B","sourcesContent":[".container {\n    position: relative;\n    gap: 15px;\n    height: 50px;\n    display: flex;\n    padding: 0px 0px 0px 30px;\n    align-items: center;\n    width: auto;\n    background: white;\n    min-width: 88px;\n}\n\n.icon {\n    height: 10px;\n    margin-right: 10px;\n    cursor: pointer;\n}\n\n.title {\n    color: #666666;\n    font-family: 'Quatro';\n    font-weight: 700;\n    font-size: 10px;\n    letter-spacing: 0.015em;\n    text-transform: uppercase;\n}\n\n.hidden {\n    display: none !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "TableHeaderCell_container__STpkK",
	"icon": "TableHeaderCell_icon__Z2TFF",
	"title": "TableHeaderCell_title__wwY-x",
	"hidden": "TableHeaderCell_hidden__+h07E"
};
export default ___CSS_LOADER_EXPORT___;
