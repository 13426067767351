// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PowerTimer_timer__DJVUt {\n  min-width: 200px;\n  max-width: 250px;\n  margin: 0 30% 0 10%;\n}\n.PowerTimer_timerTime__2Rs0w {\n  padding: 10px 0 10px 5px;\n  border-radius: 5px;\n  border: 1px solid #ccc;\n  display:inline-block;\n  width: 100%;\n  background-color: white\n}\n.PowerTimer_timerRepeat__bZG38 {\n  width: 75px\n}", "",{"version":3,"sources":["webpack://./src/components/ui/organisms/powerTimer/PowerTimer.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,mBAAmB;AACrB;AACA;EACE,wBAAwB;EACxB,kBAAkB;EAClB,sBAAsB;EACtB,oBAAoB;EACpB,WAAW;EACX;AACF;AACA;EACE;AACF","sourcesContent":[".timer {\n  min-width: 200px;\n  max-width: 250px;\n  margin: 0 30% 0 10%;\n}\n.timerTime {\n  padding: 10px 0 10px 5px;\n  border-radius: 5px;\n  border: 1px solid #ccc;\n  display:inline-block;\n  width: 100%;\n  background-color: white\n}\n.timerRepeat {\n  width: 75px\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"timer": "PowerTimer_timer__DJVUt",
	"timerTime": "PowerTimer_timerTime__2Rs0w",
	"timerRepeat": "PowerTimer_timerRepeat__bZG38"
};
export default ___CSS_LOADER_EXPORT___;
