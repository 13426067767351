import styles from "./ContextSpacer.module.css"

function ContextSpacer(){
    return(
        <div className={styles.spacer}>

        </div>
    )
}

export default ContextSpacer;