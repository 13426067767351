import styles from "./Customer.module.css"

function Customer({ name, isSelected, className, }) {

    return (
        <div className={[styles.customer, className, isSelected && styles.selected].filter(e => !!e).join(' ')}>
            <span>{name}</span>
        </div>
    )
}


export default Customer;