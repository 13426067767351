const Routes = Object.freeze({
    displayOverview: "displayOverview",
    customerOverview: "customer",
    myctouchOverview: process.env.REACT_APP_MYCTOUCH_URL,
    displaySettings: "settings",
    accountSettings: "accountSettings",
    dashboard: "dashboard",
    landing: "/",
    screenLogs: "screenLogs"
});

export default Routes;