// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DisplayContextButton_icon__vRFpv {\n    height: 15px;\n    color: #666666;\n    cursor: pointer;\n}\n\n.DisplayContextButton_contextMenuIcon__AAKuX {\n    padding: 0px 4px 0 12px;\n}\n\n.DisplayContextButton_contextMenuIcon__AAKuX:hover .DisplayContextButton_icon__vRFpv {\n    color: #009FDA\n}\n\n.DisplayContextButton_icon__vRFpv :hover {\n    color: #009FDA;\n}\n\n.DisplayContextButton_selected__KCM0c {\n    color: #FFFFFF;\n}", "",{"version":3,"sources":["webpack://./src/components/ui/atoms/displayContextButton/DisplayContextButton.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,cAAc;IACd,eAAe;AACnB;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI;AACJ;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB","sourcesContent":[".icon {\n    height: 15px;\n    color: #666666;\n    cursor: pointer;\n}\n\n.contextMenuIcon {\n    padding: 0px 4px 0 12px;\n}\n\n.contextMenuIcon:hover .icon {\n    color: #009FDA\n}\n\n.icon :hover {\n    color: #009FDA;\n}\n\n.selected {\n    color: #FFFFFF;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": "DisplayContextButton_icon__vRFpv",
	"contextMenuIcon": "DisplayContextButton_contextMenuIcon__AAKuX",
	"selected": "DisplayContextButton_selected__KCM0c"
};
export default ___CSS_LOADER_EXPORT___;
