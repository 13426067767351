import { PopUpHeader, PopUpText, PopUpButtons, ButtonColor, PopUp } from '@ctouch-europe-b-v/react-component-library';
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { MailService } from "../../../../services/MailService";
import { RoleService } from "../../../../services/RoleService";

function VerfifyMailPopUp({ setPage, adminMail, newUserMail }) {
    const { logout } = useAuth0();
    useEffect(() => {
        // Needs rewrite to id
        MailService.sentNewUserRequestToAdmin(adminMail, newUserMail)
    }, [])

    return (
        <PopUp>
            <PopUpHeader headerText={"Verified"} />
            <PopUpText bodyText={"Your account has been verified and a request has been sent to your registered system administrator to allow access to your account. "} />
            <PopUpText hasIcon bodyText={"You will receive an email once you are ready to go!"} />
            <PopUpButtons hasBtn1 textBtn1={"BUT... I AM THE ADMIN"} onClickBtn1={() => { setPage(3) }} hasBtn2 textBtn2={"CLOSE"} onClickBtn2={() => { RoleService.removeStorage(); logout({ logoutParams: { returnTo: window.location.origin } }) }} variantBtn2={ButtonColor.lifeAquatic} />
        </PopUp>
    )
}

export default VerfifyMailPopUp