import { useContext } from 'react';
import FilterMenuButton from '../filterMenuButton/FilterMenuButton';
import styles from './FilterMenu.module.css'
import { useState, useEffect } from 'react';
import { InputType } from '@ctouch-europe-b-v/react-component-library';
import ContextSpacer from '../../atoms/contextSpacer/ContextSpacer';
import { FilterContext } from '../../../../contexts/LocationContext';

const FilterMenu = ({ innerRef, items, onFilterIconChange, onFilterChange, headerType, className, isSorted }) => {
    const [filtersActive, setFiltersActive] = useState(false);
    const [allActive, setAllActive] = useState(false);
    const { globalFilterList, setGlobalFilterList } = useContext(FilterContext)

    useEffect(() => {
        if (filtersActive <= 0) {
            onFilterIconChange(false)
        }
        if (filtersActive === 1) {
            onFilterIconChange(true)
        }
    }, [filtersActive]);
    let valueList = new Map();
    const filterChangeHandler = (e, title, type) => {
        let newList = JSON.parse(JSON.stringify(globalFilterList));
        newList.forEach(item => {
            const key = Object.keys(item)[0];
            const values = Object.values(item)[0];
            if (key === title) {
                if (type === 'check') {
                    e.target.checked ? values.push({ value: e.target.value, inputType: type })
                        : values.splice(values.findIndex(filter => filter.value === e.target.value), 1)
                }
                else {
                    if (values.length > 0) {
                        if (e.target.value.length === 0) {
                            values.splice({ value: e.target.value, inputType: type })
                        } else {
                            values[0] = { value: e.target.value, inputType: type }
                        }
                    }
                    else {
                        values.push({ value: e.target.value, inputType: type });
                    }
                }
            }
        })
        setGlobalFilterList(newList)
    }
    globalFilterList.forEach(first => {
        let object = Object.entries(first)[0]
        let key = object[0]
        let values = [];
        (object[1] !== undefined) ? values.push(new Set(object[1].map(value => value.value))) : values.push(new Set([]))
        valueList.set(key, values)
    })
    const filterContainer = (fields) => {
        return <div className={styles.itemContainer}>
            <span className={styles.title}>{fields.title && fields.title}</span>
            <div className={styles.filterContentContainer}>
            {
                fields.values !== undefined ?
                    fields.values.map((item, i) => {
                        valueList.get(fields.fieldName)[0].has(item) && onFilterIconChange(true)
                        return typeof (item) === 'boolean' ? <FilterMenuButton key={i} checked={valueList.get(fields.fieldName)[0].has(!!item)} inputType={InputType.checkBox} inputLeft label={item === true ? "Online" : "Offline"} onChange={(e) => { e.target.checked ? setFiltersActive(filtersActive + 1) : setFiltersActive(filtersActive - 1); filterChangeHandler(e, fields.fieldName, 'check') }} value={item} /> :
                            <FilterMenuButton key={i} checked={valueList.get(fields.fieldName)[0].has(item)} inputType={InputType.checkBox} inputLeft label={item} onChange={(e) => { e.target.checked ? setFiltersActive(filtersActive + 1) : setFiltersActive(filtersActive - 1); filterChangeHandler(e, fields.fieldName, 'check') }} />
                    })
                    :
                    Object.entries(fields).map(([fieldName, filters], i) => {
                        return filters.map((item, i) => {
                            valueList.get(fieldName)[0].has(typeof (item) === 'boolean' ? item.toString() : item) && onFilterIconChange(true)
                            return typeof (item) === 'boolean' ? <FilterMenuButton key={i} checked={valueList.get(fieldName)[0].has(item.toString())} inputType={InputType.checkBox} inputLeft label={item === true ? "Online" : "Offline"} onChange={(e) => { e.target.checked ? setFiltersActive(filtersActive + 1) : setFiltersActive(filtersActive - 1); filterChangeHandler(e, fieldName, 'check') }} value={item} /> :
                                <FilterMenuButton key={i} checked={valueList.get(fieldName)[0].has(item)} inputType={InputType.checkBox} inputLeft label={item} onChange={(e) => { e.target.checked ? setFiltersActive(filtersActive + 1) : setFiltersActive(filtersActive - 1); filterChangeHandler(e, fieldName, 'check') }} />
                        })

                    })
            }
            </div>
        </div>
    }

    return (
        <div ref={innerRef} className={[styles.menu, className].filter(e => !!e).join(' ')}>
            {headerType === InputType.checkBox ?
                <>
                    <FilterMenuButton isSortable onSortClick={isSorted} className={styles.sorter} />
                    <ContextSpacer />
                    <div className={styles.filterMenuBody}>
                        {
                            items && typeof (items[0]) === 'object' && items.map((filterField) => {
                                return filterContainer(filterField)
                            })
                        }
                    </div>
                </> :
                <FilterMenuButton inputType={InputType.text} isSortable onSortClick={isSorted} onChange={(e) => { e.target.value.length > 0 ? setFiltersActive(1) : setFiltersActive(0); filterChangeHandler(e, 'serialNumber', 'text') }} />
            }
        </div>
    );
}

FilterMenu.defaultProps = {
    headerType: InputType.checkBox
}

export default FilterMenu;