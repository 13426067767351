import styles from './AppIconContainer.module.css';

const AppIconContainer = ({children, className, hasIcon}) => {
  return (
    <div className={[styles.container, hasIcon && styles.noBackground, className].filter(e=>!!e).join(" ")}>
      {children}
    </div>
  )
}

export default AppIconContainer