import { Axios } from 'axios';
import { AxiosService } from './AxiosService';

const baseUrl = "sphere/displays";

export const DisplayService = {
    async create(display) {
        return await AxiosService.postRequest(`${baseUrl}/create`, display);
    },

    async update(display) {
        return await AxiosService.postRequest(`${baseUrl}/update`, display);
    },

    async delete(display) {
        return await AxiosService.postRequest(`${baseUrl}/delete`, display);
    },

    async updateFirmware(display) {
        return await AxiosService.postRequest(`${baseUrl}/${display.id}/update_firmware`);
    },

    async updateSubscription(display_id, display_model) {
        return await AxiosService.postRequest(`${baseUrl}/${display_id}/${display_model}/update_subscription`)
    },

    async checkDuplicateSerial(serial) {
        return await AxiosService.getRequest(`${baseUrl}/${serial}/check_display_exists`)
    },

    async saveUpdateFirmwareData(data) {
        return await AxiosService.postRequest(`${baseUrl}/save_update_data`, data)
    },

    async deleteUpdateFirmwareData(data) {
        return await AxiosService.postRequest(`${baseUrl}/delete_update_firmware_data`, data)
    },

    async getUpdateFirmwareData(serial) {
        return await AxiosService.getRequest(`${baseUrl}/${serial}/get_update_firmware_data`)
    },

    async checkIsLatestVersion(serial) {
        return await AxiosService.getRequest(`${baseUrl}/${serial}/check_latest_version`)
    }
};