// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SettingsSidebarRow_container__BJJ8P {\n    padding-left: 40px;\n    height: 46px;\n    width: 100%;\n    display: flex;\n    align-items: center;\n    gap: 15px;\n    box-sizing: border-box;\n}\n\n.SettingsSidebarRow_active__9Cjsy {\n    background: #FFDB57;\n}\n\n.SettingsSidebarRow_container__BJJ8P:hover {\n    cursor: pointer;\n}\n\n.SettingsSidebarRow_title__oEE3C {\n    font-family: 'Quatro';\n    font-style: normal;\n    font-weight: 350;\n    font-size: 13px;\n    color: #262626;\n    letter-spacing: 0.05em;\n}\n\n.SettingsSidebarRow_disabled__HLK78 {\n    opacity: .3;\n}\n\n.SettingsSidebarRow_prefix__LOfxi {\n    height: 13px;\n}", "",{"version":3,"sources":["webpack://./src/components/ui/atoms/settingsSidebarRow/SettingsSidebarRow.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,YAAY;IACZ,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,SAAS;IACT,sBAAsB;AAC1B;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,qBAAqB;IACrB,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,cAAc;IACd,sBAAsB;AAC1B;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".container {\n    padding-left: 40px;\n    height: 46px;\n    width: 100%;\n    display: flex;\n    align-items: center;\n    gap: 15px;\n    box-sizing: border-box;\n}\n\n.active {\n    background: #FFDB57;\n}\n\n.container:hover {\n    cursor: pointer;\n}\n\n.title {\n    font-family: 'Quatro';\n    font-style: normal;\n    font-weight: 350;\n    font-size: 13px;\n    color: #262626;\n    letter-spacing: 0.05em;\n}\n\n.disabled {\n    opacity: .3;\n}\n\n.prefix {\n    height: 13px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "SettingsSidebarRow_container__BJJ8P",
	"active": "SettingsSidebarRow_active__9Cjsy",
	"title": "SettingsSidebarRow_title__oEE3C",
	"disabled": "SettingsSidebarRow_disabled__HLK78",
	"prefix": "SettingsSidebarRow_prefix__LOfxi"
};
export default ___CSS_LOADER_EXPORT___;
