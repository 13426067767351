// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SettingsLinkHeader_row__qei-\\+ {\n    width: 100%;\n    height: 46px;\n    display: flex;\n    gap: 12px;\n    align-items: center;\n    padding: 10px 0px 0px 0px;\n    box-sizing: border-box;\n}\n\n.SettingsLinkHeader_label__AftLw {\n    font-family: 'Quatro';\n    font-style: normal;\n    font-weight: 900;\n    font-size: 10px;\n    line-height: 91%;\n    letter-spacing: 0.015em;\n    text-transform: uppercase;\n    color: #262626;\n}\n\n.SettingsLinkHeader_prefix__KfEK9 {\n    cursor: pointer;\n    height: 13px;\n    padding-left: 32px;\n}", "",{"version":3,"sources":["webpack://./src/components/ui/atoms/settingsLinkHeader/SettingsLinkHeader.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;IACb,SAAS;IACT,mBAAmB;IACnB,yBAAyB;IACzB,sBAAsB;AAC1B;;AAEA;IACI,qBAAqB;IACrB,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,gBAAgB;IAChB,uBAAuB;IACvB,yBAAyB;IACzB,cAAc;AAClB;;AAEA;IACI,eAAe;IACf,YAAY;IACZ,kBAAkB;AACtB","sourcesContent":[".row {\n    width: 100%;\n    height: 46px;\n    display: flex;\n    gap: 12px;\n    align-items: center;\n    padding: 10px 0px 0px 0px;\n    box-sizing: border-box;\n}\n\n.label {\n    font-family: 'Quatro';\n    font-style: normal;\n    font-weight: 900;\n    font-size: 10px;\n    line-height: 91%;\n    letter-spacing: 0.015em;\n    text-transform: uppercase;\n    color: #262626;\n}\n\n.prefix {\n    cursor: pointer;\n    height: 13px;\n    padding-left: 32px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": "SettingsLinkHeader_row__qei-+",
	"label": "SettingsLinkHeader_label__AftLw",
	"prefix": "SettingsLinkHeader_prefix__KfEK9"
};
export default ___CSS_LOADER_EXPORT___;
