import ChartWidgetFooter from '../../atoms/chartWidgetFooter/ChartWidgetFooter';
import ChartWidgetTitle from '../../atoms/chartWidgetTitle/ChartWidgetTitle';
import ChartWidgetList from '../../atoms/chartWidgetList/ChartWidgetList';
import styles from './ChartWidgetTextMedium.module.css'
import { WidgetContainer } from '@ctouch-europe-b-v/react-component-library';
import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ChartWidgetTextMedium = ({ title, colorVariant, iconLeft, iconRight, className, data, noPlus, plusIsPositive, hasFullView, textVariant, setOverLayActive, overLayActive, hasError, hasWarning, containsPlus, children, chartRef }) => {
  const comparedToLastWeekHandler = (text) => {
    const noInfinity = text === "Infinity%" ? "0%" : text
    return noInfinity === "0%" ? <> That is <span className={styles.bold}>equal to</span> last week</> : <> That is <span className={styles.bold}>{text.replace("-", "")} {text.split("")[0] === "-" ? "lower" : "higher"}</span> than last week</>
  }
  const renderText = (textVariant) => {
    switch (textVariant) {
      case "summaryEnergy":
        return (
          <div className={styles.textContent}>
            While turned on, your displays consumed
            <span className={styles.textContentAmount}>{data.average.replace("-", "").replace("%", "")} Percent</span>
            {data.average.split("")[0] === "-" ? "less" : "more"} than the average CTOUCH user this week.
            {comparedToLastWeekHandler(data.comparedToLastWeek)}
          </div>
        )
      case "summaryEnergyUsage":
        return (
          <div className={styles.textContent}>
            On average, your displays were turned on
            <span className={styles.textContentAmount}>{data.average} hours </span>
            per day this week.
            {comparedToLastWeekHandler(data.comparedToLastWeek)}
          </div>
        )
      case "summarySourceUsage":
        const mostUsed = data.dataList.sort((a, b) => {
          const aValue = parseInt(a.value);
          const bValue = parseInt(b.value);
          if (aValue > bValue) return -1;
          else if (aValue < bValue) return 1;
          else { return 0 }
        })
        console.log(mostUsed)
        return mostUsed === undefined ?
          <div className={styles.warningText}>
            There is not enough data to display this information...
          </div>
          :
          (
            <div className={styles.textContent}>
              On average, your most used source is <span className={styles.bold}>{mostUsed[0]?.name}</span> with about
              <span className={styles.textContentAmount}>{mostUsed[0]?.value.replace("h", "")} hours </span>
              in total per day per display.
              {comparedToLastWeekHandler(data.comparedToLastWeek)}
            </div>
          )
      case "summaryFirmware":
        return (
          <div className={styles.textContent}>
            You currently have
            <span className={styles.textContentAmount}>{data.firmwareAmount} Displays</span>
            out of date Displays. Update to receive the best savety and functionality features.
          </div>
        )
      case "firmwareUpdates":
        return (
          <div className={styles.firmwareList}>
            {getFirmwareUpdateContent(data.dataList, 4)}
          </div>
        )
      case "error":
        return (<div className={styles.errorText}>
          Something went wrong displaying this data...
        </div>)
      default:
        return (<div className={styles.warningText}>
          There is not enough data to display this information...
        </div>)
    }
  }
  const [fullViewActive, setFullViewActive] = useState(false)
  useEffect(() => {
    if (!overLayActive) {
      setFullViewActive(false)
    }
  }, [overLayActive])

  return (
    <div ref={chartRef} className={fullViewActive && styles.containerWrapper}>
      <WidgetContainer size={"medium"} className={[className, (hasWarning || hasError) && styles.errorContainer, fullViewActive && styles.fullViewContainer, fullViewActive && (textVariant === "summaryFirmware" || textVariant === "firmwareUpdates") && styles.firmwares, styles[colorVariant], styles.container].filter(e => !!e).join(" ")}>

        <ChartWidgetTitle title={title} iconLeft={iconLeft} iconRight={iconRight} />
        {fullViewActive
          ? <ChartWidgetList background={colorVariant} containsPlus={containsPlus} data={textVariant !== "firmwareUpdates" ? data.dataList : getFirmwareUpdateContent(data.dataList, -1, true)} fullViewActive={fullViewActive} noPlus={noPlus} plusIsPositive={plusIsPositive} className={[textVariant === "firmwareUpdates" ? styles.firmwareList : "", styles.listOpen].filter(e => !!e).join(" ")} />
          : hasError
            ? <span className={[styles.content, styles.error].filter(e => !!e).join(" ")}>Something went wrong displaying this data...</span>
            : hasWarning
              ? <span className={[styles.content, styles.warning].filter(e => !!e).join(" ")}>There is not enough data to display this information...</span>
              : renderText(textVariant)}
        {(hasFullView && (!hasError && !hasWarning)) && <ChartWidgetFooter>
          {!fullViewActive
            ? <span className={[styles.footerButton, styles.bold].filter(e => !!e).join(" ")} onClick={() => { setFullViewActive(true); setOverLayActive(true) }}>View All</span>
            : <span className={[styles.footerButton, styles.bold].filter(e => !!e).join(" ")} onClick={() => { setFullViewActive(false); setOverLayActive(false) }}>Close</span>
          }
        </ChartWidgetFooter>
        }

      </WidgetContainer >
    </div>

  )
}

export default ChartWidgetTextMedium

const getFirmwareUpdateContent = (data, limit = -1, isFullScreen = false) => {
  let input = [];
  const modelList = new Map()
  data.sort((a, b) => {
    if (a.model > b.model) { return -1 }
    else if (a.model < b.model) { return 1 }
    else { return 0 }
  }).forEach(display => {
    if (modelList.has(display.model)) {
      const displayInfo = modelList.get(display.model);
      if (typeof display.current_firmware === "string") {
        displayInfo.earliest_version = displayInfo.earliest_version.localeCompare(display.current_version, undefined, { numeric: true }) === -1 ? display.current_firmware : displayInfo.earliest_version
      } else {
        displayInfo.earliest_version = displayInfo.earliest_version > display.current_firmware ? display.current_firmware : displayInfo.earliest_version
      }
    } else {
      modelList.set(display.model, { earliest_version: display.current_firmware, latest_version: display.latest_version })
    }
  })
  {
    let i = 0;
    modelList.forEach((displayInfo, model) => {
      input.push(
        <div className={[styles.firmware, (isFullScreen && i === modelList.size - 1) && styles.marginBottom].filter(e => !!e).join(" ")} key={model}>
          <span className={styles.model}>{model}</span>
          <div className={styles.firmwares}>
            <span>{displayInfo.earliest_version}</span>
            <span><FontAwesomeIcon className={styles.firmwaresIcon} icon={["fas", "arrow-right"]} /></span>
            <span>{displayInfo.latest_version}</span>
          </div>
        </div>
      )
      i++;
    })
  }

  return limit > 0 ? input.slice(0, limit) : input;
}
