import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styles from './ChartsContentContainer.module.css'
import { forwardRef } from 'react'

const ChartsContentContainer = forwardRef(function ChartsContentContainer({ title, titleIcon, children }, ref) {
  return (
    <div ref={ref}>
      <span className={styles.header}><FontAwesomeIcon className={styles.headerIcon} icon={["fas", titleIcon.replace(/'/g, "")]} /> {title.toUpperCase()}</span>
      <div className={styles.content}>
        {children}
      </div>
    </div>
  )
})
export default ChartsContentContainer