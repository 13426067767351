// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HelpMenuButton_helpMenuButton__rPxua {\n    height: 100%;\n    width: 30px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    cursor: pointer;\n}\n\n.HelpMenuButton_icon__m0S1r {\n    color: #CCC;\n}\n\n.HelpMenuButton_icon__m0S1r:hover {\n    color: #009FDA;\n}", "",{"version":3,"sources":["webpack://./src/components/ui/atoms/helpMenuButton/HelpMenuButton.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,eAAe;AACnB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,cAAc;AAClB","sourcesContent":[".helpMenuButton {\n    height: 100%;\n    width: 30px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    cursor: pointer;\n}\n\n.icon {\n    color: #CCC;\n}\n\n.icon:hover {\n    color: #009FDA;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"helpMenuButton": "HelpMenuButton_helpMenuButton__rPxua",
	"icon": "HelpMenuButton_icon__m0S1r"
};
export default ___CSS_LOADER_EXPORT___;
