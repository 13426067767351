// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DisplayTitle_displayTitle__FWQWx {\n  font-family: \"Quatro\";\n  font-style: normal;\n  font-weight: 350;\n  font-size: 13px;\n  line-height: 100%;\n  letter-spacing: 0.05em;\n  color: #262626;\n  padding-right: 5px;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  overflow: hidden;\n  max-width: 210px;\n}\n\n.DisplayTitle_selected__sVvxV {\n  color: #C7F0FF;\n}", "",{"version":3,"sources":["webpack://./src/components/ui/atoms/displayTitle/DisplayTitle.module.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,sBAAsB;EACtB,cAAc;EACd,kBAAkB;EAClB,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".displayTitle {\n  font-family: \"Quatro\";\n  font-style: normal;\n  font-weight: 350;\n  font-size: 13px;\n  line-height: 100%;\n  letter-spacing: 0.05em;\n  color: #262626;\n  padding-right: 5px;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  overflow: hidden;\n  max-width: 210px;\n}\n\n.selected {\n  color: #C7F0FF;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"displayTitle": "DisplayTitle_displayTitle__FWQWx",
	"selected": "DisplayTitle_selected__sVvxV"
};
export default ___CSS_LOADER_EXPORT___;
