// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TabMenu_container__0995G {\n  height: 40px;\n  width: -moz-fit-content;\n  width: fit-content;\n  position: relative;\n  display: flex;\n  background-color: #e6e6e6;\n  border-radius: 5px 5px 0 0;\n}", "",{"version":3,"sources":["webpack://./src/components/ui/molecules/tabMenu/TabMenu.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,uBAAkB;EAAlB,kBAAkB;EAClB,kBAAkB;EAClB,aAAa;EACb,yBAAyB;EACzB,0BAA0B;AAC5B","sourcesContent":[".container {\n  height: 40px;\n  width: fit-content;\n  position: relative;\n  display: flex;\n  background-color: #e6e6e6;\n  border-radius: 5px 5px 0 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "TabMenu_container__0995G"
};
export default ___CSS_LOADER_EXPORT___;
