import { useState, useRef } from "react";
import styles from './FooterSideButton.module.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fal } from "@fortawesome/pro-light-svg-icons";
import DisplayTitle from '../../atoms/displayTitle/DisplayTitle';
import useOnClickOutside from "../../../../hooks/UseOnClickOutside"
import { Modal } from '@ctouch-europe-b-v/react-component-library';
import CreateDisplayForm from '../createDisplayForm/CreateDisplayForm';
library.add(fal);

function FooterSideButton({ hasIcon, iconName, locations, company_id, handleCreated }) {
    const [isAddDisplayClosed, setIsAddDisplayClosed] = useState(true)
    const ref = useRef();

    useOnClickOutside(ref, () => setIsAddDisplayClosed(true));

    function handleAddDisplayClosedChange() {
        setIsAddDisplayClosed(!isAddDisplayClosed);
    }

    return (
        <>
            {!isAddDisplayClosed &&
                <Modal grey innerRef={ref}>
                    <CreateDisplayForm closeAddDisplay={handleAddDisplayClosedChange} locations={locations} handleCreated={handleCreated} company_id={company_id} />
                </Modal>}
            <div className={styles.container} onClick={handleAddDisplayClosedChange}>
                {hasIcon && <FontAwesomeIcon icon={["fal", iconName]} className={styles.icon} />}
                <DisplayTitle displayName={"Add a Display"} />
            </div>
        </>
    )
}

export default FooterSideButton;