import styles from "./AdminMailSentPopUp.module.css"
import { PopUpHeader, PopUpText, PopUpButtons, ButtonColor, PopUp, Input, InputType } from '@ctouch-europe-b-v/react-component-library';
import { useAuth0 } from "@auth0/auth0-react";
import { RoleService } from "../../../../services/RoleService";

function AdminMailSentPopUp({ adminMail }) {
    const { logout } = useAuth0();

    const adminMailAdress = adminMail

    return (
        <PopUp>
            <PopUpHeader headerText={"EMAIL HAS BEEN SENT TO:"} />
            <Input value={adminMailAdress} disabled inputType={InputType.text} isColumn className={styles.adminMail} />
            <PopUpText bodyText={"As soon as your admin has created an account, they can allow you access to yours."} />
            <PopUpButtons hasBtn2 btn2HasArrow textBtn2={"CLOSE"} onClickBtn2={() => { RoleService.removeStorage(); logout({ logoutParams: { returnTo: window.location.origin } }) }} variantBtn2={ButtonColor.lifeAquatic} />
        </PopUp>
    )
}

export default AdminMailSentPopUp