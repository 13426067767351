import { useState, useEffect } from "react";
import styles from "./SetupAdminPopUp.module.css"

import { PopUpHeader, PopUpText, PopUpButtons, ButtonColor, PopUp, RadioGroup, PopUpSpacer, Input, InputType } from '@ctouch-europe-b-v/react-component-library';
import { CompanyService } from "../../../../services/CompanyService";
import { UserService } from "../../../../services/UserService";

function SetupAdminPopUp({ setPage, user }) {
    const [companyName, setCompanyName] = useState("");
    const [country, setCountry] = useState(null);
    const [branch, setBranch] = useState(null);
    const [marketing, setMarketing] = useState(false);
    const [premiumContent, setPremiumContent] = useState(false);
    const [submitDisabled, setSubmitDisabled] = useState(true)

    useEffect(() => {
        if (companyName.length > 0 && country !== null && branch !== null && premiumContent !== false) setSubmitDisabled(false)
        else setSubmitDisabled(true)
    }, [companyName, country, branch, premiumContent])

    const handleSetupAdmin = () => {
        let company = { "companyName": companyName, "country": country, "branch": branch, "marketing": marketing, "CTOUCH_support": 0, "mail": user.email };

        CompanyService.createCompany(company, user.email).then((response) => {
            UserService.update({ email: user.email, company_id: response.data.id, is_admin: 1, verified_by_admin: 1, user_status: "Verified", sphere_access: 1 }).then(() => {
                setPage(4)
            })
        });
    }

    const countryList =
        [
            "Albania",
            "Algeria",
            "Andorra",
            "Angola",
            "Antigua and Barbuda",
            "Argentina",
            "Armenia",
            "Australia",
            "Austria",
            "Azerbaijan",
            "Bahamas",
            "Bahrain",
            "Bangladesh",
            "Barbados",
            "Belarus",
            "Belgium",
            "Belize",
            "Benin",
            "Bhutan",
            "Bolivia",
            "Bosnia and Herzegovina",
            "Botswana",
            "Brazil",
            "Brunei",
            "Bulgaria",
            "Burkina Faso",
            "Burundi",
            "Cabo Verde",
            "Cambodia",
            "Cameroon",
            "Canada",
            "Central African Republic (CAR)",
            "Chad",
            "Chile",
            "China",
            "Colombia",
            "Comoros",
            "Democratic Republic of the Congo",
            "Republic of the Congo",
            "Costa Rica",
            "Cote d Ivoire",
            "Croatia",
            "Cuba",
            "Cyprus",
            "Czech Republic",
            "Denmark",
            "Djibouti",
            "Dominica",
            "Dominican Republic",
            "Ecuador",
            "Egypt",
            "El Salvador",
            "Equatorial Guinea",
            "Eritrea",
            "Estonia",
            "Ethiopia",
            "Fiji",
            "Finland",
            "France",
            "Gabon",
            "Gambia",
            "Georgia",
            "Germany",
            "Ghana",
            "Greece",
            "Grenada",
            "Guatemala",
            "Guinea",
            "Guinea-Bissau",
            "Guyana",
            "Haiti",
            "Honduras",
            "Hungary",
            "Iceland",
            "India",
            "Indonesia",
            "Iran",
            "Iraq",
            "Ireland",
            "Israel",
            "Italy",
            "Jamaica",
            "Japan",
            "Jordan",
            "Kazakhstan",
            "Kenya",
            "Kiribati",
            "Kosovo",
            "Kuwait",
            "Kyrgyzstan",
            "Laos",
            "Latvia",
            "Lebanon",
            "Lesotho",
            "Liberia",
            "Libya",
            "Liechtenstein",
            "Lithuania",
            "Luxembourg",
            "Macedonia (FYROM)",
            "Madagascar",
            "Malawi",
            "Malaysia",
            "Maldives",
            "Mali",
            "Malta",
            "Marshall Islands",
            "Mauritania",
            "Mauritius",
            "Mexico",
            "Micronesia",
            "Moldova",
            "Monaco",
            "Mongolia",
            "Montenegro",
            "Morocco",
            "Mozambique",
            "Myanmar (Burma)",
            "Namibia",
            "Nauru",
            "Nepal",
            "Netherlands",
            "New Zealand",
            "Nicaragua",
            "Niger",
            "Nigeria",
            "North Korea",
            "Norway",
            "Oman",
            "Pakistan",
            "Palau",
            "Palestine",
            "Panama",
            "Papua New Guinea",
            "Paraguay",
            "Peru",
            "Philippines",
            "Poland",
            "Portugal",
            "Qatar",
            "Romania",
            "Russia",
            "Rwanda",
            "Saint Kitts and Nevis",
            "Saint Lucia",
            "Saint Vincent and the Grenadines",
            "Samoa",
            "San Marino",
            "Sao Tome and Principe",
            "Saudi Arabia",
            "Senegal",
            "Serbia",
            "Seychelles",
            "Sierra Leone",
            "Singapore",
            "Slovakia",
            "Slovenia",
            "Solomon Islands",
            "Somalia",
            "South Africa",
            "South Korea",
            "South Sudan",
            "Spain",
            "Sri Lanka",
            "Sudan",
            "Suriname",
            "Swaziland",
            "Sweden",
            "Switzerland",
            "Syria",
            "Taiwan",
            "Tajikistan",
            "Tanzania",
            "Thailand",
            "Timor-Leste",
            "Togo",
            "Tonga",
            "Trinidad and Tobago",
            "Tunisia",
            "Turkey",
            "Turkmenistan",
            "Tuvalu",
            "Uganda",
            "Ukraine",
            "United Arab Emirates (UAE)",
            "United Kingdom (UK)",
            "United States of America (USA)",
            "Uruguay",
            "Uzbekistan",
            "Vanuatu",
            "Vatican City (Holy See)",
            "Venezuela",
            "Vietnam",
            "Yemen",
            "Zambia",
            "Zimbabwe",
        ]

    return (
        <div className={styles.wrapper}>
            <PopUp>
                <PopUpHeader headerText={"Setup ADMIN account"} />
                <PopUpText bodyText={"Thank you for confirming that you will be the designated administrator. Please fill in the remaining details to continue:"} />
                <Input labelText={"Organisation name *"} inputType={InputType.text} className={styles.organisation} onChange={(e) => setCompanyName(e.target.value)} isColumn />
                <Input labelText={"Country *"} inputType={InputType.dropDown} isColumn options={countryList} className={styles.dropDown} onChange={(e) => setCountry(e.target.value)} />
            </PopUp>
            <PopUp>
                <PopUpHeader headerText={"Required info"} />
                <RadioGroup options={["Education", "Business"]} className={styles.input} onChange={(e) => setBranch(e.target.value)} />
                <PopUpSpacer />
                <Input labelText={"Sign me up for the newsletter (you can unsubscribe at any time)"} inputType={InputType.checkBox} className={styles.input} checked={marketing} onChange={() => setMarketing(!marketing)} inputLeft />
                <Input labelText={"I allow CTOUCH to store and process my data to deliver high-quality service and provide relevant product and service related updates, as further detailed in our"} inputType={InputType.checkBox} checked={premiumContent} className={styles.checkbox} onChange={() => setPremiumContent(!premiumContent)} inputLeft /> <a className={styles.privacy} target="_blank" href="https://ctouch.eu/privacy-statement">privacy statement</a>
                <PopUpButtons hasBtn1 onClickBtn1={() => { setPage(0) }} btn1HasArrow textBtn1={"BACK"} hasBtn2 btn2Disabled={submitDisabled} onClickBtn2={() => { handleSetupAdmin() }} textBtn2={"SAVE"} variantBtn2={ButtonColor.lifeAquatic} />
            </PopUp>
        </div>
    )
}

export default SetupAdminPopUp;