import styles from "./CreateConnectCodeForm.module.css";
import { PopUpHeader, PopUpSpacer, PopUpButtons, InputType, Input, ButtonColor } from '@ctouch-europe-b-v/react-component-library';
import useConnectCode from '../../../../hooks/UseConnectCode';
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ProvisionCodeService } from "../../../../services/ProvisionCodeService";
import { useRef } from 'react';

const getCurrentTime = (withTime) => {
  const date = new Date();
  const year = date.getFullYear();
  const day = ("0" + date.getDate()).slice(-2);
  const month = ("0" + (date.getMonth() + 1)).slice(-2);
  const hour = (`0${date.getHours()}`.slice(-2))
  const minute = (`0${date.getMinutes()}`.slice(-2))

  return `${year}-${month}-${day}T${withTime ? `${hour}:${minute}` : `23:59`}`;
}
const CreateConnectCodeForm = ({ closeAddConnectCode, companiesList, company, refreshProvisionCodes }) => {
  const { getProvisioningCode } = useConnectCode();
  const [connectCode, setConnectCode] = useState("");
  const [companyName, setCompanyName] = useState(company);
  const [projectName, setProjectName] = useState("");
  const [error, setError] = useState(false)
  const [validUntil, setValidUntil] = useState(getCurrentTime(true));
  const [createButtonStatus, setCreateButtonStatus] = useState(true);
  const validUntilRef = useRef();

  useEffect(() => {
    setConnectCode(getProvisioningCode());
  }, [])

  const handleConnectCodePost = () => {
    const company_id = companiesList.find(company => company.name === companyName).id;
    let provisionCode = {
      "provisionCode": connectCode,
      "company_id": company_id,
      "projectName": projectName,
      "validUntil": validUntil,
      "createdByExternal": company
    };
    ProvisionCodeService.create(provisionCode).then(() => {
      refreshProvisionCodes();
    });
  }

  useEffect(() => {
    handleCreateBtn()
  }, [connectCode, companyName, projectName, validUntil])

  const checkValidUntil = (dateInput) => {
    const currentDate = new Date(getCurrentTime(true)).getTime();
    const selectedDate = new Date(dateInput).getTime();

    if (currentDate <= selectedDate) {
      setValidUntil(dateInput);
      setError(false)
    } else if (currentDate > selectedDate) {
      setError("Date cannot be earlier than the current time");
    }
  }

  const handleCreateBtn = () => {
    if (connectCode?.length > 0 && companyName?.length > 0 && projectName?.length > 0 && validUntil?.length > 0) {
      setCreateButtonStatus(false)
    } else {
      setCreateButtonStatus(true)
    }
  }

  return (
    <>
      <PopUpHeader headerText={"Create a connect code"} className={styles.header} />
      <PopUpSpacer />
      <span className={styles.connectCodeWrapper}><Input className={styles.connectCode} inputType={InputType.text} value={connectCode} labelText={"Connect code"} disabled /><FontAwesomeIcon className={styles.copyIcon} icon={["fal", "copy"]} onClick={() => navigator.clipboard.writeText(connectCode)} /></span>
      <Input inputType={InputType.dropDown} labelText={"Company"} name={"company"} value={companyName} className={styles.dropDown} options={companiesList.map(company => company.name)} onChange={(e) => { setCompanyName(e.target.value); handleCreateBtn() }} />
      <Input inputType={InputType.text} labelText={"Project name"} name={"projectName"} onChange={(e) => { setProjectName(e.target.value); handleCreateBtn() }} />
      <Input inputType={InputType.dateTime} ref={validUntilRef} min={getCurrentTime(true)} labelText={"Valid until"} name={"validUntil"} onChange={(e) => { checkValidUntil(e.target.value); handleCreateBtn() }} value={validUntil} />
      {error !== false &&
        <span className={styles.error} >{error}</span>
      }
      <PopUpSpacer />
      <PopUpButtons hasCancelBtn hasBtn1 variantBtn1={ButtonColor.blue} textBtn1={"CREATE"} btn1Disabled={createButtonStatus} onClickBtn1={() => { handleConnectCodePost(); closeAddConnectCode(); }} onClickCancel={() => { closeAddConnectCode() }} />
    </>
  );
}

export default CreateConnectCodeForm;