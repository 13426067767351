import styles from './ChartWidgetTitle.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const ChartWidgetTitle = ({ title, iconLeft, iconLeftClassName, iconLeftOnClick, iconRight, iconRightClassName, iconRightOnClick, titleOnClick, className }) => {
  return (
    <span className={[styles.titleContainer, className].filter(e => !!e).join(' ')} onClick={(e) => { e.stopPropagation(); titleOnClick && titleOnClick() }}>
      <span className={styles.leftCornerContainer}>
        <FontAwesomeIcon icon={[iconLeft.type.replace(/'/g, ""), iconLeft.name.replace(/'/g, "")]} className={[styles.iconLeft, iconLeftClassName].filter(e => !!e).join(" ")} onClick={(e) => { e.stopPropagation(); iconLeftOnClick() }} />
        {title}
      </span>
      <span className={styles.rightCornerContainer}>
        {iconRight && <FontAwesomeIcon icon={[iconRight.type.replace(/'/g, ""), iconRight.name.replace(/'/g, "")]} className={iconRightClassName} onClick={(e) => { e.stopPropagation(); iconRightOnClick() }} />}
      </span>
    </span>
  )
}
export default ChartWidgetTitle